import { Icon } from '@shared-interfaces/icon.interface';

export const Icons = {
  // Training challenge icons
  STREAK_CHALLENGE_ACTIVE: { path: 'challenges/streak-challenge-active' } as Icon,
  STREAK_CHALLENGE_INACTIVE: { path: 'challenges/streak-challenge-inactive' } as Icon,
  TIME_CHALLENGE: { path: 'challenges/timer' } as Icon,

  // Alinea icons (used on all Alinea products)
  ALINEA_ARROW: { path: 'alinea/arrow' } as Icon,
  ALINEA_OUTSIDE_LINK: { path: 'alinea/outside-link' } as Icon,
  ALINEA_MENU: { path: 'alinea/menu' } as Icon,

  // Common icons
  ASTERIX: { sizes: [16], path: 'asterix' } as Icon,
  BLANK: { path: 'blank' } as Icon,
  BOOKMARK_OFF: { sizes: [16, 24], path: 'bookmark-off' } as Icon,
  BOOKMARK_ON: { sizes: [16, 24], path: 'bookmark-on' } as Icon,
  CALENDAR: { sizes: [16, 24], path: 'calendar' } as Icon,
  CART: { sizes: [16, 24], path: 'cart' } as Icon,
  CHECKMARK: { sizes: [16, 24], path: 'checkmark' } as Icon,
  CHECKMARK_SMALL: { sizes: [16], path: 'checkmark-small' } as Icon,
  CHEVRON_UP: { sizes: [16, 24], path: 'chevron-up' } as Icon,
  CHEVRON_DOWN: { sizes: [16, 24], path: 'chevron-down' } as Icon,
  CHEVRON_LEFT: { sizes: [16, 24], path: 'chevron-left' } as Icon,
  CHEVRON_RIGHT: { sizes: [16, 24], path: 'chevron-right' } as Icon,
  CLOSE: { sizes: [16, 24], path: 'close' } as Icon,
  CLOSE_SMALL: { sizes: [16], path: 'close-small' } as Icon,
  DELETE: { sizes: [16, 24], path: 'delete' } as Icon,
  DOWNLOAD: { sizes: [16, 24], path: 'download' } as Icon,
  DRAG: { sizes: [16, 24], path: 'drag' } as Icon,
  DUPLICATE: { sizes: [16, 24], path: 'duplicate' } as Icon,
  EDIT: { sizes: [16, 24], path: 'edit' } as Icon,
  ERROR: { sizes: [16, 24], path: 'error' } as Icon,
  FAVORITE_OFF: { sizes: [16, 24], path: 'favorite-off' } as Icon,
  FAVORITE_ON: { sizes: [16, 24], path: 'favorite-on' } as Icon,
  FILE: { sizes: [16, 24], path: 'file' } as Icon,
  FILTER: { sizes: [16, 24], path: 'filter' } as Icon,
  HELP: { sizes: [16, 24], path: 'help' } as Icon,
  HOME: { sizes: [16, 24], path: 'home' } as Icon,
  IN_PROGRESS: { sizes: [16, 24], path: 'in-progress' } as Icon,
  INFO: { sizes: [16, 24], path: 'info' } as Icon,
  LIST_SORT: { sizes: [16], path: 'list-sort' } as Icon,
  NAVIGATION_ARROW_LEFT: { sizes: [16, 24], path: 'navigation-arrow-left' } as Icon,
  NAVIGATION_ARROW_RIGHT: { sizes: [16, 24], path: 'navigation-arrow-right' } as Icon,
  NOTIFICATION: { sizes: [16, 24], path: 'notification' } as Icon,
  PARTIALLY_SELECTED: { sizes: [16, 24], path: 'partially-selected' } as Icon,
  PLUS: { sizes: [16, 24], path: 'plus' } as Icon,
  PRINT: { sizes: [16, 24], path: 'print' } as Icon,
  PROGRESS_STATE_IN_PROGRESS: { sizes: [16, 24], path: 'progress-state-in-progress' } as Icon,
  PROGRESS_STATE_COMPLETED: { sizes: [16, 24], path: 'progress-state-completed' } as Icon,
  PROGRESS_STATE_NOT_STARTED: { sizes: [16, 24], path: 'progress-state-not-started' } as Icon,
  REDIRECT_TO_PAGE: { sizes: [16], path: 'redirect-to-page' } as Icon,
  RELOAD: { sizes: [16, 24], path: 'reload' } as Icon,
  RESET: { sizes: [16, 24], path: 'reset' } as Icon,
  SEARCH: { sizes: [16, 24], path: 'search' } as Icon,
  SETTINGS: { sizes: [16, 24], path: 'settings' } as Icon,
  SHARE: { sizes: [16, 24], path: 'share' } as Icon,
  SORT_DOWN: { sizes: [16, 24], path: 'sort-down' } as Icon,
  SORT_UP: { sizes: [16, 24], path: 'sort-up' } as Icon,
  SOUND_OFF: { sizes: [16, 24], path: 'sound-off' } as Icon,
  SOUND_ON: { sizes: [16, 24], path: 'sound-on' } as Icon,
  TIPS: { sizes: [32], path: 'tips' } as Icon,
  TIMER_ENDED: { sizes: [16, 24], path: 'timer-ended' } as Icon,
  TIMER_OFF: { sizes: [16, 24], path: 'timer-off' } as Icon,
  TIMER_ON: { sizes: [16, 24], path: 'timer-on' } as Icon,
  TRAINING: { sizes: [16, 24, 32], path: 'training' } as Icon,
  TRAINING_COUNT: { sizes: [16, 24], path: 'training-count' } as Icon,
  USER: { sizes: [16, 24], path: 'user' } as Icon,
  USER_ADD: { sizes: [16, 24], path: 'user-add' } as Icon,
  USER_REMOVE: { sizes: [16, 24], path: 'user-remove' } as Icon,
  VALIDATION_CORRECT: { sizes: [16, 24], path: 'validation-correct' } as Icon,
  VALIDATION_SYSTEM_ANSWER: { sizes: [16, 24], path: 'validation-system-answer' } as Icon,
  VALIDATION_WRONG: { sizes: [16, 24], path: 'validation-wrong' } as Icon,
  CLEAR_INPUT: { sizes: [24], path: 'clear-input' } as Icon,
  VIDEO: { sizes: [16, 24], path: 'video' } as Icon,
  VISIBILITY: { sizes: [16, 24], path: 'visibility' } as Icon,

  // Content type icons
  EXERCISE_COLLECTION: { sizes: [16], path: 'exercise-collection' } as Icon,
  LESSON: { sizes: [16], path: 'lesson' } as Icon,
  TRAINING_COURSE: { sizes: [16], path: 'training-course' } as Icon,
  TRAINING_CONTEXT: { sizes: [16], path: 'training-context' } as Icon,
  ACTIVITY: { sizes: [16], path: 'activity' } as Icon,
  WORKSHEET: { sizes: [16], path: 'worksheet' } as Icon,
};
