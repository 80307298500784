import { Injectable, OnDestroy } from '@angular/core';
import { User } from '@app/user/interfaces/user.interface';
import * as UserActions from '@app/user/store/user.actions';
import * as UserSelectors from '@app/user/store/user.selectors';
// TODO MF2-5582 Decide out whether this should be removed completely
//import { logoutFlag } from '@core/const/storage-keys.const';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { UserType } from '@core/enums/user-type.enum';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { AzureAppConfigurationService } from '@core/services/azure-app-configuration.service';
// TODO MF2-5582 Decide out whether this should be removed completely
//import { StorageService } from '@core/services/storage.service';
import { CoreState } from '@core/store/interfaces/core-state.interface';
import { select, Store } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';
import { dateIsInThePast } from '@utils/date';
import { unsubscribe } from '@utils/observable.helper';
import { getObservableValueSync } from '@utils/observables';
import { filter, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  public isLoggedIn$: Observable<boolean>;
  public hasAccess$: Observable<boolean>;
  public isTeacher$: Observable<boolean>;
  public isStudent$: Observable<boolean>;
  public isAlineaUser$: Observable<boolean>;
  public user$: Observable<User | null>;
  public userInitials$: Observable<string | undefined>;
  public userDisplayName$: Observable<string | undefined>;
  public userFirstName$: Observable<string | undefined>;
  public userId$: Observable<GUID | undefined>;
  public userServerInteractionState$: Observable<ServerInteractionState>;
  public hasLoadedUserData$: Observable<boolean>;

  public isLoggedIn = false;
  public hasAccess = false;
  public isTeacher = false;
  public isAlineaUser = false;
  public isStudent = false;

  private isLoggedInSub: Subscription;
  private hasAccessSub: Subscription;
  private isTeacherSub: Subscription;
  private isStudentSub: Subscription;
  private isAlineaUserSub: Subscription;
  // TODO MF2-5582 Decide out whether this should be removed completely
  //private logoutEvent: Subscription;

  constructor(
    private coreStore: Store<CoreState>,
    // TODO MF2-5582 Decide out whether this should be removed completely
    //private storageService: StorageService,
    private azureAppConfigurationService: AzureAppConfigurationService,
  ) {
    this.isLoggedIn$ = this.coreStore.select(UserSelectors.isLoggedIn);
    this.hasAccess$ = this.coreStore.select(UserSelectors.hasAccess);
    this.isTeacher$ = this.coreStore.select(UserSelectors.isTeacher);
    this.isStudent$ = this.coreStore.select(UserSelectors.isStudent);
    this.isAlineaUser$ = this.coreStore.select(UserSelectors.isAlineaUser);
    this.user$ = this.coreStore.select(UserSelectors.selectCurrentUser);
    this.userId$ = this.coreStore.select(UserSelectors.selectCurrentUserId);
    this.userInitials$ = this.coreStore.select(UserSelectors.selectCurrentUserInitials);
    this.userDisplayName$ = this.coreStore.select(UserSelectors.selectCurrentUserDisplayName);
    this.userFirstName$ = this.coreStore.select(UserSelectors.selectCurrentUserFirstName);
    this.userServerInteractionState$ = this.coreStore.select(UserSelectors.selectUserServerInteractionState);
    this.hasLoadedUserData$ = this.userServerInteractionState$.pipe(
      map(
        (serverInteractionState: ServerInteractionState) =>
          serverInteractionState.state === ServerInteractionStateType.SUCCESS,
      ),
    );

    this.isLoggedInSub = this.isLoggedIn$.subscribe((isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn));
    this.hasAccessSub = this.hasAccess$.subscribe((hasAccess: boolean) => (this.hasAccess = hasAccess));
    this.isTeacherSub = this.isTeacher$.subscribe((isTeacher: boolean) => (this.isTeacher = isTeacher));
    this.isStudentSub = this.isStudent$.subscribe((isStudent: boolean) => (this.isStudent = isStudent));
    this.isAlineaUserSub = this.isAlineaUser$.subscribe((isAlineaUser: boolean) => (this.isAlineaUser = isAlineaUser));
    // TODO MF2-5582 Decide out whether this should be removed completely
    /*
    this.logoutEvent = this.storageService.localStorageChanged$
      .pipe(filter((x: StorageEvent) => x.key === logoutFlag))
      .subscribe((x: StorageEvent) => this.userLogOut());
    */
  }

  ngOnDestroy(): void {
    unsubscribe(
      this.isLoggedInSub,
      this.hasAccessSub,
      this.isTeacherSub,
      this.isAlineaUserSub,
      this.isStudentSub,
      // TODO MF2-5582 Decide out whether this should be removed completely
      //this.logoutEvent
    );
  }

  public get user(): User | undefined {
    return this.isLoggedIn
      ? getObservableValueSync(this.coreStore.pipe(select(UserSelectors.selectCurrentUser))) ?? undefined
      : undefined;
  }

  public get userType(): UserType {
    let userType: UserType = UserType.NOT_LOGGED_IN;

    const user = this.user;

    if (user) {
      if (user.isTeacher) {
        userType = UserType.TEACHERS;
      } else {
        userType = UserType.STUDENTS;
      }
    }

    return userType;
  }

  public get isTokenExpired(): boolean {
    const expired = this.user ? dateIsInThePast(this.user.expiresAt) : true;
    return expired;
  }

  public get doNotTrack(): boolean {
    return this.user?.doNotTrack || false;
  }

  public get getAccessToken(): string | undefined {
    return getObservableValueSync(this.coreStore.pipe(select(UserSelectors.selectAccessToken)));
  }

  public get getContextIdentifier(): string | undefined {
    return getObservableValueSync(this.coreStore.pipe(select(UserSelectors.selectContextIdentifier)));
  }

  public get getInstitutionNumber(): string | undefined {
    return getObservableValueSync(this.coreStore.pipe(select(UserSelectors.selectInstitutionNumber)));
  }

  public get getInstitutionId(): GUID | undefined {
    return getObservableValueSync(this.coreStore.pipe(select(UserSelectors.selectCurrentUserInstitutionId)));
  }

  private get returnUrl(): string {
    const querystring = new URLSearchParams(window.location.search);

    querystring.delete('returnUrl');
    const q = querystring.toString().length > 0 ? `?${querystring.toString()}` : '';
    const finalUrl = `${window.location.origin}${window.location.pathname}${q}`;

    return encodeURIComponent(finalUrl);
  }

  /**
   * The userLogout method will perform a user logout by redirecting user to the express server.
   */
  userLogOut(): void {
    if (this.isLoggedIn) {
      const useThisAsReturnUrl = window.location.origin;
      // this.route?.snapshot.firstChild?.routeConfig?.canActivate?.filter(
      //    (guard) => guard?.nameHardcoded === 'LoginGuard'
      // ).length === 0
      //   ? this.returnUrl
      //   : window.location.origin;
      const href = `${this.azureAppConfigurationService.getSetting(
        'internalBackendUrl',
      )}authorize/logoff?returnUrl=${useThisAsReturnUrl}`;

      // TODO MF2-5582 Decide out whether this should be removed completely
      //this.storageService.set(logoutFlag, 'true');
      window.location.href = href;
    }
  }

  /**
   * The userLogin method will perform a user login by redirecting user to the express server.
   */
  userLogin(): void {
    const href = `${this.azureAppConfigurationService.getSetting('internalBackendUrl')}auth/login?returnUrl=${
      this.returnUrl
    }&isbn=${this.azureAppConfigurationService.getSetting('isbn')}`;

    // TODO MF2-5582 Decide out whether this should be removed completely
    //this.storageService.delete(logoutFlag);
    window.location.href = href;
  }

  /**
   * The renewRefreshToken method will perform a refresh token cycling to keep login alive
   * parameter: expire : if true will force the expiration property to be in the past (making the next refresh cycling or getUserModel call fail)
   */
  renewRefreshToken(): Observable<ServerInteractionState> {
    this.coreStore.dispatch(UserActions.renewRefreshToken());

    return this.coreStore.select(UserSelectors.selectRenewRefreshTokenServerInteractionState);
  }

  reloadUserData(noLoadingAnimation?: boolean): void {
    this.coreStore.dispatch(UserActions.loadUser({ noLoadingAnimation }));
  }
}
