import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-modal-content-layout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-content-layout.component.html',
  styleUrls: ['./modal-content-layout.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalContentLayoutComponent {
  @Input() heading?: string;
  @Input() message?: string;
}
