import { createReducer, on } from '@ngrx/store';

import { PageState } from '../interfaces/page-state.interface';
import * as PageActions from './page.actions';

export const pageFeatureKey = 'pageState';

export const initialPageState: PageState = {
  id: undefined,
  url: undefined,
  sections: [],
  hero: undefined,
  page: undefined,
  pageType: undefined,
  documentTitle: undefined,
};

export const pageReducer = createReducer(
  initialPageState,
  on(PageActions.setPage, (state, action) => ({
    ...state,
    id: action.data.id,
    url: action.data.url,
    sections: action.data.sections,
    hero: action.data.hero,
    pageType: action.data.pageType,
  })),
  on(PageActions.setPageType, (state, action) => ({
    ...state,
    pageType: action.pageType,
  })),
  on(PageActions.setDocumentTitle, (state, action) => ({
    ...state,
    documentTitle: action.title,
  })),
);
