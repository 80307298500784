import { Injectable } from '@angular/core';
import * as CoreSelectors from '@app/core/store/core.selectors';
import { CssColorService } from '@app/css-color/css-color.service';
import { Header } from '@app/page/interfaces/header.interface';
import { ColorsMapper } from '@app/page/mapper/colors.mapper';
import { MegaMenuMapper } from '@app/page/mapper/mega-menu.mapper';
import { NavbarMapper } from '@app/page/mapper/navbar.mapper';
import { isPageType, pageType } from '@app/page/page.types';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { SiteBootstrap } from '@core/interfaces/project/site-bootstrap.interface';
import { UmbracoApiService } from '@core/services/umbraco-api.service';
import { getLocalStorageKeyForDismissedOperationalNotifications } from '@core/store/core.utils';
import { CoreState } from '@core/store/interfaces/core-state.interface';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, retry, switchMap } from 'rxjs/operators';

import { SiteColors, SiteHeaderColors } from '../interfaces/project/portal-configuration.interface';
import { AzureAppConfigurationApiService } from '../services/azure-app-configuration-api.service';
import * as CoreActions from './core.actions';

@Injectable()
export class CoreEffects {
  constructor(
    private actions$: Actions,
    private umbracoApi: UmbracoApiService,
    private coreStore: Store<CoreState>,
    private navbarMapper: NavbarMapper,
    private megaMenuMapper: MegaMenuMapper,
    private headerColorsMapper: ColorsMapper,
    private cssColorService: CssColorService,
    private appSettingsApiService: AzureAppConfigurationApiService,
  ) {}

  loadAzureAppConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.loadAzureAppConfiguration),
      exhaustMap((action) => {
        this.coreStore.dispatch(
          CoreActions.setLoadAzureAppConfigurationServerInteractionState({ state: ServerInteractionStateType.LOADING }),
        );

        return this.appSettingsApiService.getAzureAppConfiguration().pipe(
          switchMap((appSettings) => [
            CoreActions.setAzureAppConfiguration({ azureAppConfiguration: appSettings }),
            CoreActions.setLoadAzureAppConfigurationServerInteractionState({
              state: ServerInteractionStateType.SUCCESS,
            }),
          ]),
          catchError((error) => {
            return of(
              CoreActions.setLoadAzureAppConfigurationServerInteractionState({
                state: ServerInteractionStateType.ERROR,
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  setBrowserIndicator$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.setBrowserIndicator),
        map((action) => {
          // Get the user-agent string
          let userAgentString = navigator.userAgent;

          // Detect Chrome
          let chromeAgent = userAgentString.indexOf('Chrome') > -1;

          // Detect Internet Explorer
          let IExplorerAgent = userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1;

          // Detect Firefox
          let firefoxAgent = userAgentString.indexOf('Firefox') > -1;

          // Detect Safari
          let safariAgent = userAgentString.indexOf('Safari') > -1;

          // Discard Safari since it also matches Chrome
          if (chromeAgent && safariAgent) safariAgent = false;

          // Detect Opera
          let operaAgent = userAgentString.indexOf('OP') > -1;

          // Discard Chrome since it also matches Opera
          if (chromeAgent && operaAgent) chromeAgent = false;

          document.documentElement.setAttribute('browser-chrome', chromeAgent.toString());
          document.documentElement.setAttribute('browser-ie', IExplorerAgent.toString());
          document.documentElement.setAttribute('browser-firefox', firefoxAgent.toString());
          document.documentElement.setAttribute('browser-safari', safariAgent.toString());
          document.documentElement.setAttribute('browser-opera', operaAgent.toString());
        }),
      ),
    { dispatch: false },
  );

  setStylesheet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.setStylesheet),
        concatLatestFrom(() => [this.coreStore.select(CoreSelectors.selectPortalConfiguration)]),
        map(([action, portalConfig]) => {
          // console.log('handle the effect for CoreActions.setStylesheet', action, portalConfig);
          // this.cssColorService.setCssColors({
          //   prefix: 'felib',
          //   items: [
          //     {
          //       name: 'primary-teacher',
          //       hex100: this.siteConfiguration.PrimaryColor,
          //       add100Suffix: true,
          //       variants: [80, 50, 20, 10],
          //     },
          //     {
          //       name: 'primary-student',
          //       hex100: this.siteConfiguration.PrimaryColor,
          //       add100Suffix: true,
          //       variants: [80, 50, 20, 10],
          //     },
          //     {
          //       name: 'success',
          //       hex100: '#6dddb2',
          //     },
          //     {
          //       name: 'error',
          //       hex100: '#e8505c',
          //     },
          //     {
          //       name: 'grey',
          //       hex100: '#071839',
          //       add100Suffix: true,
          //       variants: [90, 80, 70, 60, 50, 40, 30, 20, 10],
          //     },
          //   ],
          // });
          if (portalConfig.colors) {
            const siteBackgroundColor = this.cssColorService.hexToRgb(portalConfig.colors.siteBackgroundColor);
            const siteTextColor = this.cssColorService.hexToRgb(portalConfig.colors.siteTextColor);
            const siteLinkColor = this.cssColorService.hexToRgb(portalConfig.colors.siteLinkColor);
            const primaryColor = this.cssColorService.hexToRgb(portalConfig.colors.primaryColor);
            const primaryTextColor = this.cssColorService.hexToRgb(portalConfig.colors.primaryTextColor);
            const secondaryColor = this.cssColorService.hexToRgb(portalConfig.colors.secondaryColor);
            const secondaryTextColor = this.cssColorService.hexToRgb(portalConfig.colors.secondaryTextColor);

            if (siteBackgroundColor) {
              document.documentElement.style.setProperty('--color-background', siteBackgroundColor);
            }
            if (siteTextColor) {
              document.documentElement.style.setProperty('--color-text', siteTextColor);
            }
            if (siteLinkColor) {
              document.documentElement.style.setProperty('--color-link', siteLinkColor);
            }
            if (primaryColor) {
              document.documentElement.style.setProperty('--color-primary-background', primaryColor);
            }
            if (primaryTextColor) {
              document.documentElement.style.setProperty('--color-primary-text', primaryTextColor);
            }
            if (secondaryColor) {
              document.documentElement.style.setProperty('--color-secondary-background', secondaryColor);
            }
            if (secondaryTextColor) {
              document.documentElement.style.setProperty('--color-secondary-text', secondaryTextColor);
            }
          }
          if (portalConfig.headerColors) {
            const header: Header = {
              InitialBackgroundColor: this.cssColorService.hexToRgb(portalConfig.headerColors.primaryBackgroundColor),
              InitialTextColor: this.cssColorService.hexToRgb(portalConfig.headerColors.primaryTextColor),
              ScrolledBackgroundColor: this.cssColorService.hexToRgb(
                portalConfig.headerColors.secondaryBackgroundColor,
              ),
              ScrolledTextColor: this.cssColorService.hexToRgb(portalConfig.headerColors.secondaryTextColor),
            };

            document.documentElement.style.setProperty(
              '--header-background-initial',
              header.InitialBackgroundColor || null,
            );
            document.documentElement.style.setProperty('--header-text-initial', header.InitialTextColor || null);

            document.documentElement.style.setProperty(
              '--header-background-scrolled',
              header.ScrolledBackgroundColor || null,
            );
            document.documentElement.style.setProperty('--header-text-scrolled', header.ScrolledTextColor || null);
          }
        }),
      ),
    { dispatch: false },
  );

  loadSiteBootstrap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.loadSiteBootstrap),
      exhaustMap(() => {
        this.coreStore.dispatch(
          CoreActions.setLoadSiteBootstrapServerInteractionState({ state: ServerInteractionStateType.LOADING }),
        );

        return this.umbracoApi.getSiteBootstrap().pipe(
          retry(1),
          switchMap(({ configuration, menus }: SiteBootstrap) => {
            const siteColors: SiteColors = this.headerColorsMapper.getPortalColors(configuration);
            const headerColors: SiteHeaderColors = this.headerColorsMapper.getPortalHeaderColors(configuration);

            const primaryMenuId = configuration.menus.find(
              (menuItem) => menuItem.isActive && menuItem.type === 'primary',
            )?.id;
            const megaMenuId: number | undefined = configuration.menus.find(
              (menuItem) => menuItem.isActive && menuItem.type === 'mega',
            )?.id;

            let actions: Action[] = [
              CoreActions.setPortalConfiguration({
                config: {
                  customPagesInfo: (configuration.customPagesInfo || [])
                    .filter((x) => isPageType(x.pageType))
                    .map((x) => {
                      return { customUrl: x.customUrl || '', pageType: x.pageType as pageType, id: x.id };
                    }),
                  navbar: [],
                  ISBNs: configuration.isbns,
                  projectGroup: configuration.portalProducts[0].externalId,
                  googleTagManagerId: configuration.googleGTM || '',
                  errorPages:
                    configuration.errorpages.map((item) => {
                      return {
                        id: item.id,
                        statusCode: item.statusCode.toString(),
                      };
                    }) || [],
                  portalId: configuration.portalId,
                  id: configuration.id.toString(),
                  frontpageId: configuration.frontpage ? configuration.frontpage.id : undefined,
                  logo:
                    configuration.logoImageUrl || configuration.mobileLogoImageUrl
                      ? {
                          mobile: configuration.mobileLogoImageUrl ?? undefined,
                          default: configuration.logoImageUrl ?? undefined,
                        }
                      : undefined,
                  title: configuration.name,
                  headerColors: headerColors,
                  colors: siteColors,
                },
              }),
              CoreActions.setLoadSiteBootstrapServerInteractionState({ state: ServerInteractionStateType.SUCCESS }),
              CoreActions.setBrowserIndicator(),
              CoreActions.setStylesheet(),
            ];

            if (primaryMenuId && menus[primaryMenuId]) {
              actions = [
                ...actions,
                CoreActions.setNavbar({ navbar: this.navbarMapper.mapNavbarFromApi(menus[primaryMenuId]) }),
              ];
            }

            if (megaMenuId && menus[megaMenuId]) {
              actions = [
                ...actions,
                CoreActions.setMegaMenu({ megaMenu: this.megaMenuMapper.mapMegaMenuFromApi(menus[megaMenuId]) }),
              ];
            }

            return actions;
          }),
          catchError((error) => {
            return [
              CoreActions.setLoadSiteBootstrapServerInteractionState({
                state: ServerInteractionStateType.ERROR,
                error,
              }),
            ];
          }),
        );
      }),
    ),
  );

  loadOperationalNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.operationalNotificationLoad),
      exhaustMap(() => {
        this.coreStore.dispatch(
          CoreActions.operationalNotificationLoadSetServerInteractionState({
            state: ServerInteractionStateType.LOADING,
          }),
        );

        return this.umbracoApi.getOperationalNotifications().pipe(
          retry(1),
          switchMap((operationalNotification) => [
            CoreActions.operationalNotificationLoadSetServerInteractionState({
              state: ServerInteractionStateType.SUCCESS,
            }),
            operationalNotification
              ? CoreActions.operationalNotificationSetData({ operationalNotification })
              : CoreActions.operationalNotificationClearData(),
          ]),
          catchError((error) => {
            return [
              CoreActions.operationalNotificationLoadSetServerInteractionState({
                state: ServerInteractionStateType.ERROR,
                error,
              }),
            ];
          }),
        );
      }),
    ),
  );

  operationalNotificationReadDismissedFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.operationalNotificationReadDismissedFromLocalStorage),
      map(({ userId }) => {
        const dismissedNotificationTokensString = localStorage.getItem(
          getLocalStorageKeyForDismissedOperationalNotifications(userId),
        );

        // Split the string if something was returned — otherwise default to an empty array
        const dismissedNotificationTokens = dismissedNotificationTokensString?.split(',') ?? [];

        return CoreActions.operationalNotificationStoreDismissed({ dismissedNotificationTokens });
      }),
    ),
  );

  dismissOperationalNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.operationalNotificationDismiss),
      concatLatestFrom(() => [this.coreStore.select(CoreSelectors.selectOperationalNotificationDismissedTokens)]),
      switchMap(([{ notificationToken, userId }, previouslyDismissedNotificationTokens]) => {
        // Add the newly dismissed token to the beginning of the array and limit the array length to 10, to avoid a forever growing array of unused tokens
        const dismissedNotificationTokens = [notificationToken, ...previouslyDismissedNotificationTokens].slice(0, 10);

        return [
          CoreActions.operationalNotificationWriteDismissedToLocalStorage({ dismissedNotificationTokens, userId }),
          CoreActions.operationalNotificationStoreDismissed({ dismissedNotificationTokens }),
        ];
      }),
    ),
  );

  operationalNotificationWriteDismissedToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.operationalNotificationWriteDismissedToLocalStorage),
        map(({ dismissedNotificationTokens, userId }) => {
          if (userId) {
            localStorage.setItem(
              getLocalStorageKeyForDismissedOperationalNotifications(userId),
              dismissedNotificationTokens.join(','),
            );
          }
        }),
      ),
    { dispatch: false },
  );
}
