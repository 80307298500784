<app-message [variant]="MessageVariants.ERROR">
  <h2 class="heading-2">{{ heading || ('shared.components.ui.error-message.default-heading' | translate) }}</h2>
  <ul *ngIf="validationErrors; else templateNoValidationErrorsRecords" class="validation-errors-list">
    <li *ngFor="let error of validationErrors | keyvalue">{{ error.value.errorCode | translate }}</li>
  </ul>
  <p *ngIf="allowRetry || enableBackButton || displayLoginButton" class="button-wrapper">
    <app-button *ngIf="enableBackButton" [appBackButton]="backButtonLink" [icon]="Icons.NAVIGATION_ARROW_LEFT">{{
      'shared.components.ui.error-message.back-button' | translate
    }}</app-button>
    <app-button *ngIf="displayLoginButton" (click)="onLoginClick()">{{
      'shared.components.ui.error-message.login-button' | translate
    }}</app-button>
    <app-button *ngIf="allowRetry" [icon]="Icons.RELOAD" (click)="onRetryClick()">{{
      'shared.components.ui.error-message.retry-button' | translate
    }}</app-button>
  </p>
</app-message>

<ng-template #templateNoValidationErrorsRecords>
  <p *ngIf="message" class="message">{{ message | translate }}</p>
  <p *ngIf="httpStatus" class="http-status">
    {{ 'shared.components.ui.error-message.http-status-code-message' | translate: { httpStatusCode: httpStatus } }}
  </p>
</ng-template>
