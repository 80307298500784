import { TeamsState } from '@app/teams/interfaces/teams.interfaces';
import { initialServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { createReducer, on } from '@ngrx/store';

import * as TeamsActions from './teams.actions';

export const teamsFeatureKey = 'TeamsState';

export const initialTeamsState: TeamsState = {
  teams: [],
  subTeamsByTeam: {},
  studentsByTeam: {},
  loadTeamsServerInteractionState: initialServerInteractionState,
  loadStudentsForTeamServerInteractionState: {},
  globalTeamId: undefined,
  globalSubTeamId: undefined,
  noTeams: false,
  alertTeamChange: false,
  globalTeamUserSelected: false,
};

export const teamsReducer = createReducer(
  initialTeamsState,
  on(TeamsActions.setTeams, (state, action) => ({
    ...state,
    teams: action.teams,
    noTeams: !!!action.teams.length,
  })),
  on(TeamsActions.setLoadTeamsServerInteractionState, (state, action) => ({
    ...state,
    loadTeamsServerInteractionState: { state: action.state, error: action.error || null },
  })),

  on(TeamsActions.setSubTeamsForTeam, (state, action) => ({
    ...state,
    subTeamsByTeam: {
      ...state.subTeamsByTeam,
      [action.teamId]: {
        serverInteractionState:
          state.subTeamsByTeam[action.teamId]?.serverInteractionState ?? initialServerInteractionState,
        subTeams: action.subTeams,
      },
    },
  })),
  on(TeamsActions.setSubTeamsForTeamServerInteractionState, (state, action) => ({
    ...state,
    subTeamsByTeam: {
      ...state.subTeamsByTeam,
      [action.teamId]: {
        serverInteractionState: { state: action.state, error: action.error || null },
        subTeams: state.subTeamsByTeam[action.teamId]?.subTeams ?? [],
      },
    },
  })),

  on(
    TeamsActions.setStudentsForTeam,
    (state, action): TeamsState => ({
      ...state,
      studentsByTeam: {
        ...state.studentsByTeam,
        [action.teamId]: action.students,
      },
    }),
  ),

  on(
    TeamsActions.setStudentsForTeamServerInteractionState,
    (state, action): TeamsState => ({
      ...state,
      loadStudentsForTeamServerInteractionState: {
        ...state.loadStudentsForTeamServerInteractionState,
        [action.teamId]: {
          state: action.state,
          error: action.error || null,
        },
      },
    }),
  ),
  on(
    TeamsActions.setGlobalTeam,
    (state, action): TeamsState => ({
      ...state,
      globalTeamId: action.teamId,
      globalTeamUserSelected: action.globalTeamUserSelected,
    }),
  ),
  on(
    TeamsActions.setGlobalSubTeam,
    (state, action): TeamsState => ({
      ...state,
      globalSubTeamId: action.subTeamId,
    }),
  ),
  on(
    TeamsActions.unsetGlobalTeam,
    (state): TeamsState => ({
      ...state,
      globalTeamId: undefined,
    }),
  ),
  on(
    TeamsActions.unsetGlobalSubTeam,
    (state): TeamsState => ({
      ...state,
      globalSubTeamId: undefined,
    }),
  ),

  on(
    TeamsActions.setAlertTeamChange,
    (state, action): TeamsState => ({
      ...state,
      alertTeamChange: action.change,
    }),
  ),
);
