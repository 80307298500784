import { Injectable } from '@angular/core';
import { FindContentApiService } from '@app/find-content/services/find-content-api.service';
import { SearchByIdsState } from '@app/search-by-ids/interfaces/search-by-ids-state.interface';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createLoadIfNecessaryEffect } from '@utils/store.utils';
import { merge, of } from 'rxjs';
import { catchError, delay, mergeMap, switchMap } from 'rxjs/operators';

import * as SearchByIdsActions from './search-by-ids.actions';
import * as SearchByIdsSelectors from './search-by-ids.selectors';

@Injectable()
export class SearchByIdsEffects {
  constructor(
    private actions$: Actions,
    private findContentApiService: FindContentApiService,
    private store: Store<SearchByIdsState>,
  ) {}

  searchByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchByIdsActions.searchByIds),
      mergeMap(({ ids }) =>
        merge(
          of(
            SearchByIdsActions.setSearchByIdsServerInteractionState({
              ids,
              state: ServerInteractionStateType.LOADING,
            }),
          ),
          this.findContentApiService.searchByIds(ids).pipe(
            delay(1000),
            switchMap((searchResultItems) => [
              SearchByIdsActions.setSearchByIdsResult({ ids, searchResultItems }),
              SearchByIdsActions.setSearchByIdsServerInteractionState({
                ids,
                state: ServerInteractionStateType.SUCCESS,
              }),
            ]),
            catchError((error) => [
              SearchByIdsActions.setSearchByIdsServerInteractionState({
                ids,
                state: ServerInteractionStateType.ERROR,
                error,
              }),
            ]),
          ),
        ),
      ),
    ),
  );

  searchByIdsIfNecessary$ = createLoadIfNecessaryEffect(
    this.actions$,
    SearchByIdsActions.searchByIdsIfNecessary,
    ({ ids }) => this.store.select(SearchByIdsSelectors.selectSearchByIdsServerInteractionState(ids)),
    ({ ids }) => [SearchByIdsActions.searchByIds({ ids })],
  );
}
