import { ModuleWithProviders, NgModule } from '@angular/core';
import { InlineSVGConfig, InlineSVGModule } from 'ng-inline-svg-2';

@NgModule({
  imports: [InlineSVGModule],
  exports: [InlineSVGModule],
})
export class InlineSVGSharedModule {
  static forRoot(config?: InlineSVGConfig): ModuleWithProviders<InlineSVGModule> {
    if (!config) {
      // console.log('forRoot: config NOT provided - config:', config);
      return InlineSVGModule.forRoot({ baseUrl: '/assets/svg/' });
    }
    // console.log('forRoot: config present - config:', config);
    return InlineSVGModule.forRoot(config);
  }
}
