<app-modal-content-layout
  [heading]="
    ((userService.isTeacher$ | async)
      ? 'page.components.not-authorized-modal-content-for-teachers.heading'
      : 'page.components.not-authorized-modal-content-for-students.heading'
    ) | translate
  "
  [message]="
    ((userService.isTeacher$ | async)
      ? 'page.components.not-authorized-modal-content-for-teachers.message'
      : 'page.components.not-authorized-modal-content-for-students.message'
    ) | translate
  "
  ><app-modal-buttons
    ><app-button (click)="okClick.emit()">{{ 'app.actions.ok' | translate }}</app-button></app-modal-buttons
  ></app-modal-content-layout
>
