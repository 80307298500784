import { Pipe, PipeTransform } from '@angular/core';
import { pascalToKebab } from '@utils/string';

@Pipe({
  name: 'pascalToKebab',
  standalone: true,
})
export class PascalToKebabPipe implements PipeTransform {
  transform(value: string): string {
    return pascalToKebab(value);
  }
}
