/**
 * Returns true if the user is using a device with hover (and a mouse/pointer:fine).
 */
export function isHoverableDevice(): boolean {
  return window.matchMedia('(hover: hover) and (pointer: fine)').matches;
}

/**
 * Returns true if the user is using a mobile device (phone or tablet).
 */
export function isOnMobileDevice(): boolean {
  return isOnIosDevice() || isOnAndroidDevice();
}

/**
 * Returns true if the user is using an Apple device.
 */
export function isOnAppleDevice(): boolean {
  return navigator.platform === 'MacIntel' || isOnIosDevice();
}

/**
 * Returns true if the user is using an iOS device.
 */
export function isOnIosDevice(): boolean {
  return isOnIphone() || isOnIpad() || isOnIpod();
}

/**
 * Returns true if the user is using an Android device.
 */
export function isOnAndroidDevice(): boolean {
  return navigator.userAgent.includes('Android');
}

/**
 * Returns true if the user is using a Samsung device.
 */
export function isOnSamsungDevice(): boolean {
  return !!navigator.userAgent.match(/\sSM-\w+\)?\s/);
}

/**
 * Returns true if the user is using an iPhone.
 */
export function isOnIphone(): boolean {
  return ['iPhone Simulator', 'iPhone'].includes(navigator.platform);
}

/**
 * Returns true if the user is using an iPod.
 */
export function isOnIpod(): boolean {
  return ['iPod Simulator', 'iPod'].includes(navigator.platform);
}

/**
 * Returns true if the user is using an iPad.
 * Returns true if the user is using any iPad.
 */
export function isOnIpad(): boolean {
  return isOnIpadWithIos() || isOnIpadWithIpadOs();
}

/**
 * Returns true if the user is using an iPad running iOS.
 */
export function isOnIpadWithIos(): boolean {
  return ['iPad Simulator', 'iPad'].includes(navigator.platform);
}

/**
 * Returns true if the user is using an iPad running iPadOS.
 */
export function isOnIpadWithIpadOs(): boolean {
  return navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
}
