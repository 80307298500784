import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Params, RouterLink } from '@angular/router';
import { loadingEnterLeaveAnimation } from '@shared-animations/loading.animation';
import { Icons } from '@shared-data/icons';
import { IconSize } from '@shared-enums/icon-size.enum';
import { Icon } from '@shared-interfaces/icon.interface';
import { RouterLinkCommands } from '@shared-types/router-link-command.type';

import { IconComponent } from '../icon/icon.component';
import { LoadingAnimationComponent } from '../loading-animation/loading-animation.component';

export enum ButtonVariant {
  PRIMARY = 'primary',
  GHOST = 'ghost',
  TEXT = 'text',
  WHITE = 'white',
  GLOBAL = 'global',
}

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
  MEGA = 'mega',
}

@Component({
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, RouterLink, IconComponent, LoadingAnimationComponent],
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  animations: [loadingEnterLeaveAnimation],
})
export class ButtonComponent<T> {
  @Input() active: boolean = false;
  @Input() autofocus: boolean = false;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() icon?: Icon;
  @Input() iconOnHover?: Icon;
  @Input() iconSize?: IconSize;

  /**
   * Sets the aspect ratio of the button to 1/1 and uses the `buttonText` input as its title
   */
  @Input() iconOnly?: boolean;
  @Input() rightAlignIcon: boolean = false;
  @Input() size: ButtonSize = ButtonSize.DEFAULT;
  @Input() type: string = 'button';
  @Input() variant: ButtonVariant = ButtonVariant.PRIMARY;
  @Input() href?: any;
  @Input() routerLink?: RouterLinkCommands;
  @Input() queryParams?: Params;
  @Input() target?: string;
  @Input() buttonText?: string;
  @Input() data?: T;

  @Output() buttonClick = new EventEmitter<T>();

  @HostBinding('style.pointer-events') private get pointerEvents(): string | null {
    return this.disabled ? 'none' : null;
  }

  // Override the tabindex set by [routerLink], since we're relaying the link and the tabindex to the contained <a>
  @HostBinding('tabindex') private tabindex = '-1';

  public ButtonVariant = ButtonVariant;
  public ButtonSize = ButtonSize;
  public Icons = Icons;
  public IconSize = IconSize;

  constructor(private elementRef: ElementRef) {}

  stopPropogationClickEvent(event: MouseEvent) {
    if (this.data) {
      event.stopPropagation();
    }
    this.buttonClick.emit(this.data);
  }

  get buttonVariantClassName(): string {
    return `${this.variant}-variant`;
  }

  get buttonSizeClassName(): string {
    return `${this.size}-button`;
  }

  get isLink(): boolean {
    return !!this.href;
  }

  get isRouterLink(): boolean {
    return !!this.routerLink;
  }

  get isButton(): boolean {
    return !(this.isLink || this.isRouterLink);
  }

  public focus(): void {
    this.elementRef.nativeElement.firstElementChild.focus();
  }
}
