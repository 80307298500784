import {
  AssignContentData,
  AssignContentRequestData,
} from '@app/assignments-managed/interfaces/assignments-managed.interfaces';
import {
  ManagedAssignment,
  ManagedAssignmentRequestData,
} from '@app/assignments-managed/interfaces/managed-assignment.interface';
import { mapManagedAssignmentItemsToAssignContentItemData } from '@app/assignments-managed/mappers/managed-assignment-item-to-assign-content-item-data.mapper';

export function mapManagedAssignmentRequestDataToManagedAssignment(
  assignment: ManagedAssignmentRequestData,
): ManagedAssignment {
  return {
    ...assignment,
    startDate: new Date(assignment.startDate),
    endDate: new Date(assignment.endDate),
  };
}

/**
 * Converts an object from the AssignContentData type to the AssignContentRequestData type.
 */
export function mapAssignContentDataToAssignContentPostData({
  title,
  message,
  teamId,
  assignees,
  startDate,
  endDate,
  items,
}: AssignContentData): AssignContentRequestData {
  return {
    title,
    message,
    teamId,
    assignees,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    items: mapManagedAssignmentItemsToAssignContentItemData(items),
  };
}
