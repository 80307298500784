import { Injectable } from '@angular/core';
import { GoogleTagManagerEventProperties } from '@app/tracking/interfaces/google-tag-manager-event-properties.interface';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private isLoaded = false;

  private id: string = '';

  public setId(id: string): void {
    this.id = id;
  }

  public getDataLayer(): Object[] {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  private pushOnDataLayer(obj: object): void {
    const dataLayer = this.getDataLayer();

    dataLayer.push(obj);
  }

  public addGtmToDom(): void {
    if (this.isLoaded) {
      return;
    }

    this.pushOnDataLayer({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const gtmScript = document.createElement('script');

    gtmScript.id = 'GTMscript';
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${this.id}`;
    document.head.insertBefore(gtmScript, document.head.firstChild);
    this.isLoaded = true;
  }

  public pushTag(item: GoogleTagManagerEventProperties): void {
    this.addGtmToDom();

    this.pushOnDataLayer(item);
  }
}
