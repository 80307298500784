<ng-template #buttonContent>
  <app-loading-animation *ngIf="loading" class="button-loading-animation" @loadingEnterLeave></app-loading-animation>
  <span class="button-content" [class.right-align-icon]="rightAlignIcon">
    <span
      *ngIf="icon && icon !== Icons.BLANK"
      class="button-icon-wrapper"
      [class.has-hover-icon]="iconOnHover && iconOnHover !== Icons.BLANK"
    >
      <app-icon [icon]="icon" [size]="iconSize" class="button-icon"></app-icon>
      <app-icon
        *ngIf="iconOnHover && iconOnHover !== Icons.BLANK"
        [icon]="iconOnHover"
        [size]="iconSize"
        class="button-icon button-hover-icon"
      ></app-icon>
    </span>
    <span *ngIf="!iconOnly" class="button-text">
      <ng-container *ngIf="buttonText"> {{ buttonText }}</ng-container>
      <ng-content></ng-content>
    </span>
  </span>
</ng-template>
<button
  *ngIf="isButton"
  class="button"
  [class]="buttonVariantClassName + ' ' + buttonSizeClassName"
  [class.active]="active"
  [class.is-loading]="loading"
  [class.icon-only]="iconOnly"
  [attr.autofocus]="autofocus || null"
  [attr.disabled]="disabled || null"
  [type]="type"
  [attr.title]="iconOnly ? buttonText : null"
  (click)="data ? stopPropogationClickEvent($event) : undefined"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<a
  *ngIf="isLink"
  [href]="href"
  class="button"
  [class]="buttonVariantClassName + ' ' + buttonSizeClassName"
  [class.active]="active"
  [class.is-loading]="loading"
  [class.icon-only]="iconOnly"
  [attr.target]="target || null"
  [attr.autofocus]="autofocus || null"
  [class.diabled]="disabled || null"
  [attr.title]="iconOnly ? buttonText : null"
  tabindex="0"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</a>

<a
  *ngIf="isRouterLink"
  [routerLink]="routerLink"
  [queryParams]="queryParams"
  class="button"
  [class]="buttonVariantClassName + ' ' + buttonSizeClassName"
  [class.active]="active"
  [class.is-loading]="loading"
  [class.icon-only]="iconOnly"
  [attr.target]="target || null"
  [attr.autofocus]="autofocus || null"
  [class.disabled]="disabled || null"
  [attr.title]="iconOnly ? buttonText : null"
  tabindex="0"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</a>
