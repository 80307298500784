import { Injectable } from '@angular/core';
import { ContentType } from '@app/content/enums/content-type.enum';
import { SearchResultItem } from '@app/find-content/interfaces/search-result-item.interface';

import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingFunctionsService {
  constructor(private trackingService: TrackingService) {}

  // Search tracking BEGIN

  /**
   * Sends a tracking event to tracking service when a user
   * selects one topic or all topics in a competenceArea
   *
   * @param trackingTagName
   * @param competenceArea
   * @param skillArea
   * @param topic
   * @param contentType
   */
  public trackSearchTopicSelection(
    trackingTagName: string,
    competenceArea: string,
    skillArea: string,
    topic: string | null,
    contentType: ContentType,
  ): void {
    this.trackingService.trackEvent(trackingTagName, {
      competenceArea,
      skillArea,
      topic,
      contentType,
    });
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks the reset topics link so the topic filter is reset
   *
   * @param trackingTagName
   * @param resetAll
   */
  public trackSearchRemoveAllTopics(): void {
    this.trackingService.trackEvent('content-filter-removeAll', {
      resetAll: true,
    });
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks a content card and previews the content
   *
   * @param content
   */
  public trackSearchResultCardPreviewContent(content: SearchResultItem): void {
    this.trackingService.trackEvent('content-preview', {
      type: content.$type,
      topics: content.topics.flatMap((topic) => topic.name),
      title: content.title,
    });
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks the 'add to basket' button in a search content card
   */
  public trackSearchResultCardAddToBasket(contentType: ContentType, title: string, topicNames: string[] = []): void {
    this.trackingService.trackEvent('content-add', {
      type: contentType,
      topics: topicNames,
      title,
    });
  }

  // Search tracking END

  // Cart tracking BEGIN

  /**
   * Sends a tracking event to trackingService when a user
   * opens the basket
   *
   */
  public trackCartOpenBasket(): void {
    this.trackingService.trackEvent('content-open-basket');
  }

  /**
   * Sends a tracking event to trackingService when a user
   * empties the basket
   *
   */
  public trackCartEmptyBasket(): void {
    this.trackingService.trackEvent('content-empty-basket-all');
  }

  /**
   * Sends a tracking event to trackingService when a user
   * removes an item from the basket
   *
   */
  public trackCartRemoveItem(): void {
    this.trackingService.trackEvent('content-empty-basket-item');
  }

  /**
   * Sends a tracking event to trackingService when a user
   * empties the basket
   *
   */
  public trackCartGoToCreateAssignment(): void {
    this.trackingService.trackEvent('content-create-assignment');
  }

  // Cart tracking END

  // Assign content tracking BEGIN

  /**
   * Sends a tracking event to trackingService when a user
   * rearranges items in the assign content editor
   *
   * @param editMode
   * Is the user editing an existing assignment or making a new
   */
  public trackAssignContentRearrangeItems(editMode: boolean): void {
    this.trackingService.trackEvent('assign-rearrange', {
      editMode,
    });
  }

  /**
   * Sends a tracking event to trackingService when a user
   * deletes an item in the assign content editor
   *
   * @param editMode
   * Is the user editing an existing assignment or making a new
   */
  public trackAssignContentDeleteItem(editMode: boolean): void {
    this.trackingService.trackEvent('assign-delete', {
      editMode,
    });
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks the button to go to the assignment configuration
   *
   * @param editMode
   * Is the user editing an existing assignment or making a new
   */
  public trackAssignContentGoToConfiguration(editMode: boolean): void {
    this.trackingService.trackEvent('assign-step2', {
      editMode,
    });
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks the button to go to the assignment summary
   *
   * @param editMode
   * Is the user editing an existing assignment or making a new
   */
  public trackAssignContentGoToSummary(editMode: boolean): void {
    this.trackingService.trackEvent('assign-step3', {
      editMode,
    });
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks the button to finish and assign the content
   *
   * @param editMode
   * Is the user editing an existing assignment or making a new
   */
  public trackAssignContentFinishAssignment(editMode: boolean): void {
    this.trackingService.trackEvent('assign-done', {
      editMode,
    });
  }

  // Assign content tracking END

  // Team assignments page tracking BEGIN

  /**
   * Sends a tracking event to trackingService when a user
   * clicks on an assignemnt in the assignment overview for a team
   *
   * @param completed
   * Is the assignment overdue or not
   */
  public trackTeamAssignmentsViewAssignment(status: string): void {
    this.trackingService.trackEvent('assignment-choose', {
      status,
    });
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks the button to view the students that has the assignment
   */
  public trackTeamAssignmentsViewStudentsOverview(): void {
    this.trackingService.trackEvent('assignment-students-overview');
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks the button to create a new assignment
   */
  public trackTeamAssignmentsCreateNewAssignment(): void {
    this.trackingService.trackEvent('assignment-create-new');
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks the button to delete the assignment
   */
  public trackTeamAssignmentsDeleteAssignment(): void {
    this.trackingService.trackEvent('assignment-delete');
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks the button to edit the assignment
   */
  public trackTeamAssignmentsEditAssignment(): void {
    this.trackingService.trackEvent('assignment-edit');
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks the live statistics button on a content element inside an assignment
   *
   * TODO Not implemented yet
   */
  public trackTeamAssignmentsViewLiveStatistics(): void {
    this.trackingService.trackEvent('assignment-livestatistic');
  }

  /**
   * Sends a tracking event to trackingService when a user
   * has navigated into live statistics for one assignment element
   * and thereafter sorts the students
   *
   * @param sortingColumn
   * THe column the user sorts the students after, ie name
   */
  public trackTeamAssignmentsLiveStatisticsSortStudents(sortingColumn: string): void {
    this.trackingService.trackEvent('assignment-sort', { sortingColumn });
  }

  // Team assignments page tracking END

  // Evaluation BEGIN

  /**
   * Sends a tracking event to trackingService when a user
   * clicks on a student accordian
   */
  public trackEvaluationClickOnStudent(): void {
    this.trackingService.trackEvent('evaluation-student');
  }

  /**
   * Sends a tracking event to trackingService when a user
   * clicks on a topic under a student
   */
  public trackEvaluationClickOnTopic(): void {
    this.trackingService.trackEvent('evaluation-student-topic');
  }

  // Evaluation END
}
