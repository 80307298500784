import { Injectable } from '@angular/core';
import { GradeLevel } from '@app/grade-levels/interfaces/grade-level.interface';
import * as LevelsActions from '@app/grade-levels/store/grade-levels.actions';
import * as LevelsSelectors from '@app/grade-levels/store/grade-levels.selectors';
import { Store } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';
import { getArrayExtremes } from '@utils/array';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GradeLevelsState } from '../interfaces/grade-levels-state.interface';

@Injectable({
  providedIn: 'root',
})
export class GradeLevelsService {
  levels$ = this.store.select(LevelsSelectors.selectGradeLevelsSorted);
  levelGroups$ = this.store.select(LevelsSelectors.selectGradeLevelGroups);

  constructor(private store: Store<GradeLevelsState>) {
    // Load grade-levels delayed to make sure the action is detected by effects
    setTimeout(() => {
      this.loadLevels();
    });
  }

  public loadLevels(): void {
    this.store.dispatch(LevelsActions.loadGradeLevels());
  }

  public getLevelObservable(gradeLevelId: GUID): Observable<GradeLevel | undefined> {
    return this.store.select(LevelsSelectors.selectGradeLevelById(gradeLevelId));
  }

  /**
   * Returns an array of sorted grade levels.
   * Any non-existing grade level IDs will be ignored.
   */
  public getLevelObservables(gradeLevelIds: GUID[]): Observable<GradeLevel[]> {
    const levelObservables = gradeLevelIds.map((gradeLevelId) => this.getLevelObservable(gradeLevelId));

    return combineLatest(levelObservables).pipe(
      // Filter out any missing grade levels
      map((gradeLevels) => gradeLevels.filter((gradeLevel) => gradeLevel !== undefined) as GradeLevel[]),
      // Sort the grade levels
      map((gradeLevels) => gradeLevels.sort((a, b) => a.order - b.order)),
    );
  }

  /**
   * Formats the given grade levels as a human-readable grade level span.
   * Example output: `1. - 3. klasse`
   */
  public formatLevelSpan(gradeLevels: GradeLevel[], abbreviated: boolean = false): string {
    const gradeLevelExtremes = getArrayExtremes(gradeLevels);

    if (gradeLevelExtremes.first === undefined && gradeLevelExtremes.last === undefined) {
      // Return `-` when no grade-levels are present
      return '-';
    } else if (gradeLevelExtremes.first && gradeLevelExtremes.first === gradeLevelExtremes.last) {
      // Return a single level when the first and last level are equal
      return abbreviated ? gradeLevelExtremes.first.mediumForm : gradeLevelExtremes.first.longForm;
    } else {
      // Return a level span when the first and last level not equal
      return `${gradeLevelExtremes.first ? gradeLevelExtremes.first.shortForm : '?'} – ${
        gradeLevelExtremes.last
          ? abbreviated
            ? gradeLevelExtremes.last.mediumForm
            : gradeLevelExtremes.last.longForm
          : '?'
      }`;
    }
  }
}
