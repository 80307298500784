import { Student } from '@app/teams/interfaces/student.interfaces';
import { SubTeam } from '@app/teams/interfaces/sub-team.interfaces';
import { Team } from '@app/teams/interfaces/teams.interfaces';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { ParsedServerError } from '@core/interfaces/project/parsed-server-error.interface';
import { createAction, props } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';

export const loadTeams = createAction('[Teams/API] Load teams');

export const setAllTeams = createAction(
  '[Teams/API] Set all teams w/o API',
  props<{ teams: Team[]; autoSelectNewTeam?: boolean }>(),
);

export const loadTeamsIfNecessary = createAction('[Teams/API] Load teams if necessary');

export const loadSubTeamsForTeam = createAction('[Teams/API] Load sub teams for team', props<{ teamId: GUID }>());

export const loadStudentsForTeam = createAction('[Teams/API] Load students for team', props<{ teamId: GUID }>());

export const loadSubTeamsForTeamIfNecessary = createAction(
  '[Teams/API] Load sub teams for team if necessary',
  props<{ teamId: GUID }>(),
);

export const loadStudentsForTeamIfNecessary = createAction(
  '[Teams/API] Load students for team if necessary',
  props<{ teamId: GUID }>(),
);

export const setTeams = createAction('[Teams/API] Set teams', props<{ teams: Team[] }>());

export const setAlertTeamChange = createAction('[Teams] Set alert team change', props<{ change: boolean }>());

export const setSubTeamsForTeam = createAction(
  '[Teams/API] Set sub teams for team',
  props<{ teamId: GUID; subTeams: SubTeam[] }>(),
);

export const setStudentsForTeam = createAction(
  '[Teams/API] Set students for team',
  props<{ teamId: GUID; students: Student[] }>(),
);

export const setSubTeamsForTeamServerInteractionState = createAction(
  '[Teams/API] Set sub teams for team server interaction state',
  props<{ teamId: GUID; state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const setStudentsForTeamServerInteractionState = createAction(
  '[Teams/API] Set students for team server interaction state',
  props<{ teamId: GUID; state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const applyGlobalTeamAndSubTeamFromLocalStorage = createAction(
  '[Teams/Local] Apply global team and sub team from local storage',
);

export const autoUpdateGlobalTeam = createAction(
  '[Teams/Local] Auto update global team',
  props<{ teams: Team[]; autoSelectNewTeam?: boolean }>(),
);

export const setGlobalTeam = createAction(
  '[Teams/Local] Set global team',
  props<{ teamId: GUID; globalTeamUserSelected: boolean }>(),
);

export const setGlobalSubTeam = createAction('[Teams/Local] Set global sub team', props<{ subTeamId: GUID }>());

export const unsetGlobalTeam = createAction('[Teams/Local] Unset global team');

export const unsetGlobalSubTeam = createAction('[Teams/Local] Unset global sub team');

export const setLoadTeamsServerInteractionState = createAction(
  '[Teams/API] Set load teams server interaction state',
  props<{ state: ServerInteractionStateType; error?: ParsedServerError }>(),
);
