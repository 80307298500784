import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ standalone: true, name: 'umbracoImage' })
export class UmbracoImagePipe implements PipeTransform {
  transform(
    value: string,
    width?: number,
    height?: number,
    mode?: string,
    backgroundColor?: string,
    quality?: number,
  ): string {
    const imgQuality = quality || 80;

    if (value) {
      const tempParameters = [];
      let parameters = '';

      if (window.useWebP) {
        tempParameters.push('format=webp');
      }

      if (backgroundColor) {
        tempParameters.push('bgcolor=' + backgroundColor);
      }

      tempParameters.push(`quality=${imgQuality}`);
      if (width) {
        tempParameters.push(`width=${width}`);
      }

      if (height) {
        tempParameters.push(`height=${height}`);
      }

      if (mode === 'crop') {
        tempParameters.push('mode=' + mode);
      }

      if (tempParameters.length) {
        parameters = '?' + tempParameters.join('&');
      }

      value = value + parameters;
    }

    return value;
  }
}
