<article class="operational-notification">
  <div class="text-content">
    <h2 class="heading-5">{{ operationalNotification?.title }}</h2>
    <p>{{ operationalNotification?.note }}</p>
  </div>
  <app-icon [icon]="Icons.ERROR" [size]="IconSize.LARGE"></app-icon>
  <app-icon-button
    *ngIf="dismissible"
    class="dismiss-button"
    [icon]="Icons.CLOSE_SMALL"
    [title]="'app.actions.dismiss' | translate"
    (click)="onDismissClick()"
  ></app-icon-button>
</article>
