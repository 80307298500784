import { ToastMessageInstance } from '../interfaces/toast-message-instance.interface';

export interface ToastState {
  messages: ToastMessageInstance[];
  defaultDuration: number;
}

export const initialToastState: ToastState = {
  // The messages are stored in an array and new messages are always added to
  // the end of the array to maintain consistency when displaying them.
  messages: [],
  defaultDuration: 5000,
};
