<div class="heading-1 page-title">
  {{
    '' // The back button is hidden from screen readers using `aria-hidden`, because it is also available through the breadcrumbs
  }}
  <a
    *ngIf="previousPageRouterLink && previousPageTitle"
    [routerLink]="previousPageRouterLink"
    class="page-title-back-button"
    aria-hidden="true"
    [appTooltip]="'shared.components.ui.page-title.previous-page-button-tooltip' | translate: { previousPageTitle }"
  >
    <app-icon
      [icon]="Icons.NAVIGATION_ARROW_LEFT"
      [size]="IconSize.MEDIUM"
      class="page-title-back-button-icon"
    ></app-icon>
  </a>
  <h1 *ngIf="heading" class="page-title-heading" data-testid="page-title">
    {{ heading }}
  </h1>
</div>
