import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { zIndexFeatureKey } from './z-index.reducers';
import { ZIndexState } from './z-index.state';

export const selectZIndexState = createFeatureSelector<ZIndexState>(zIndexFeatureKey);

export const selectCurrentZIndex = createSelector(selectZIndexState, (state) => state.currentZIndex);

export const selectInitialZIndex = createSelector(selectZIndexState, (state) => state.initialZIndex);

export const selectAllItems = createSelector(selectZIndexState, (state) => state.items);

export const getItemById = (id: string) =>
  createSelector(selectAllItems, (items) => {
    return items.find((x) => x.id === id)?.zIndex;
  });
