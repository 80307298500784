import { KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TrackingService } from '@app/tracking/services/tracking.service';
import { UserService } from '@app/user/services/user.service';
import { ValidationError } from '@core/interfaces/project/validation-error.interface';
import { validationErrorType } from '@core/types/validation-error-type';
import { Icons } from '@shared-data/icons';
import { BackButtonDirective } from '@shared-directives/back-button.directive';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';

import { ButtonComponent } from '../button/button.component';
import { MessageComponent, MessageVariants } from '../message/message.component';

@Component({
  standalone: true,
  imports: [KeyValuePipe, NgIf, NgFor, BackButtonDirective, MessageComponent, TranslateSharedModule, ButtonComponent],
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnChanges {
  @Input() allowRetry: boolean = false;
  @Input() enableBackButton: boolean = false;
  @Input() backButtonLink: string = '';
  @Input() heading: string = '';
  @Input() message: string = '';
  @Input() validationErrors?: Record<validationErrorType, ValidationError>;
  @Input() httpStatus?: number;
  @Input() trackingPrefix?: string;
  @Input() preventTracking: boolean = false;
  @Output() retry: EventEmitter<void> = new EventEmitter<void>();

  Icons = Icons;
  MessageVariants = MessageVariants;

  constructor(
    private userService: UserService,
    private trackingService: TrackingService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.preventTracking && changes.message) {
      this.trackingService.trackEvent(this.trackingPrefix || 'error-message', {
        message: this.message,
        httpStatus: this.httpStatus,
      });
    }
  }

  get displayLoginButton(): boolean {
    return this.httpStatus === 401;
  }

  onLoginClick(): void {
    this.userService.userLogin();
  }

  onRetryClick(): void {
    this.retry.emit();
  }
}
