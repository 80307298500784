import {
  AssignContentItemData,
  AssignContentItemDataActivity,
  AssignContentItemDataBase,
  AssignContentItemDataExerciseCollection,
  AssignContentItemDataLesson,
  AssignContentItemDataTraining,
  AssignContentItemDataWorksheet,
} from '@app/assignments-managed/interfaces/assignments-managed.interfaces';
import { ManagedAssignmentItem } from '@app/assignments-managed/interfaces/managed-assignment-item.interface';
import { ContentType } from '@app/content/enums/content-type.enum';

export function mapManagedAssignmentItemsToAssignContentItemData(
  managedAssignmentItems: ManagedAssignmentItem[],
): AssignContentItemData[] {
  return managedAssignmentItems.map((item) => mapManagedAssignmentItemToAssignContentItemData(item));
}

export function mapManagedAssignmentItemToAssignContentItemData(
  managedAssignmentItem: ManagedAssignmentItem,
): AssignContentItemData {
  const base: AssignContentItemDataBase = {
    id: managedAssignmentItem.id,
    contentId: managedAssignmentItem.contentId,
    contentType: managedAssignmentItem.contentType,
    order: managedAssignmentItem.order,
    progressState: managedAssignmentItem.progressState,
    trainingCourseId: managedAssignmentItem.trainingCourseId,
  };

  switch (managedAssignmentItem.contentType) {
    case ContentType.ACTIVITY:
      return base as AssignContentItemDataActivity;
    case ContentType.WORKSHEET:
      return base as AssignContentItemDataWorksheet;
    case ContentType.LESSON:
      return base as AssignContentItemDataLesson;
    case ContentType.EXERCISE_COLLECTION:
      return { ...base, asTest: managedAssignmentItem.asTest } as AssignContentItemDataExerciseCollection;
    case ContentType.TRAINING_CONTEXT:
      return {
        ...base,
        asTest: managedAssignmentItem.asTest,
        exerciseCount: managedAssignmentItem.exerciseCount,
        topicIds: managedAssignmentItem.topics.map((topic) => topic.id),
      } as AssignContentItemDataTraining;
  }
}
