import { NativeDateAdapter } from '@angular/material/core';

export class CustomDateAdapter extends NativeDateAdapter {
  /**
   * Overrides the default first day of week, which is always sunday.
   */
  getFirstDayOfWeek(): number {
    return 1;
  }
}
