export enum DropdownSelectionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export enum DropdownVariant {
  PRIMARY = 'primary',
  GHOST = 'ghost',
  TEXT = 'text',
  WHITE = 'white',
  GLOBAL = 'global',
  PLAIN = 'plain',
}
