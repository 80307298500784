import { Injectable } from '@angular/core';
import { MegaMenu } from '@app/page/interfaces/mega-menu.interface';
import { NavbarItem } from '@app/page/interfaces/navbar.interface';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import * as CoreActions from '@core/store/core.actions';
import * as CoreSelectors from '@core/store/core.selectors';
import { CoreState } from '@core/store/interfaces/core-state.interface';
import { Store } from '@ngrx/store';
import { isLocalhost } from '@utils/network';
import { getObservableValueSync } from '@utils/observables';
import { Observable } from 'rxjs';

import { SiteLogo } from '../interfaces/project/portal-configuration.interface';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  public navbar$: Observable<NavbarItem[]> = this.coreStore.select(CoreSelectors.selectNavbar);
  public megaMenu$: Observable<MegaMenu | undefined> = this.coreStore.select(CoreSelectors.selectMegaMenu);
  public logo$: Observable<SiteLogo | undefined> = this.coreStore.select(CoreSelectors.selectLogo);
  public siteName$: Observable<string> = this.coreStore.select(CoreSelectors.selectSiteName);
  public portalId$: Observable<string> = this.coreStore.select(CoreSelectors.selectPortalId);
  public isDevServer$: Observable<boolean> = this.coreStore.select(CoreSelectors.selectIsDevServer);

  public loadSiteBootstrapServerInteractionState$: Observable<ServerInteractionState> = this.coreStore.select(
    CoreSelectors.selectLoadSiteBootstrapServerInteractionState,
  );

  public initialBootstrapCompleted$: Observable<boolean> = this.coreStore.select(
    CoreSelectors.selectInitialBootstrapCompleted,
  );

  constructor(private coreStore: Store<CoreState>) {}

  public get hostName(): string {
    const hardcodeDomain = 'matematikfessor.alineadigital.dk';
    return isLocalhost() ? hardcodeDomain : window.location.hostname;
  }

  public get isDevServer(): boolean {
    return getObservableValueSync(this.isDevServer$);
  }

  public reloadPortalConfiguration(): void {
    this.coreStore.dispatch(CoreActions.loadSiteBootstrap());
  }
}
