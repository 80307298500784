import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PageState } from '../interfaces/page-state.interface';
import { pageFeatureKey } from './page.reducers';

export const selectPageState = createFeatureSelector<PageState>(pageFeatureKey);

export const selectPage = createSelector(selectPageState, (state: PageState) => state);

export const selectDocumentTitle = createSelector(selectPageState, (state: PageState) => state?.documentTitle);
