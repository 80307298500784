import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { ParsedServerError } from '@core/interfaces/project/parsed-server-error.interface';

/**
 * A generic state object for tracking the state of an interaction with the server.
 */
export interface ServerInteractionState {
  state: ServerInteractionStateType;
  error: ParsedServerError | null;
}

export const initialServerInteractionState: ServerInteractionState = {
  state: ServerInteractionStateType.INITIAL,
  error: null,
};
