import { Point } from '@angular/cdk/drag-drop';

export interface BoxSize {
  width: number;
  height: number;
}

/**
 * Scales a box to fit inside another box while respecting a given padding.
 */
export function aspectScale(elementSize: BoxSize, containerSize: BoxSize, padding: number = 0): BoxSize {
  const aspectRatio = elementSize.height / elementSize.width;
  const doublePadding = padding * 2;

  const maxWidth = containerSize.width - doublePadding;
  const maxHeight = containerSize.height - doublePadding;

  // Assume the image will fit when scaling in respect to the width
  let width = maxWidth;
  let height = width * aspectRatio;

  // Scale in respect to the height instead, if the box became too high
  if (height > maxHeight) {
    height = maxHeight;
    width = height / aspectRatio;
  }

  return { width, height };
}

export function scaleNumber(value: number, min: number, max: number, newMin: number, newMax: number): number {
  return ((value - min) * (newMax - newMin)) / (max - min) + newMin;
}

/**
 * Maps a value from one range to another.
 * Example: `map(0.5, 0, 1, 0, 10)` will return `5`.
 */
export function map(value: number, orgMin: number, orgMax: number, newMin: number, newMax: number): number {
  return ((value - orgMin) * (newMax - newMin)) / (orgMax - orgMin) + newMin;
}

/**
 * Clamps the input value between the given min and max values.
 */
export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

/**
 * Generated a random number within a range.
 * Example: `randomInRange(5, 10)` will generate numbers between 5 and 10.
 */
export function randomInRange(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

/**
 * Adds random noise to the given number within the given range.
 * Example: `addRandomNoiseToNumber(10, 2)` will generate numbers between 8 and 12.
 */
export function addRandomNoiseToNumber(value: number, noise: number): number {
  return randomInRange(value - noise, value + noise);
}

/**
 * Rounds the given value with the given step.
 * Example: `roundToStep(10.3333, 0.25)` will result in 10.25
 */
export function roundToStep(value: number, step: number): number {
  return Math.round(value / step) * step;
}

/**
 * Returns the diagonal value for a given width and height
 */
export function getDiagonal(width: number, height: number): number {
  return Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
}

/**
 * Returns the absolute distance between two points
 */
export function getDistanceBetweenPoints(pointA: Point, pointB: Point): number {
  return Math.sqrt(Math.pow(Math.abs(pointA.x - pointB.x), 2) + Math.pow(Math.abs(pointA.y - pointB.y), 2));
}

/**
 * Returns the absolute distance between two points
 */
export function getAngleBetweenPoints(pointA: Point, pointB: Point): number {
  return Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x);
}

export function radiansToDegrees(radians: number): number {
  return (radians * 180) / Math.PI;
}
export function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}
