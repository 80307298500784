import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { BaseModalComponent } from '@app/modals/components/base-modal/base-modal.component';
import { modalEnterLeaveAnimation } from '@shared-animations/modals.animation';
import { IconButtonComponent } from '@shared-components/ui/icon-button/icon-button.component';
import { Icons } from '@shared-data/icons';
import { IconSize } from '@shared-enums/icon-size.enum';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';

/**
 * A component for displaying modals.
 * The content passed will be the content of the modal.
 * The visibility is controlled by the `isVisible` attribute.
 * If the modal is hidden (or displayed) by user interaction an `isVisibleChange` event will be fired.
 * The component isn't rendered in place, but moved to the <app-modal-outlet> component automatically to prevent issues with semantics and style bleeding.
 */
@Component({
  standalone: true,
  imports: [IconButtonComponent, TranslateSharedModule, BaseModalComponent],
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [modalEnterLeaveAnimation],
})
export class ModalComponent {
  Icons = Icons;
  IconSize = IconSize;

  @Input() isVisible = false;
  @Input() noPadding = false;
  @Input() variant?: string;
  @Input() fullWidth = false;
  @Input() width = '50rem';

  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() animationEnded = new EventEmitter<boolean>();

  public get modalVariant(): string | undefined {
    if (this.variant) {
      return this.variant;
    }

    if (this.noPadding) {
      return 'no-padding';
    }

    return undefined;
  }

  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Hides the modal when dismissed
   */
  public onDismissClick(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
  }
}
