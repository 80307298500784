import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import {
  parsedServerErrorAuthErrorFixture,
  parsedServerErrorNotFoundFixture,
  parsedServerErrorServerErrorFixture,
  parsedServerErrorUnknownErrorFixture,
} from '@core/fixtures/parsed-server-error.fixture';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';

export const serverInteractionStateSuccess: ServerInteractionState = {
  state: ServerInteractionStateType.SUCCESS,
  error: null,
};

export const serverInteractionStateLoading: ServerInteractionState = {
  state: ServerInteractionStateType.LOADING,
  error: null,
};

export const serverInteractionStateNotFoundError: ServerInteractionState = {
  state: ServerInteractionStateType.ERROR,
  error: parsedServerErrorNotFoundFixture,
};

export const serverInteractionStateNetworkError: ServerInteractionState = {
  state: ServerInteractionStateType.ERROR,
  error: parsedServerErrorUnknownErrorFixture,
};

export const serverInteractionStateAuthError: ServerInteractionState = {
  state: ServerInteractionStateType.ERROR,
  error: parsedServerErrorAuthErrorFixture,
};

export const serverInteractionStateServerError: ServerInteractionState = {
  state: ServerInteractionStateType.ERROR,
  error: parsedServerErrorServerErrorFixture,
};
