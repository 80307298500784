import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';

import { GradeLevel } from '../interfaces/grade-level.interface';
import { GradeLevelsState } from '../interfaces/grade-levels-state.interface';
import { gradeLevelsFeatureKey } from './grade-levels.reducers';

export const selectGradeLevelsState = createFeatureSelector<GradeLevelsState>(gradeLevelsFeatureKey);

export const selectGradeLevelsSorted = createSelector(selectGradeLevelsState, (state: GradeLevelsState) =>
  Object.values(state.gradeLevels).sort((a, b) => a.order - b.order),
);

export const selectGradeLevelGroups = createSelector(selectGradeLevelsState, (state: GradeLevelsState) =>
  Object.values(state.gradeLevelGroups),
);

export const selectGradeLevelById = (
  gradeLevelId: GUID,
): MemoizedSelector<object, GradeLevel, DefaultProjectorFn<GradeLevel>> =>
  createSelector(selectGradeLevelsState, (state: GradeLevelsState) => state.gradeLevels[gradeLevelId]);

export const selectGradeLevelByOrder = (
  order: number,
): MemoizedSelector<object, GradeLevel | undefined, DefaultProjectorFn<GradeLevel | undefined>> =>
  createSelector(selectGradeLevelsState, (state: GradeLevelsState) =>
    Object.values(state.gradeLevels).find((level) => level.order === order),
  );
