<app-base-modal
  [isVisible]="isVisible"
  [width]="width"
  [class.full-width]="fullWidth"
  [variant]="modalVariant"
  (animationEnded)="animationEnded.emit($event)"
  (isVisibleChange)="isVisibleChange.next($event)"
>
  <ng-content></ng-content>
  <app-icon-button
    data-testid="modal-dismiss-button"
    class="modal-dismiss-button"
    [icon]="Icons.CLOSE"
    [iconSize]="IconSize.DEFAULT"
    [title]="'shared.components.media.lightbox.dismiss-button' | translate"
    (click)="onDismissClick()"
  ></app-icon-button>
</app-base-modal>
