import { Injectable } from '@angular/core';
import { UmbMetaData } from '@app/page/interfaces/umbraco/umb-meta-data.interface';
import { UmbPage } from '@app/page/interfaces/umbraco/umb-page.interface';
import { SiteBootstrap } from '@core/interfaces/project/site-bootstrap.interface';
import {
  OperationalNotification,
  OperationalNotificationResponse,
} from '@core/interfaces/umbraco/operational-notification.interface';
import { ApiService } from '@core/services/api.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UmbracoApiService {
  constructor(private apiService: ApiService) {}

  getSiteBootstrap(): Observable<SiteBootstrap> {
    return this.apiService.getBFFGateway<SiteBootstrap>(`v1/site-bootstrap`);
  }

  getPage(url: string): Observable<UmbPage> {
    return this.apiService.getUmbraco(url);
  }

  getMetaData(pageId: string, portalId: string): Observable<UmbMetaData> {
    return this.apiService.getUmbraco(`v4/alineaportal/getseometa/${pageId}/${portalId}`);
  }

  getOperationalNotifications(): Observable<OperationalNotification | undefined> {
    return this.apiService.getBFFGateway<OperationalNotificationResponse>('v1/notification').pipe(
      map((response) =>
        response.IsActive && response.Title && response.Note
          ? {
              isActive: response.IsActive,
              note: response.Note,
              title: response.Title,
              notificationToken: response.NotificationToken,
            }
          : undefined,
      ),
    );
  }
}
