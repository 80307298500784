import { NgModule } from '@angular/core';
import { SearchByIdsEffects } from '@app/search-by-ids/store/search-by-ids.effects';
import { searchByIdsFeatureKey, searchByIdsReducer } from '@app/search-by-ids/store/search-by-ids.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(searchByIdsFeatureKey, searchByIdsReducer),
    EffectsModule.forFeature([SearchByIdsEffects]),
  ],
})
export class SearchByIdsModule {}
