<app-error-message
  [heading]="heading"
  [validationErrors]="serverInteractionState.error?.validationErrors"
  [httpStatus]="serverInteractionState.error?.httpStatus"
  [message]="
    serverInteractionState.error?.message || 'app.errors.unknown-error'
      | translate: serverInteractionState.error?.messageParameters
  "
  [trackingPrefix]="trackingPrefix"
  [allowRetry]="allowRetry"
  (retry)="onRetry()"
></app-error-message>
