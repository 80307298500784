import { Injectable } from '@angular/core';
import { UserState } from '@app/user/interfaces/user-state.interface';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { AzureAppConfigurationService } from '@core/services/azure-app-configuration.service';
import { InternalBackendApiService } from '@core/services/internal-backend-api.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { isLocalhost } from '@utils/network';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, retry, switchMap } from 'rxjs/operators';

import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<UserState>,
    private internalBackendApi: InternalBackendApiService,
    private azureAppConfigurationService: AzureAppConfigurationService,
  ) {}

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUser),
      exhaustMap(({ noLoadingAnimation }) => {
        if (!noLoadingAnimation) {
          this.store.dispatch(UserActions.setUserServerInteractionState({ state: ServerInteractionStateType.LOADING }));
        }

        return this.internalBackendApi.getUserModel().pipe(
          retry(1),
          switchMap((user) => [
            UserActions.validateAndApplyUser({ user }),
            UserActions.setUserServerInteractionState({ state: ServerInteractionStateType.SUCCESS }),
          ]),
          catchError((error) => {
            if (isLocalhost()) {
              console.log(
                `%cUser model data could not be loaded and it's necessary to perform login.`,
                'background: red; color: white',
                error,
              );
            }

            return [UserActions.setUserServerInteractionState({ state: ServerInteractionStateType.ERROR, error })];
          }),
        );
      }),
    ),
  );

  renewRefreshToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.renewRefreshToken),
      concatLatestFrom(() => [this.store.select(UserSelectors.selectExpiresAt)]),
      exhaustMap(([action, expiresAt]) => {
        this.store.dispatch(
          UserActions.setRenewRefreshTokenServerInteractionState({ state: ServerInteractionStateType.LOADING }),
        );

        return this.internalBackendApi.renewRefreshToken(expiresAt).pipe(
          switchMap((user) => [
            UserActions.setRenewRefreshTokenServerInteractionState({ state: ServerInteractionStateType.SUCCESS }),
            UserActions.validateAndApplyUser({ user }),
          ]),
          catchError((error) =>
            of(
              UserActions.setRenewRefreshTokenServerInteractionState({
                state: ServerInteractionStateType.ERROR,
                error,
              }),
            ),
          ),
        );
      }),
    );
  });

  validateAndApplyUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.validateAndApplyUser),
      exhaustMap(({ user }) => {
        const actions: TypedAction<any>[] = [];
        const userUpdated = user
          ? {
              ...user,
              isAlineaUser: this.azureAppConfigurationService
                .getSetting('institutionNumber')
                .split(',')
                .includes(user?.institutionNumber),
            }
          : null;

        if (userUpdated?.accessToken) {
          actions.push(UserActions.loadUserSuccess({ user: userUpdated }));
        } else {
          actions.push(UserActions.removeUser());
        }

        if (userUpdated?.hasAccess && !userUpdated.accessToken) {
          actions.push(
            UserActions.setUserServerInteractionState({
              state: ServerInteractionStateType.ERROR,
              error: {
                httpStatus: 200,
                message: 'core.user-errors.access-token-not-provided',
                messageParameters: {},
              },
            }),
          );
        }

        return actions;
      }),
    ),
  );

  private refreshInterval: ReturnType<typeof setTimeout> | undefined = undefined;

  startRefreshTimer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loadUserSuccess),
        map((data) => {
          if (data.user) {
            let endTimestamp: number = data.user.expiresAt;
            let timeNowMs: number = Date.now();

            if (this.refreshInterval) {
              clearTimeout(this.refreshInterval);
              this.refreshInterval = undefined;
            }

            if (Math.floor(endTimestamp * 1000) - timeNowMs < 300000) {
              this.store.dispatch(UserActions.renewRefreshToken());
            } else if (Math.floor(endTimestamp * 1000) - timeNowMs > 300000) {
              this.refreshInterval = setTimeout(
                () => {
                  this.store.dispatch(UserActions.renewRefreshToken());
                },
                Math.floor(endTimestamp * 1000) - timeNowMs - 300000,
              );
            }
          }
        }),
      ),
    { dispatch: false },
  );
}
