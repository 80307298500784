import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SafePipe } from '@shared-pipes/safe.pipe';
import { removeTags } from '@utils/string';

// Copied from `alinea.products.fagportaler.ui` and modified
// TODO Move to shared frontend library

@Injectable({
  providedIn: 'root',
})
export class HtmlService {
  constructor(private domSanitizer: DomSanitizer) {}

  safeHtml(value: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }

  public getTextContentLength(html: string): number {
    return html.replace(/<\/?\w[^>]*>/gi, '').length;
  }

  fixHtml(text: string | undefined): SafeHtml | undefined {
    if (!text) {
      return;
    }

    return new SafePipe(this.domSanitizer).transform(text.replace(new RegExp('\n', 'g'), '<br />'), 'html');
  }

  truncateHTML(text: string, length: number): string {
    if (!text) {
      return '';
    }

    const cleanString = removeTags(text);

    // Return the entire input if it doesn't need truncating
    if (cleanString.length <= length) {
      return text;
    }

    let truncated = text.substring(0, length);

    // Remove line breaks and surrounding whitespace
    truncated = truncated.replace(/(\r\n|\n|\r)/gm, '').trim();
    // If the text ends with an incomplete start tag, trim it off
    truncated = truncated.replace(/<(\w*)(?:(?:\s\w+(?:={0,1}(["']{0,1})\w*\2{0,1})))*$/g, '');
    // If the text ends with a truncated end tag, fix it.
    const truncatedEndTagExpr = /<\/((?:\w*))$/g;
    const truncatedEndTagMatch = truncatedEndTagExpr.exec(truncated);

    if (truncatedEndTagMatch != null) {
      const truncatedEndTag = truncatedEndTagMatch[1];

      // Check to see if there's an identifiable tag in the end tag
      if (truncatedEndTag.length > 0) {
        // If so, find the start tag, and close it
        const startTagExpr = new RegExp('<(' + truncatedEndTag + '\\w?)(?:(?:\\s\\w+(?:=(["\'])\\w*\\2)))*>');
        let testString = truncated;
        let startTagMatch = startTagExpr.exec(testString);

        let startTag = null;

        while (startTagMatch != null) {
          startTag = startTagMatch[1];
          testString = testString.replace(startTagExpr, '');
          startTagMatch = startTagExpr.exec(testString);
        }

        if (startTag != null) {
          truncated = truncated.replace(truncatedEndTagExpr, '</' + startTag + '>');
        }
      } else {
        // Otherwise, cull off the broken end tag
        truncated = truncated.replace(truncatedEndTagExpr, '');
      }
    }

    // Now the tricky part. Reverse the text, and look for opening tags. For each opening tag,
    //  check to see that the closing tag before it is for that tag. If not, append a closing tag.
    let testString = this.reverseHtml(truncated);
    const reverseTagOpenExpr = /<(?:(["'])\w*\1=\w+ )*(\w*)>/;
    let tagMatch = reverseTagOpenExpr.exec(testString);

    while (tagMatch != null) {
      const tag = tagMatch[0],
        tagName = tagMatch[2],
        startPos = tagMatch.index,
        endPos = startPos + tag.length,
        fragment = testString.substring(0, endPos);

      // Test to see if an end tag is found in the fragment. If not, append one to the end
      //  of the truncated HTML, thus closing the last unclosed tag
      if (!new RegExp('<' + tagName + '/>').test(fragment)) {
        truncated += '...</' + this.reverseHtml(tagName) + '>';
      }

      // Get rid of the already tested fragment
      testString = testString.replace(fragment, '');
      // Get another tag to test
      tagMatch = reverseTagOpenExpr.exec(testString);
    }

    return truncated;
  }

  /**
   * Returns the HTML for the first element (and its children) matching the given selector in the given HTML string.
   */
  extractChild(html: string, childSelector: string): string {
    return new DOMParser().parseFromString(html, 'text/html').querySelector(childSelector)?.outerHTML || '';
  }

  private reverseHtml(str: string): string {
    const ph = String.fromCharCode(206);
    let result = str.split('').reverse().join('');

    while (result.indexOf('<') > -1) {
      result = result.replace('<', ph);
    }

    while (result.indexOf('>') > -1) {
      result = result.replace('>', '<');
    }

    while (result.indexOf(ph) > -1) {
      result = result.replace(ph, '>');
    }

    return result;
  }
}
