<svg
  *ngIf="displayProgress"
  class="progress-indicator"
  role="progressbar"
  [attr.viewBox]="svgViewBox"
  [attr.aria-valuenow]="progress.stepsCompleted"
  [attr.aria-valuemin]="0"
  [attr.aria-valuemax]="progress.steps"
>
  <circle
    [attr.cx]="svgSize / 2"
    [attr.cy]="svgSize / 2"
    [attr.r]="(svgSize - progressStrokeWidth) / 2"
    [attr.stroke-width]="progressStrokeWidth"
    [attr.stroke-dasharray]="strokeDashArray"
  ></circle>
</svg>
