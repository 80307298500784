import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as ZIndexActions from '../store/z-index.actions';
import * as ZIndexSelectors from '../store/z-index.selectors';
import { ZIndexState } from '../store/z-index.state';

/**
 * Service used to give elements a new higher z-index to ensure an element is on top
 */
@Injectable({
  providedIn: 'root',
})
export class ZIndexService {
  /**
   * Get z-index info for item with specific ID
   * @param id item to get z-index info for
   * @returns z-index item or undefined if not found
   */
  item$ = (id: string): Observable<number | undefined> => this.store.select(ZIndexSelectors.getItemById(id));

  /** the z-index currently being applied */
  public currentZIndex$ = this.store.select(ZIndexSelectors.selectCurrentZIndex);

  /** @ignore */
  constructor(private store: Store<ZIndexState>) {}

  /**
   * Set z-index item in store
   * @param id name of item that is getting the new z-index
   */
  setItem(id: string): void {
    this.store.dispatch(ZIndexActions.setItem({ id: id }));
  }

  /**
   * Unset z-index item in store
   * @param id name of item that is being removed from the z-index stack
   */
  unsetItem(id: string): void {
    this.store.dispatch(ZIndexActions.unsetItem({ id: id }));
  }
}
