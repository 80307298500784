import { CartState } from '@app/cart/interfaces/cart-state.interface';
import { getInternalCartItemRequestId } from '@app/cart/store/cart.utils';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { initialServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { createReducer, on } from '@ngrx/store';

import * as CartActions from './cart.actions';

export const cartFeatureKey = 'cartState';

export const initialCartState: CartState = {
  initialized: false,
  items: [],
  cartServerInteractionState: initialServerInteractionState,
  emptyCartServerInteractionState: initialServerInteractionState,
  addToCartServerInteractionStates: {},
  removeFromCartServerInteractionStates: {},
};

export const cartReducer = createReducer(
  initialCartState,
  on(CartActions.setCartItems, (state, action) => {
    const items = [...action.items].sort((a, b) => {
      return a.order - b.order;
    });
    return {
      ...state,
      items: items,
    };
  }),
  on(CartActions.setCartServerInteractionState, (state, action) => ({
    ...state,
    initialized: action.state === ServerInteractionStateType.SUCCESS ? true : state.initialized,
    cartServerInteractionState: { state: action.state, error: action.error || null },
  })),
  on(CartActions.setEmptyCartServerInteractionState, (state, action) => ({
    ...state,
    emptyCartServerInteractionState: { state: action.state, error: action.error || null },
  })),
  on(CartActions.setAddToCartServerInteractionState, (state, action) => ({
    ...state,
    addToCartServerInteractionStates: {
      ...state.addToCartServerInteractionStates,
      [getInternalCartItemRequestId(action.item)]: { state: action.state, error: action.error || null },
    },
  })),
  on(CartActions.setRemoveFromCartServerInteractionState, (state, action) => ({
    ...state,
    removeFromCartServerInteractionStates: {
      ...state.removeFromCartServerInteractionStates,
      [getInternalCartItemRequestId(action.item)]: { state: action.state, error: action.error || null },
    },
  })),
);
