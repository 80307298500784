import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AzureAppConfigurationService } from '@app/core/services/azure-app-configuration.service';
import { UserService } from '@app/user/services/user.service';

import { PageService } from '../services/page.service';

@Injectable({
  providedIn: 'root',
})
export class SetPageForRoute {
  constructor(
    private pageService: PageService,
    private router: Router,
    private user: UserService,
    private azureAppConfigurationService: AzureAppConfigurationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url.trim() === '/' && this.user.isLoggedIn) {
      if (this.user.hasAccess) {
        const redirectUrl = this.user.isTeacher
          ? this.pageService.getUrlForPageType('mf20-dashboard-teacher')!
          : this.azureAppConfigurationService.getSetting('frontpageLoggedInRedirect');
        this.router.navigateByUrl(redirectUrl);
      } else {
        // Display the "no license" error page
        this.pageService.showError(state.url, 33);
      }

      return true;
    }

    const url = state.url.split('/');
    const useRouteConfigPath = route?.data?.customRoute && route.routeConfig?.path ? route.routeConfig?.path : null;
    const id = useRouteConfigPath || url.at(-1)!.split('?')[0];

    this.pageService.setPage(state.url, id);

    return true;
  }
}
