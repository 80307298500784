import { Injectable } from '@angular/core';
import { CartItem } from '@app/cart/interfaces/cart-item.interface';
import { CartItemRequest } from '@app/cart/interfaces/cart-item-request.interface';
import { CartItemServerInteractionStates, CartState } from '@app/cart/interfaces/cart-state.interface';
import * as CartActions from '@app/cart/store/cart.actions';
import * as CartSelectors from '@app/cart/store/cart.selectors';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { select, Store } from '@ngrx/store';
import { combineServerInteractionStateObservables } from '@utils/observables';
import { Observable, share, shareReplay } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  public items$: Observable<CartItem[]> = this.cartStore.pipe(select(CartSelectors.selectItems));
  public itemCount$: Observable<number | undefined> = this.cartStore.pipe(select(CartSelectors.selectItemCount));
  public cartServerInteractionState$: Observable<ServerInteractionState> = this.cartStore.pipe(
    select(CartSelectors.selectCartServerInteractionState),
  );
  public emptyCartServerInteractionState$: Observable<ServerInteractionState> = this.cartStore.pipe(
    select(CartSelectors.selectEmptyCartServerInteractionState),
  );
  public removeItemServerInteractionStates$: Observable<CartItemServerInteractionStates> = this.cartStore.pipe(
    select(CartSelectors.selectRemoveFromCartServerInteractionStates),
  );

  constructor(private cartStore: Store<CartState>) {}

  public fetchCart(): void {
    this.cartStore.dispatch(CartActions.fetchCart());
  }

  public fetchCartIfNecessary(): void {
    this.cartStore.dispatch(CartActions.fetchCartIfNecessary());
  }

  public emptyCart(): void {
    this.cartStore.dispatch(CartActions.emptyCart());
  }

  public addItem(item: CartItemRequest): Observable<ServerInteractionState> {
    this.cartStore.dispatch(CartActions.addItem({ item }));
    return this.getAddToCartServerInteractionState(item);
  }

  public removeItem(item: CartItemRequest): Observable<ServerInteractionState> {
    this.cartStore.dispatch(CartActions.removeItem({ item }));
    return this.getRemoveItemServerInteractionState(item);
  }

  public getAddToCartServerInteractionState(item: CartItemRequest): Observable<ServerInteractionState> {
    return this.cartStore.select(CartSelectors.selectAddToCartServerInteractionState(item));
  }

  public getRemoveItemServerInteractionState(item: CartItemRequest): Observable<ServerInteractionState> {
    return this.cartStore.select(CartSelectors.selectRemoveFromCartServerInteractionState(item));
  }

  /**
   * Combines the add and remove server interaction state for a single item
   */
  public getCombinedItemServerInteractionState(item: CartItemRequest): Observable<ServerInteractionState> {
    return combineServerInteractionStateObservables(
      [this.getAddToCartServerInteractionState(item), this.getRemoveItemServerInteractionState(item)],
      true,
    );
  }
}
