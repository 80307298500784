import { getObjectProperty, hasOwnProperty } from '@utils/object';

export interface ArrayExtremes<T> {
  first: T | undefined;
  last: T | undefined;
}
/**
 * Returns the array sorted alphabetically.
 */
export const sortArrayByString = <K extends string, T extends Record<K, string>>(
  items: T[],
  dateFieldName: K,
  language: string,
) =>
  items.sort((a: T, b: T) => {
    if (a[dateFieldName] === null || b[dateFieldName] === null) {
      return 0;
    }
    return a[dateFieldName].localeCompare(b[dateFieldName], language);
  });

/**
 * Returns true if the given arrays contains exactly the same items at the same indexes.
 */
export function arrayEqualsArray(array1: any[], array2: any[]): boolean {
  let equals = false;

  if (array1.length === array2.length) {
    for (let i = 0; i < array1.length; i++) {
      equals = array2.indexOf(array1[i]) === i;

      if (!equals) {
        break;
      }
    }
  }

  return equals;
}

export function getFirstArrayItem<T>(array: T[] | undefined | null): T | undefined {
  return array ? array.at(0) : undefined;
}

export function getLastArrayItem<T>(array: T[] | undefined | null): T | undefined {
  return array ? array.at(-1) : undefined;
}

/**
 * Returns the first and last item of the given array.
 */
export function getArrayExtremes<T>(array: T[]): ArrayExtremes<T> {
  return { first: getFirstArrayItem(array), last: getLastArrayItem(array) };
}

/**
 * Creates an array with the given length filled with the given value.
 */
export function createFillerArray<Type>(length: number, fillValue: Type): Type[] {
  const array = [];

  while (length--) {
    array.push(fillValue);
  }

  return array;
}

/**
 * Removes the given item from the given array.
 * Returns `true` if something was removed.
 */
export function removeFromArray(array: any[], item: any): boolean {
  const index = array.indexOf(item);

  if (index !== -1) {
    array.splice(index, 1);
    return true;
  }

  return false;
}

/**
 * Removes any items from the given array matching the given filter.
 * Returns `true` if something was removed.
 */
export function removeFromArrayByFilter<T>(array: any[], filter: (item: T) => boolean): boolean {
  const index = array.findIndex(filter);

  if (index !== -1) {
    array.splice(index, 1);
    // Remove the next matching element, if any, before returning true
    return removeFromArrayByFilter(array, filter) || true;
  }

  return false;
}

/**
 * Converts an array of items to a dictionary based on the given index key (or `id` if omitted).
 */
export function arrayToDictionary<Type>(array: Type[], indexKey = 'id'): { [key: string]: Type } {
  const dictionary: { [key: string]: Type } = {};

  array.forEach((item) => {
    const itemAsObject = item as unknown as object;

    if (hasOwnProperty(itemAsObject, indexKey)) {
      dictionary[getObjectProperty<string>(itemAsObject, indexKey)!] = item;
    }
  });

  return dictionary;
}

/**
 * Returns an array of items from the `mainArray` that are not present in the `compareArray`.
 */
export function getUniqueArrayItems<T>(
  mainArray: T[],
  compareArray: T[],
  compareFunction = (mainItem: T, compareItem: T) => mainItem === compareItem,
): T[] {
  return mainArray.filter((mainItem) => !compareArray.some((compareItem) => compareFunction(mainItem, compareItem)));
}

/**
 * Returns the last item from the given array or undefined if the array is empty.
 */
export function getLastItemFromArray<Type>(array: Type[]): Type | undefined {
  return array[array.length - 1];
}
