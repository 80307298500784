<span
  class="snowflake"
  *ngFor="let snowflake of snowflakes"
  [style.--snowflake-size]="snowflake.size + 'px'"
  [style.left]="snowflake.leftOffset + '%'"
  [style.--animation-duration-vertical]="snowflake.animationDurationVertical + 's'"
  [style.--animation-duration-horizontal]="snowflake.animationDurationHorizontal + 's'"
  [style.--animation-delay]="snowflake.animationDelay + 's'"
  >❄️</span
>
