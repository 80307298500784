import { Injectable } from '@angular/core';
import { ZIndexState } from '@app/z-index/store/z-index.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, withLatestFrom } from 'rxjs/operators';

import * as ZIndexSelectors from '../store/z-index.selectors';
import * as ZIndexActions from './z-index.actions';

@Injectable()
export class ZIndexEffects {
  constructor(
    private actions$: Actions,
    private store: Store<ZIndexState>,
  ) {}

  updateCurrentZIndex$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ZIndexActions.setItem, ZIndexActions.unsetItem),
      withLatestFrom(
        this.store.select(ZIndexSelectors.selectAllItems),
        this.store.select(ZIndexSelectors.selectInitialZIndex),
      ),
      exhaustMap(([action, items, initialZIndex]) => [
        // Find the highest z-index in the items array or fall back to the initial z-index
        ZIndexActions.setCurrentZIndex({ zIndex: Math.max(...items.map((item) => item.zIndex), initialZIndex) }),
      ]),
    ),
  );
}
