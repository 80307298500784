import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { UserService } from '@app/user/services/user.service';
import { AzureAppConfigurationService } from '@core/services/azure-app-configuration.service';
import { ButtonComponent, ButtonVariant } from '@shared-components/ui/button/button.component';
import { Icons } from '@shared-data/icons';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';

@Component({
  selector: 'app-login-modal-content',
  standalone: true,
  imports: [CommonModule, TranslateSharedModule, ButtonComponent],
  templateUrl: './login-modal-content.component.html',
  styleUrls: ['./login-modal-content.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginModalContentComponent {
  protected readonly requestTrialLink: string;

  protected readonly ButtonVariant = ButtonVariant;
  protected readonly Icons = Icons;

  constructor(
    protected userService: UserService,
    private azureAppConfigurationService: AzureAppConfigurationService,
  ) {
    this.requestTrialLink = this.azureAppConfigurationService.getSetting('requestTrialLink');
  }
}
