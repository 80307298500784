import { SearchByIdsState } from '@app/search-by-ids/interfaces/search-by-ids-state.interface';
import { createKeyForSearchByIds } from '@app/search-by-ids/utils/store.utils';
import { createServerInteractionState } from '@core/utils/server-interaction-state.utils';
import { createReducer, on } from '@ngrx/store';

import * as SearchByIdsActions from './search-by-ids.actions';

export const searchByIdsFeatureKey = 'searchByIdsState';

export const initialSearchByIdsState: SearchByIdsState = {
  results: {},
  serverInteractionStates: {},
};

export const searchByIdsReducer = createReducer(
  initialSearchByIdsState,
  on(
    SearchByIdsActions.setSearchByIdsServerInteractionState,
    (state, action): SearchByIdsState => ({
      ...state,
      serverInteractionStates: {
        ...state.serverInteractionStates,
        [createKeyForSearchByIds(action.ids)]: createServerInteractionState(action.state, action.error),
      },
    }),
  ),
  on(
    SearchByIdsActions.setSearchByIdsResult,
    (state, action): SearchByIdsState => ({
      ...state,
      results: {
        ...state.results,
        [createKeyForSearchByIds(action.ids)]: action.searchResultItems,
      },
    }),
  ),
);
