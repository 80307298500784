export enum UmbSectionTypeEnum {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  RichTextEditor = 'rte',
  Image = 'media-image',
  Quote = 'quote',
  Embed = 'embed',
  LruEmbedVideo = 'lru-embed-video',
  LruHeroBackground = 'lru-hero-background',
  LruHeroCard = 'lru-hero-card',
  LruMfHero = 'lru-mf-hero',
  LruMfHeroBoxed = 'lru-mf-hero-boxed',
  LruMfHeroImageCard = 'lru-mf-hero-image-card',
  LruMfSignup = 'lru-mf-signup',
  LruMfUsp = 'lru-mf-usp',
  LruMfUspColumn = 'lru-mf-usp-column',
  CallToActionButton = 'cta-button',
  CallToActionButtons = 'cta-buttons',
  CallToAction = 'call-to-action',
  CallToActionStandard = 'cta-standard',
  CallToActionFeaturedTheme = 'cta-featured-theme',
  CallToActionFeaturedCourse = 'cta-featured-course',
  ContentList = 'content-list',
  NotImplemented = 'not-implemented',
  DataMissing = "api-data-missing (can't implement)",
}

export enum UmbSectionCategoryEnum {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  RichTextEditor = 'rte',
  Image = 'media-image',
  Quote = 'quote',
  Embed = 'embed',
  LruEmbedVideo = 'lru-embed-video',
  LruMfHero = 'lru-mf-hero',
  // LruMfHeroBoxed = 'lru-mf-hero-boxed',
  // LruMfHeroImageCard = 'lru-mf-hero-image-card',
  LruMfSignup = 'lru-mf-signup',
  LruMfUsp = 'lru-mf-usp',
  LruMfUspColumn = 'lru-mf-usp-column',
  CTA = 'cta',
  CallToAction = 'call-to-action',
  CallToActionButton = 'cta-button',
  CallToActionButtons = 'cta-buttons',
  ContentList = 'content-collection',
}
