import { EducationGroup } from '@app/grade-levels/interfaces/education-group.interface';
import { GradeLevel } from '@app/grade-levels/interfaces/grade-level.interface';
import { GradeLevelGroup } from '@app/grade-levels/interfaces/grade-level-group.interface';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { ParsedServerError } from '@core/interfaces/project/parsed-server-error.interface';
import { createAction, props } from '@ngrx/store';

export const loadGradeLevels = createAction('[Levels/API] Load grade-levels');

export const setLoadGradeLevelsServerInteractionState = createAction(
  '[Levels/API] Set load grade-levels server interaction state',
  props<{ state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const setGradeLevels = createAction('[Levels/API] Set grade-levels', props<{ gradeLevels: GradeLevel[] }>());

export const setGradeLevelGroups = createAction(
  '[Levels/API] Set grade-level groups',
  props<{ gradeLevelGroups: GradeLevelGroup[] }>(),
);

export const setEducationGroups = createAction(
  '[Levels/API] Set education groups',
  props<{ educationGroups: EducationGroup[] }>(),
);
