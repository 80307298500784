import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { VisuallyHiddenComponent } from '@shared-components/accessibility/visually-hidden/visually-hidden.component';
import { IconSize } from '@shared-enums/icon-size.enum';
import { Icon } from '@shared-interfaces/icon.interface';
import { RouterLinkCommands } from '@shared-types/router-link-command.type';

import { IconComponent } from '../icon/icon.component';
import { LoadingAnimationComponent } from '../loading-animation/loading-animation.component';

const smallBadge = {
  top: 'calc(1 * -3px)',
  right: 'calc(1 * -5px)',
  minWidth: '6px',
  height: '6px',
  borderRadius: '50%',
  opacity: 1,
  color: 'transparent',
};

const largeBadge = {
  position: 'absolute',
  display: 'flex',
  top: 'calc(1 * -3px)',
  right: 'calc(1 * -5px)',
  minWidth: '180px',
  height: '180px',
  backgroundColor: 'var(--color-progress-gradient-start)',
  borderRadius: '50%',
  opacity: 0.3,
  overflow: 'hidden',
  color: 'transparent',
};

const defaultBadge = {
  position: 'absolute',
  display: 'flex',
  top: 'calc(1 * -3px)',
  right: 'calc(1 * -5px)',
  minWidth: '20px',
  height: '20px',
  lineHeight: 'var(--line-height-20px)',
  backgroundColor: 'var(--color-progress-gradient-start)',
  borderRadius: '50%',
  opacity: 1,
  color: 'var(--color-white-100)',
};

const hiddenStyle = {
  opacity: 0,
};

const badgeStyleBlink = { ...defaultBadge, backgroundColor: 'var(--color-white-100)' };
/**
 * A button with an icon.
 * Utilizes either a `<button>` or an `<a>` under the hood depending on the input, to best match the intended use.
 * Supports multiple variants and external CSS variable overrides.
 */
@Component({
  standalone: true,
  imports: [CommonModule, RouterLink, IconComponent, LoadingAnimationComponent, VisuallyHiddenComponent],
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  animations: [
    trigger('circleAnimation', [
      // When badgeCount is 0, hide badge
      state('0', style(hiddenStyle)),
      // When badgeCount is anything else, show badge
      state('*', style(defaultBadge)),
      // When badgeCount goes from void to 0, hide it
      transition('void => 0', [style(hiddenStyle)]),
      // When badgeCount goes from void to anything except 0, show the badge
      transition('void => *', [animate('.15s ease-in-out', style(defaultBadge))]),
      // When badgeCount goes from 0 to anything run scaling animation
      transition('0 => *', [
        style(largeBadge),
        animate('.3s ease-in-out', style(smallBadge)),
        animate('.15s ease-in-out', style({ ...defaultBadge, color: 'transparent' })),
        animate('.15s ease-in-out', style(defaultBadge)),
      ]),
      // When badgeCount goes from anything to zero, run badge fade out animation
      transition('* => 0', [animate('.3s ease-in-out', style(hiddenStyle))]),
      // When badgeCount is incrementet by 1 run blink animation
      transition(':increment, :decrement', [
        animate('.15s ease-in-out', style(badgeStyleBlink)),
        animate('.15s ease-in-out', style(defaultBadge)),
      ]),
    ]),
  ],
})
export class IconButtonComponent<T> {
  @Input() icon?: Icon;
  @Input() iconSize: IconSize = IconSize.MEDIUM;
  @Input() loading: boolean = false;
  @Input() title?: string;
  @Input() routerLink?: RouterLinkCommands;
  @Input() type: string = 'button';
  @Input() href?: string;
  @Input() badgeCount?: number;
  @Input() disabled?: boolean = false;
  @Input() target?: string;
  @Input() active?: boolean = false;
  @Input() circleFilled: boolean = false;
  @Input() circleOutline: boolean = false;
  @Input() data?: T;

  @Output() buttonFocus = new EventEmitter<Event>();
  @Output() buttonFocusOut = new EventEmitter<Event>();
  @Output() buttonClick = new EventEmitter<T>();

  @ViewChild('buttonElement') buttonElement?: ElementRef<HTMLButtonElement | HTMLAnchorElement>;

  get titleAttribute(): string | null {
    return this.title?.trim() || null;
  }

  get isLink(): boolean {
    return !!this.href;
  }

  get isRouterLink(): boolean {
    return !!this.routerLink;
  }

  get isButton(): boolean {
    return !(this.isLink || this.isRouterLink);
  }

  public focus() {
    this.buttonElement?.nativeElement.focus();
  }

  protected onFocus(event: Event): void {
    this.buttonFocus.emit(event);
  }

  protected onFocusOut(event: Event): void {
    this.buttonFocusOut.emit(event);
  }

  protected stopPropogationClickEvent(event: MouseEvent) {
    if (this.data) {
      event.stopPropagation();
    }
    this.buttonClick.emit(this.data);
  }
}
