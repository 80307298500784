<ng-template #buttonContent>
  <app-loading-animation *ngIf="loading" class="icon-button-loading-animation"></app-loading-animation>
  <div class="counter-badge" [@circleAnimation]="badgeCount" *ngIf="badgeCount !== undefined">
    <span>{{ badgeCount | number }}</span>
  </div>
  <app-icon
    class="icon-button-icon"
    [icon]="icon"
    [size]="iconSize"
    [circleFilled]="circleFilled"
    [circleOutline]="circleOutline"
  ></app-icon>
  <app-visually-hidden *ngIf="titleAttribute">{{ titleAttribute }}</app-visually-hidden>
</ng-template>

<button
  *ngIf="isButton"
  #buttonElement
  [disabled]="disabled"
  class="icon-button"
  [class.is-loading]="loading"
  [type]="type"
  [attr.title]="titleAttribute"
  (focus)="onFocus($event)"
  (focusout)="onFocusOut($event)"
  (click)="data ? stopPropogationClickEvent($event) : undefined"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<a
  *ngIf="isLink"
  #buttonElement
  [href]="href"
  class="icon-button"
  [class.disabled]="disabled"
  [class.is-loading]="loading"
  [attr.title]="titleAttribute"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</a>

<a
  *ngIf="isRouterLink"
  #buttonElement
  [routerLink]="routerLink"
  class="icon-button"
  [class.disabled]="disabled"
  [class.is-loading]="loading"
  [attr.title]="titleAttribute"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</a>
