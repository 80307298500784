import { Lesson } from '@app/content/interfaces/lesson.interface';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { ParsedServerError } from '@core/interfaces/project/parsed-server-error.interface';
import { createAction, props } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';

import { Printable } from '../interfaces/printable.interface';

// LESSON ACTIONS
export const loadLesson = createAction('[Lessons/API] Load lesson', props<{ lessonId: GUID }>());

export const loadLessonIfNecessary = createAction(
  '[Lessons/API] Load lesson if necessary',
  props<{ lessonId: GUID }>(),
);

export const setLoadLessonServerInteractionState = createAction(
  '[Lessons/API] Set load lesson server interaction state',
  props<{ lessonId: GUID; state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const setLesson = createAction('[Lessons/API] Set lesson', props<{ lesson: Lesson }>());

// ACTIVITY & WORKSHEET ACTIONS
export const getActivity = createAction('[AssignmentsManaged/API] Get an activity', props<{ activityId: GUID }>());

export const getWorksheet = createAction('[AssignmentsManaged/API] Get a worksheet', props<{ worksheetId: GUID }>());

export const setPrintable = createAction('[AssignmentsManaged/API] Save a printable', props<{ data: Printable }>());

export const setLoadPrintableServerInteractionState = createAction(
  '[AssignmentsManaged/API] Set printable server interaction state',
  props<{ printableId: GUID; state: ServerInteractionStateType; error?: ParsedServerError }>(),
);
