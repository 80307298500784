import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PageService } from '@app/page/services/page.service';
import { UserService } from '@app/user/services/user.service';
import { concatLatestFrom } from '@ngrx/effects';
import { removeQueryParams } from '@utils/network';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  public readonly nameHardcoded = 'LoginGuard';
  constructor(
    private router: Router,
    private userService: UserService,
    private location: Location,
    private pageService: PageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.hasLoadedUserData$.pipe(
      filter((hasLoadedUserData) => hasLoadedUserData),
      concatLatestFrom(() => [this.userService.isLoggedIn$, this.userService.hasAccess$]),
      map(([hasLoadedUserData, isLoggedIn, hasAccess]) => {
        if (!isLoggedIn) {
          if (route.queryParams.referred) {
            const newPath = removeQueryParams(this.location.path());
            this.location.replaceState(newPath);

            this.router.navigate(['/'], {
              skipLocationChange: true,
            });

            this.userService.userLogin();
          } else {
            this.pageService.showError(state.url, 204);
          }
        } else if (!hasAccess) {
          // Display the "no license" error page
          this.pageService.showError(state.url, 33);

          // The path is set to `contentpage` when follow links to pages that are blocked by this guard.
          // This timeout resets the path to its intended value.
          setTimeout(() => {
            this.location.replaceState(state.url);
          });
        }

        return isLoggedIn && hasAccess;
      }),
    );
  }
}
