<app-icon-button
  class="dismiss-button"
  [title]="'cart.components.cart.dismiss-button' | translate"
  [icon]="Icons.CLOSE"
  (click)="onDismiss()"
></app-icon-button>
<app-cart-presentational
  [items]="(items$ | async)!"
  [cartServerInteractionState]="(cartServerInteractionState$ | async)!"
  [emptyCartServerInteractionState]="(emptyCartServerInteractionState$ | async)!"
  [removeItemServerInteractionStates]="(removeItemServerInteractionStates$ | async)!"
  (dismiss)="onDismiss()"
  (emptyCart)="onEmptyCart()"
  (removeItem)="onRemoveItem($event)"
></app-cart-presentational>
