import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CartModule } from '@app/cart/cart.module';
import { CartItem } from '@app/cart/interfaces/cart-item.interface';
import { CartItemServerInteractionStates } from '@app/cart/interfaces/cart-state.interface';
import { mapCartItemToCartItemRequest } from '@app/cart/mappers/cart-item-to-cart-item-request.mapper';
import { CartService } from '@app/cart/services/cart.service';
import { UserService } from '@app/user/services/user.service';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { IconButtonComponent } from '@shared-components/ui/icon-button/icon-button.component';
import { Icons } from '@shared-data/icons';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';
import { Observable } from 'rxjs';

import { CartPresentationalComponent } from '../cart-presentational/cart-presentational.component';

@Component({
  standalone: true,
  imports: [IconButtonComponent, TranslateSharedModule, CartPresentationalComponent, AsyncPipe, CartModule],
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartComponent implements OnInit {
  @HostBinding('attr.tabindex') public tabIndex = -1;
  @HostBinding('attr.role') public role = 'article';

  @Output() dismiss = new EventEmitter<void>();

  @HostListener('document:visibilitychange', ['!$event.target.hidden'])
  onVisibilityChange(isVisible: boolean): void {
    // Reload cart data every time this browser tab re-gains focus, to make sure we're not displaying stale
    // data when content is changed in another tab.
    if (isVisible && this.userService.isTeacher) {
      this.cartService.fetchCart();
    }
  }

  public Icons = Icons;
  public ServerInteractionStateType = ServerInteractionStateType;

  public items$: Observable<CartItem[]> = this.cartService.items$;
  public cartServerInteractionState$: Observable<ServerInteractionState> = this.cartService.cartServerInteractionState$;
  public emptyCartServerInteractionState$: Observable<ServerInteractionState> =
    this.cartService.emptyCartServerInteractionState$;
  public removeItemServerInteractionStates$: Observable<CartItemServerInteractionStates> =
    this.cartService.removeItemServerInteractionStates$;

  constructor(
    private elementRef: ElementRef,
    private cartService: CartService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    if (this.userService.isTeacher) {
      this.cartService.fetchCart();
    }
  }

  public focus(): void {
    this.elementRef.nativeElement.focus();
  }

  public onDismiss(): void {
    this.dismiss.emit();
  }

  public onEmptyCart(): void {
    this.cartService.emptyCart();
  }

  public onRemoveItem(cartItem: CartItem): void {
    this.cartService.removeItem(mapCartItemToCartItemRequest(cartItem));
  }
}
