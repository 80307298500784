import { animate, style, transition, trigger } from '@angular/animations';
import { softBezier } from '@shared-animations/beziers.animation';

const transitionTiming = `.3s ${softBezier}`;
const loadingHiddenStyle = style({ transform: 'scale(.7)', opacity: 0 });
const loadingVisibleStyle = style({ transform: 'scale(1)', opacity: 1 });

export const loadingEnterLeaveAnimation = trigger('loadingEnterLeave', [
  transition(':enter', [loadingHiddenStyle, animate(transitionTiming, loadingVisibleStyle)]),
  transition(':leave', [animate(transitionTiming, loadingHiddenStyle)]),
]);
