import { createFeatureSelector, createSelector } from '@ngrx/store';

import { azureAppEnvironment } from '../interfaces/azure-app-configuration/azure-app-environment.enum';
import { coreFeatureKey } from './core.reducers';
import { CoreState } from './interfaces/core-state.interface';

export const selectCoreState = createFeatureSelector<CoreState>(coreFeatureKey);

export const selectAzureAppConfiguration = createSelector(selectCoreState, (state) => state.azureAppConfiguration);
export const selectIsDevServer = createSelector(
  selectAzureAppConfiguration,
  (state) => state?.settings.environment === azureAppEnvironment.DEVELOPMENT,
);
export const selectLoadAzureAppConfigurationServerInteractionState = createSelector(
  selectCoreState,
  (state) => state.loadAzureAppConfigurationServerInteractionState,
);

export const selectPortalConfiguration = createSelector(
  selectCoreState,
  (state: CoreState) => state.portalConfiguration,
);

export const selectLoadSiteBootstrapServerInteractionState = createSelector(
  selectCoreState,
  (state: CoreState) => state.loadSiteBootstrapServerInteractionState,
);

export const selectInitialBootstrapCompleted = createSelector(
  selectCoreState,
  (state) => state.initialBootstrapCompleted,
);

export const selectPortalId = createSelector(selectCoreState, (state: CoreState) => state.portalConfiguration.portalId);

export const selectProductId = createSelector(selectCoreState, (state: CoreState) => state.portalConfiguration.id);

export const selectFrontpage = createSelector(
  selectCoreState,
  (state: CoreState) => state.portalConfiguration.frontpageId,
);

export const selectErrorPages = createSelector(
  selectCoreState,
  (state: CoreState) => state.portalConfiguration.errorPages,
);

export const selectGoogleTagManagerId = createSelector(
  selectCoreState,
  (state: CoreState) => state.portalConfiguration.googleTagManagerId,
);

export const selectNavbar = createSelector(selectCoreState, (state: CoreState) => state.portalConfiguration.navbar);

export const selectMegaMenu = createSelector(selectCoreState, (state: CoreState) => state.portalConfiguration.megaMenu);
export const selectLogo = createSelector(selectCoreState, (state: CoreState) => state.portalConfiguration.logo);
export const selectSiteName = createSelector(selectCoreState, (state: CoreState) => state.portalConfiguration.title);

/*
 * Operational notification
 */
export const selectOperationalNotificationDismissedTokens = createSelector(
  selectCoreState,
  (state) => state.operationalNotificationDismissedTokens,
);

/**
 * Selects the current operational notification, but only if it hasn't been dismissed
 */
export const selectOperationalNotification = createSelector(
  selectCoreState,
  selectOperationalNotificationDismissedTokens,
  (state, dismissedOperationalNotificationTokens) =>
    state.operationalNotification &&
    !dismissedOperationalNotificationTokens.includes(state.operationalNotification.notificationToken)
      ? state.operationalNotification
      : undefined,
);

export const selectOperationalNotificationServerInteractionState = createSelector(
  selectCoreState,
  (state) => state.operationalNotificationServerInteractionState,
);
