import { Injectable } from '@angular/core';
import { GradeLevelsOverview } from '@app/grade-levels/interfaces/grade-levels-overview.interface';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GradeLevelsApiService {
  constructor(private api: ApiService) {}

  public loadLevels(): Observable<GradeLevelsOverview> {
    return this.api.getBFFGateway<{ result: GradeLevelsOverview }>('v1/grade-levels').pipe(map(({ result }) => result));
  }
}
