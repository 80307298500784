import { createFeatureSelector, createSelector } from '@ngrx/store';

import { toastFeatureKey } from './toast.reducers';
import { ToastState } from './toast.state';

export const selectToastState = createFeatureSelector<ToastState>(toastFeatureKey);

export const selectAllMessages = createSelector(selectToastState, (state) => state.messages);

export const selectDefaultExpirationTime = createSelector(selectToastState, (state) => state.defaultDuration);
