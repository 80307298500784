import { Injectable } from '@angular/core';
import { Student } from '@app/teams/interfaces/student.interfaces';
import { GetSubTeamsByTeamApiResponse, SubTeam } from '@app/teams/interfaces/sub-team.interfaces';
import { ChangeStudentHiddenGradelevelPayload } from '@app/teams-admin/interfaces/change-student-hidden-grade-level-payload.interface';
import {
  ChangeStudentHiddenGradelevel,
  ChangeStudentHiddenGradelevelResponse,
} from '@app/teams-admin/interfaces/change-student-hidden-grade-level-response.interface';
import { TeamWithTeachersDto } from '@app/teams-admin/interfaces/endpoint/dtos/dto-team-with-teachers.interface';
import { ILoungeResponseTemplate } from '@app/teams-admin/interfaces/endpoint/response-template.interface';
import {
  ILoungePostBodyTeam_AddTeachersToTeam,
  ILoungePostBodyTeam_AddTeacherToTeam,
  ILoungePostBodyTeam_CreateTeam,
  ILoungePostBodyTeam_SaveStudentsToTeam,
  ILoungePutBodyTeam_UpdateTeam,
  ILoungeResponseTeam_AddTeachersToTeam,
  ILoungeResponseTeam_AddTeacherToTeam,
  ILoungeResponseTeam_CreateTeam,
  ILoungeResponseTeam_GetStudentsInTeam,
  ILoungeResponseTeam_SaveStudentsToTeam,
  ILoungeResponseTeam_UpdateTeam,
} from '@app/teams-admin/interfaces/endpoint/team.interface';
import { ApiService } from '@core/services/api.service';
import { GUID } from '@shared-types/guid.type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TeamsApiService {
  constructor(private apiService: ApiService) {}

  getTeam(teamId: string): Observable<TeamWithTeachersDto> {
    return this.apiService.getBFFGateway<{ result: TeamWithTeachersDto }>(`v1/team/${teamId}`).pipe(
      map((response) => response.result),
      map((team) => ({ ...team, created: new Date(team.created), updated: new Date(team.updated) })),
    );
  }

  deleteTeam(teamId: string): Observable<ILoungeResponseTemplate> {
    return this.apiService.deleteBFFGateway<ILoungeResponseTemplate>(`v1/team/${teamId}`);
  }

  getTeamsAvailableToUser(): Observable<TeamWithTeachersDto[]> {
    return this.apiService
      .getBFFGateway<{ result: TeamWithTeachersDto[] }>(`v1/team/list`)
      .pipe(
        map(({ result: teams }) =>
          teams.map((team) => ({ ...team, created: new Date(team.created), updated: new Date(team.updated) })),
        ),
      );
  }

  createTeam(postBody: ILoungePostBodyTeam_CreateTeam): Observable<ILoungeResponseTeam_CreateTeam> {
    return this.apiService.postBFFGateway<ILoungeResponseTeam_CreateTeam>(`v1/team`, postBody);
  }

  saveStudentsToTeam(
    teamId: string,
    postBody: ILoungePostBodyTeam_SaveStudentsToTeam,
  ): Observable<ILoungeResponseTeam_SaveStudentsToTeam> {
    return this.apiService.postBFFGateway<ILoungeResponseTeam_SaveStudentsToTeam>(
      `v1/team/${teamId}/students`,
      postBody,
    );
  }

  getStudentsInTeam(teamId: string): Observable<ILoungeResponseTeam_GetStudentsInTeam> {
    return this.apiService.getBFFGateway<ILoungeResponseTeam_GetStudentsInTeam>(`v1/teams/${teamId}/students`);
  }

  addTeacher(
    teamId: string,
    postBody: ILoungePostBodyTeam_AddTeacherToTeam,
  ): Observable<ILoungeResponseTeam_AddTeacherToTeam> {
    return this.apiService.postBFFGateway<ILoungeResponseTeam_AddTeacherToTeam>(`v1/team/${teamId}/teacher`, postBody);
  }

  removeSelf(teamId: string): Observable<ILoungeResponseTemplate> {
    return this.apiService.deleteBFFGateway<ILoungeResponseTemplate>(`v1/team/${teamId}/teacher/self`);
  }

  addTeachers(
    teamId: string,
    postBody: ILoungePostBodyTeam_AddTeachersToTeam,
  ): Observable<ILoungeResponseTeam_AddTeachersToTeam> {
    return this.apiService.postBFFGateway<ILoungeResponseTeam_AddTeachersToTeam>(
      `v1/team/${teamId}/teachers`,
      postBody,
    );
  }

  updateTeam(teamId: string, putBody: ILoungePutBodyTeam_UpdateTeam): Observable<ILoungeResponseTeam_UpdateTeam> {
    return this.apiService.putBFFGateway<ILoungeResponseTeam_UpdateTeam>(`v1/team/${teamId}`, putBody);
  }

  getSubTeamsForTeam(teamId: GUID): Observable<SubTeam[]> {
    return this.apiService.getBFFGateway<GetSubTeamsByTeamApiResponse>(`v1/subteams/${teamId}/list`).pipe(
      map((response) =>
        response.result.map((subTeamApiResponse) => ({
          ...subTeamApiResponse,
          created: new Date(subTeamApiResponse.created),
          updated: new Date(subTeamApiResponse.updated),
        })),
      ),
    );
  }

  getStudentsForTeam(teamId: GUID): Observable<Student[]> {
    return this.apiService
      .getBFFGateway<{ result: Student[] }>(`v1/teams/${teamId}/students`)
      .pipe(map(({ result }) => result));
  }

  public changeStudentHiddenGradeLevel(
    body: ChangeStudentHiddenGradelevelPayload,
  ): Observable<ChangeStudentHiddenGradelevel> {
    return this.apiService
      .postBFFGateway<ChangeStudentHiddenGradelevelResponse>(`v1/users/training-grade-level`, body)
      .pipe(map((response) => response.result));
  }
}
