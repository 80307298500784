import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@app/user/services/user.service';
import { AzureAppConfigurationService } from '@core/services/azure-app-configuration.service';
import { parseCustomErrorResponse, parseHttpErrorResponse } from '@core/utils/api.utils';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private azureAppConfigurationService: AzureAppConfigurationService,
  ) {}

  getInternalBackend<T>(url: string, httpParams?: HttpParams): Observable<T> {
    return this.get<T>(this.azureAppConfigurationService.getSetting('internalBackendUrl'), url, httpParams, true).pipe(
      catchError(this.parseHttpErrorResponse),
    );
  }

  /**
   * This method is only used by FELib.
   * IntelliJ based editors marks it as unused, because they can't see the connection.
   */
  getLounge<T>(url: string, httpParams?: HttpParams): Observable<T> {
    return this.get<T>(this.azureAppConfigurationService.getSetting('apiLoungeBaseUrl'), url, httpParams, true).pipe(
      catchError(this.parseCustomErrorResponse),
    );
  }

  getBFFGatewayForAzureConfig<T>(url: string = '', httpParams?: HttpParams): Observable<T> {
    return this.get<T>(environment.appConfigurationConnectionString, url, httpParams, true).pipe(
      catchError(this.parseCustomErrorResponse),
    );
  }

  getBFFGateway<T>(url: string, httpParams?: HttpParams): Observable<T> {
    return this.get<T>(
      this.azureAppConfigurationService.getSetting('apiBFFGatewayBaseUrl'),
      url,
      httpParams,
      true,
    ).pipe(catchError(this.parseCustomErrorResponse));
  }

  getUmbraco<T>(url: string, httpParams?: HttpParams): Observable<T> {
    return this.get<T>(this.azureAppConfigurationService.getSetting('apiUmbracoBaseUrl'), url, httpParams).pipe(
      catchError(this.parseHttpErrorResponse),
    );
  }

  /**
   * This method is only used by FELib.
   * IntelliJ based editors marks it as unused, because they can't see the connection.
   */
  postLounge<T>(url: string, body?: any, wwwForm?: boolean): Observable<T> {
    return this.post<T>(this.azureAppConfigurationService.getSetting('apiLoungeBaseUrl'), url, body, wwwForm).pipe(
      catchError(this.parseCustomErrorResponse),
    );
  }

  postBFFGateway<T>(url: string, body?: any, wwwForm?: boolean): Observable<T> {
    return this.post<T>(this.azureAppConfigurationService.getSetting('apiBFFGatewayBaseUrl'), url, body, wwwForm).pipe(
      catchError(this.parseCustomErrorResponse),
    );
  }

  postSearchContent<T>(url: string, body?: any): Observable<T> {
    return this.post<T>(this.azureAppConfigurationService.getSetting('apiSearchContentUrl'), url, body).pipe(
      catchError(this.parseHttpErrorResponse),
    );
  }

  /**
   * This method is only used by FELib.
   * IntelliJ based editors marks it as unused, because they can't see the connection.
   */
  putLounge<T>(url: string, body?: any): Observable<T> {
    return this.put<T>(this.azureAppConfigurationService.getSetting('apiLoungeBaseUrl'), url, body).pipe(
      catchError(this.parseCustomErrorResponse),
    );
  }

  putBFFGateway<T>(url: string, body?: any): Observable<T> {
    return this.put<T>(this.azureAppConfigurationService.getSetting('apiBFFGatewayBaseUrl'), url, body).pipe(
      catchError(this.parseCustomErrorResponse),
    );
  }

  /**
   * This method is only used by FELib.
   * IntelliJ based editors marks it as unused, because they can't see the connection.
   */
  deleteLounge<T>(url: string, body?: any, httpParams?: HttpParams): Observable<T> {
    return this.delete<T>(this.azureAppConfigurationService.getSetting('apiLoungeBaseUrl'), url, body, httpParams).pipe(
      catchError(this.parseCustomErrorResponse),
    );
  }

  deleteBFFGateway<T>(url: string, body?: any, httpParams?: HttpParams): Observable<T> {
    return this.delete<T>(
      this.azureAppConfigurationService.getSetting('apiBFFGatewayBaseUrl'),
      url,
      body,
      httpParams,
    ).pipe(catchError(this.parseCustomErrorResponse));
  }

  // Generic API functions, used in the above
  private get<T>(apiBaseUrl: string, url: string, httpParams?: HttpParams, withCredentials?: boolean): Observable<T> {
    return this.httpClient.get<T>(apiBaseUrl + url, {
      headers: this.defaultHeaders,
      params: httpParams,
      withCredentials: withCredentials,
    });
  }

  private post<T>(
    apiBaseUrl: string,
    url: string,
    body?: any,
    wwwForm?: boolean,
    withCredentials?: boolean,
  ): Observable<T> {
    return this.httpClient.post<T>(apiBaseUrl + url, body, {
      headers: wwwForm ? this.defaultHeaders_wwwform : this.defaultHeaders,
      withCredentials: withCredentials,
    });
  }

  private put<T>(apiBaseUrl: string, url: string, body?: any): Observable<T> {
    return this.httpClient.put<T>(apiBaseUrl + url, body, {
      headers: this.defaultHeaders,
    });
  }

  private patch<T>(apiBaseUrl: string, url: string, body?: any): Observable<T> {
    return this.httpClient.patch<T>(apiBaseUrl + url, body, {
      headers: this.defaultHeaders,
    });
  }

  private delete<T>(apiBaseUrl: string, url: string, body?: any, httpParams?: HttpParams): Observable<T> {
    const httpOptions = {
      headers: this.defaultHeaders,
      body: body,
      httpParams: httpParams,
    };

    return this.httpClient.delete<T>(apiBaseUrl + url, httpOptions);
  }

  private get defaultHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    });

    let accessToken = this.userService.getAccessToken;

    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    if (this.userService.isLoggedIn) {
      const contextIdentifier = this.userService.getContextIdentifier;
      const institutionNumber = this.userService.getInstitutionNumber;

      if (contextIdentifier) {
        headers = headers.set('ContextIdentifier', contextIdentifier);
      }

      if (institutionNumber) {
        headers = headers.set('InstitutionNumber', institutionNumber);
      }
    }

    return headers;
  }

  private get defaultHeaders_wwwform(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    });
  }

  /**
   * Parses response errors in our custom format and re-throws a consistent error object with HTTP status and error message.
   */
  private parseCustomErrorResponse(errorResponse: HttpErrorResponse): Observable<never> {
    return throwError(() => parseCustomErrorResponse(errorResponse));
  }

  /**
   * Parses general http response errors and re-throws a consistent error object with HTTP status and error message.
   */
  private parseHttpErrorResponse(errorResponse: HttpErrorResponse): Observable<never> {
    return throwError(() => parseHttpErrorResponse(errorResponse));
  }
}
