import { ToastMessageInstance } from '@app/toast/interfaces/toast-message-instance.interface';
import { createAction, props } from '@ngrx/store';

export const getAllMessages = createAction('[Toast/UI] Get all messages');

export const addMessage = createAction('[Toast/UI] Add message', props<{ message: ToastMessageInstance }>());

export const updateMessage = createAction(
  '[Toast/UI] Update message',
  props<{ message: ToastMessageInstance; ignoreTiming?: boolean }>(),
);

export const removeMessage = createAction('[Toast/UI] Remove message', props<{ id: string }>());

export const pauseMessageExpiration = createAction('[Toast/UI] Pause message expiration');

export const continueMessageExpiration = createAction('[Toast/UI] Continue message expiration');

export const initializeMessageTimeout = createAction(
  '[Toast/UI] Initialize message timeout',
  props<{ message: ToastMessageInstance }>(),
);

export const clearMessageTimeout = createAction(
  '[Toast/UI] Clear message timeout',
  props<{ message: ToastMessageInstance }>(),
);
