import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ToastService } from './services/toast.service';
import { ToastEffects } from './store/toast.effects';
import { toastFeatureKey, toastReducer } from './store/toast.reducers';

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature(toastFeatureKey, toastReducer), EffectsModule.forFeature([ToastEffects])],
  providers: [ToastService],
})
export class ToastModule {}
