import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

/**
 * Hides any content from the sighted while still letting screen readers access the content.
 * Heavily inspired by https://kittygiraudel.com/snippets/visually-hidden-component/
 */
@Component({
  standalone: true,
  imports: [],
  selector: 'app-visually-hidden',
  templateUrl: './visually-hidden.component.html',
  styleUrls: ['./visually-hidden.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisuallyHiddenComponent {}
