import { Component, HostBinding, Input } from '@angular/core';

export enum LoadingAnimationVariant {
  CUSTOM = 'custom',
  BLOCK = 'block',
  INLINE = 'inline',
  OVERLAY = 'overlay', // Overlays the entire page
  TRANSPARENT_OVERLAY = 'transparent-overlay', // Overlays the entire page
  LOCAL_OVERLAY = 'local-overlay', // Overlays the nearest positioned ancestor
  TRANSPARENT_LOCAL_OVERLAY = 'transparent-local-overlay', // Overlays the nearest positioned ancestor
}

@Component({
  standalone: true,
  selector: 'app-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss'],
})
export class LoadingAnimationComponent {
  @Input() strokeWidth: number;
  @Input() variant: LoadingAnimationVariant;

  @HostBinding('class') get className(): string {
    return `loading-animation loading-animation-${this.variant}`;
  }

  @HostBinding('style.--size') @Input() size: number | undefined;

  constructor() {
    this.variant = LoadingAnimationVariant.BLOCK;
    this.strokeWidth = 10;
  }

  get radius(): number {
    return 50 - this.strokeWidth / 2;
  }

  get circleCutout(): number {
    return (2 * this.radius * Math.PI) / 3;
  }
}
