<ng-template cdk-portal>
  <ng-container *ngIf="zIndex$ | async as zIndex">
    <div
      class="modal-overlay"
      [attr.data-variant]="variant"
      [@modal-overlay-enter-leave]
      [attr.data-variant]="variant"
      [class.overflow-visible]="overflowVisible"
      [style.zIndex]="zIndex"
    ></div>
    <div
      *ngIf="isVisible"
      class="modal-wrapper"
      [style.zIndex]="zIndex"
      (click)="onWrapperClick($event)"
      [@modal-enter-leave]
      (@modal-enter-leave.done)="animationDone($event)"
      [class.overflow-visible]="overflowVisible"
      [attr.data-variant]="variant"
    >
      <div
        (click)="onWrapperClick($event)"
        class="modal"
        role="dialog"
        aria-modal="true"
        [style.paddingLeft]="spacingFromWindow"
        [style.paddingRight]="spacingFromWindow"
      >
        <!-- Used to trap focus inside of the modal -->
        <div tabindex="0" class="focus-trap focus-trap-begin"></div>
        <div
          class="modal-content"
          #modalContent
          [style.marginTop]="spacingFromWindow"
          [style.marginBottom]="spacingFromWindow"
          [style.width]="width"
          [style.height]="height"
        >
          <ng-content></ng-content>
        </div>
        <!-- Used to trap focus inside of the modal -->
        <div tabindex="0" class="focus-trap focus-trap-end"></div>
      </div>
    </div>
  </ng-container>
</ng-template>
