import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PageService } from '@app/page/services/page.service';
import { UserService } from '@app/user/services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StudentsOnlyGuard {
  constructor(
    private userService: UserService,
    private pageService: PageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.isStudent$.pipe(
      map((isStudent) => {
        // Prevent navigation beef with the login guard by only navigating if the user has access
        if (!isStudent && this.userService.hasAccess) {
          this.pageService.showError(state.url, 403);
        }

        return isStudent;
      }),
    );
  }
}
