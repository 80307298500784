import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { ParsedServerError } from '@core/interfaces/project/parsed-server-error.interface';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';

export function shouldLoadData(serverInteractionStateType: ServerInteractionStateType): boolean {
  return [ServerInteractionStateType.INITIAL, ServerInteractionStateType.ERROR].includes(serverInteractionStateType);
}

export function isFinalServerInteractionStateType(serverInteractionStateType: ServerInteractionStateType): boolean {
  return [ServerInteractionStateType.SUCCESS, ServerInteractionStateType.ERROR].includes(serverInteractionStateType);
}

export function createServerInteractionState(
  serverInteractionStateType: ServerInteractionStateType,
  error?: ParsedServerError,
): ServerInteractionState {
  return { state: serverInteractionStateType, error: error ?? null };
}
