import { NgModule } from '@angular/core';
import { ZIndexEffects } from '@app/z-index/store/z-index.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ZIndexService } from './services/z-index.service';
import { zIndexFeatureKey, zIndexReducer } from './store/z-index.reducers';

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature(zIndexFeatureKey, zIndexReducer), EffectsModule.forFeature([ZIndexEffects])],
  providers: [ZIndexService],
})
export class ZIndexModule {}
