import { Injectable } from '@angular/core';
import { Lesson } from '@app/content/interfaces/lesson.interface';
import { sanitizeCourseItems } from '@app/content/utils/course-item.utils';
import { ApiService } from '@core/services/api.service';
import { GUID } from '@shared-types/guid.type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Printable } from '../interfaces/printable.interface';

@Injectable({
  providedIn: 'root',
})
export class ContentApiService {
  constructor(private apiService: ApiService) {}

  public loadLesson(lessonId: GUID): Observable<Lesson> {
    return this.apiService.getBFFGateway<{ result: Lesson }>(`v1/lessons/${lessonId}`).pipe(
      map(({ result }) => ({
        ...result,
        courseItems: sanitizeCourseItems(result.courseItems),
      })),
    );
  }

  public getActivity(activityId: GUID): Observable<Printable> {
    return this.apiService
      .getBFFGateway<{ result: Printable }>(`v1/activities/${activityId}`)
      .pipe(map((response) => response.result));
  }

  public getWorksheet(worksheetId: GUID): Observable<Printable> {
    return this.apiService
      .getBFFGateway<{ result: Printable }>(`v1/worksheets/${worksheetId}`)
      .pipe(map((response) => response.result));
  }
}
