export enum AssignedAssignmentTimeState {
  INITIAL = 'Initial',
  PENDING = 'Pending',
  AVAILABLE = 'Available',
  CLOSED = 'Closed',
}

export enum AssignedAssignmentProgressState {
  INITIAL = 'Initial',
  NOT_STARTED = 'NotStarted',
  STARTED = 'Started',
  COMPLETED = 'Completed',
  NONE = 'None',
}

export enum CourseItemPageElementType {
  TEXT_ELEMENT = 'Text',
  VIDEO_ELEMENT = 'Video',
  IMAGE_ELEMENT = 'Image',
  GEOGEBRA_ELEMENT = 'GeoGebra',
}

export enum CourseItemType {
  TRAINING = 'Training',
  PAGE = 'Page',
}
