import { CourseItem } from '@app/assignments-assigned/interfaces/course-item.interface';
import { CourseItemType } from '@app/assignments-shared/assignments-shared.enums';
import { GUID } from '@shared-types/guid.type';

/**
 * Removes all but the first page item and all but the first training item from the given course items.
 * // TODO MF2-4425 Remove this function and all of its usage
 */
export function sanitizeCourseItems(courseItems: CourseItem[]): CourseItem[] {
  const filteredCourseItems: CourseItem[] = [];
  const firstPage: CourseItem | undefined = courseItems.find((courseItem) => courseItem.type === CourseItemType.PAGE);
  const firstTraining: CourseItem | undefined = courseItems.find(
    (courseItem) => courseItem.type === CourseItemType.TRAINING,
  );

  if (firstPage) {
    filteredCourseItems.push(firstPage);
  }

  if (firstTraining) {
    filteredCourseItems.push(firstTraining);
  }

  return filteredCourseItems.map((courseItem) => ({ ...courseItem, id: getGuidForCourseItemType(courseItem.type) }));
}

/**
 * Returns a fixed GUID for course items based on their type.
 * This is a workaround for an issue where assigned lessons breaks because
 * homework and lesson data is getting out of sync in the backend.
 * // TODO MF2-4425 Remove this function and all of its usage
 */
export function getGuidForCourseItemType(courseItemType: CourseItemType): GUID {
  switch (courseItemType) {
    case CourseItemType.PAGE:
      return '918e9102-a610-4cc4-8a2b-ab19e917da9e' as GUID;
    case CourseItemType.TRAINING:
      return 'd3184b9a-697b-4788-8521-fc060dfdfefd' as GUID;
  }
}
