import { Injectable } from '@angular/core';
import * as UserSelectors from '@app/user/store/user.selectors';
import { AzureAppConfiguration } from '@core/interfaces/azure-app-configuration/azure-app-configuration.interface';
import { AzureAppSettings } from '@core/interfaces/azure-app-configuration/azure-app-settings.interface';
import { AzureFeatureFlags } from '@core/interfaces/azure-app-configuration/azure-feature-flags.interface';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import * as CoreActions from '@core/store/core.actions';
import * as CoreSelectors from '@core/store/core.selectors';
import { CoreState } from '@core/store/interfaces/core-state.interface';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { getObjectProperty, hasOwnProperty } from '@utils/object';
import { getObservableValueSync } from '@utils/observables';
import { camelToPascal } from '@utils/string';
import { combineLatest, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AzureAppConfigurationService {
  public azureAppConfiguration$: Observable<AzureAppConfiguration | null> = this.store.select(
    CoreSelectors.selectAzureAppConfiguration,
  );
  public azureAppConfigurationServerInteractionState$: Observable<ServerInteractionState> = this.store.select(
    CoreSelectors.selectLoadAzureAppConfigurationServerInteractionState,
  );

  /**
   * Cached observables for feature flags.
   * Ensures that only one observable is created for each feature flag no matter how many times it's requested.
   */
  private cachedFeatureFlagObservables: Partial<Record<keyof AzureFeatureFlags, Observable<boolean>>> = {};

  constructor(private store: Store<CoreState>) {}

  public getLanguage(): string {
    return this.getSetting('defaultLanguage', environment.fallbackLanguage);
  }

  public getSetting(settingName: keyof AzureAppSettings, defaultValue: string = ''): string {
    const appSettings = this.getConfiguration();

    if (!appSettings?.settings) {
      return defaultValue;
    }

    return getObjectProperty<string>(appSettings.settings, settingName, defaultValue)!;
  }

  /**
   * Returns an observable with the value for the given feature flag.
   *
   * The given feature flag may not contain the `alinea` prefix.
   * It is added internally when necessary.
   */
  public getFeatureFlagObservable(flagName: keyof AzureFeatureFlags): Observable<boolean> {
    if (!hasOwnProperty(this.cachedFeatureFlagObservables, flagName)) {
      this.cachedFeatureFlagObservables[flagName] = this.createFeatureFlagObservable(flagName);
    }

    return this.cachedFeatureFlagObservables[flagName]!;
  }

  /**
   * Returns the current value for the given feature flag.
   *
   * The given feature flag may not contain the `alinea` prefix.
   * It is added internally when necessary.
   */
  public getFeatureFlag(flagName: keyof AzureFeatureFlags): boolean {
    return getObservableValueSync(this.getFeatureFlagObservable(flagName));
  }

  public reload(): void {
    this.store.dispatch(CoreActions.loadAzureAppConfiguration());
  }

  private getConfiguration(): AzureAppConfiguration | null {
    return getObservableValueSync(this.azureAppConfiguration$);
  }

  /**
   * Creates an observable containing the value for the given feature flag,
   * taking Alinea users into account when necessary.
   *
   * The given feature flag may not contain the `alinea` prefix.
   * It is added internally when necessary.
   */
  private createFeatureFlagObservable(featureFlag: keyof AzureFeatureFlags): Observable<boolean> {
    return combineLatest([this.azureAppConfiguration$, this.store.select(UserSelectors.isAlineaUser)]).pipe(
      map(([azure, isAlineaUser]) => {
        return !!(
          azure?.featureFlags[featureFlag] ||
          (isAlineaUser && azure?.featureFlags[('alinea' + camelToPascal(featureFlag)) as keyof AzureFeatureFlags])
        );
      }),
    );
  }
}
