import { NgModule } from '@angular/core';
import { CartEffects } from '@app/cart/store/cart.effects';
import { cartFeatureKey, cartReducer } from '@app/cart/store/cart.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [StoreModule.forFeature(cartFeatureKey, cartReducer), EffectsModule.forFeature([CartEffects])],
})
export class CartModule {}
