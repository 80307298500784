import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { serverInteractionStateLoading } from '@core/fixtures/server-interaction-state.fixture';
import {
  initialServerInteractionState,
  ServerInteractionState,
} from '@core/interfaces/server-interaction-state.interface';
import { combineLatest, distinctUntilKeyChanged, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

/**
 * Executes the callback with the next emitted value from the observable.
 * In case of replaying observables this will happen instantly with the current value.
 * In case of "normal" observables, this will happen the next time the observable fires.
 */
export function getNextValueFromObservable<T>(observable: Observable<T>, callback: (value: T) => void): void {
  observable.pipe(take(1)).subscribe(callback);
}

/**
 * Returns the current value from an observable instantly
 */
export function getObservableValueSync<T>(observable: Observable<T>): T {
  let returnValue: T | undefined | null = undefined;

  getNextValueFromObservable(observable, (value) => {
    returnValue = value;
  });

  return <T>(<unknown>returnValue);
}

/**
 * Combines the given server interaction state observables to a single observable representing their collective state.
 *
 * Any error takes precedence over everything else.
 * Loading is returned if anything is loading OR some items have been loaded and some haven't yet started loaded.
 * Success is only returned once everything is loaded.
 */
export function combineServerInteractionStateObservables(
  observables: Observable<ServerInteractionState>[],
  ignoreInitialState?: boolean,
): Observable<ServerInteractionState> {
  if (observables.length === 0) {
    return of(initialServerInteractionState);
  }

  return combineLatest(observables).pipe(
    map((serverInteractionStates) => {
      const filtered = serverInteractionStates.filter((serverInteractionState) => serverInteractionState);
      const initial = filtered.filter(
        (serverInteractionState) => serverInteractionState.state === ServerInteractionStateType.INITIAL,
      );
      const loading = filtered.filter(
        (serverInteractionState) => serverInteractionState.state === ServerInteractionStateType.LOADING,
      );
      const success = filtered.filter(
        (serverInteractionState) => serverInteractionState.state === ServerInteractionStateType.SUCCESS,
      );
      const error = filtered.filter(
        (serverInteractionState) => serverInteractionState.state === ServerInteractionStateType.ERROR,
      );

      if (error.length > 0) {
        return error[0];
      }

      if (loading.length > 0) {
        return loading[0];
      }

      if (!ignoreInitialState && initial.length > 0 && success.length > 0) {
        // Fake loading state, since some items have loaded and some haven't yet started loading
        return serverInteractionStateLoading;
      }

      if (success.length === filtered.length) {
        return success[0];
      }

      return initialServerInteractionState;
    }),
    distinctUntilKeyChanged('state'),
  );
}
