import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { shouldLoadData } from '@core/utils/server-interaction-state.utils';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export function createLoadIfNecessaryEffect<T extends ActionCreator>(
  actions$: Actions,
  triggerAction: T,
  serverInteractionStateGetter: (action: ReturnType<T>) => Observable<ServerInteractionState>,
  loadIsNecessaryCallback: (action: ReturnType<T>) => TypedAction<any>[],
  loadIsNotNecessaryCallback?: (action: ReturnType<T>) => TypedAction<any>[],
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(triggerAction),
      concatLatestFrom(serverInteractionStateGetter),
      switchMap(([action, serverInteractionState]) => {
        if (shouldLoadData(serverInteractionState.state)) {
          return loadIsNecessaryCallback(action);
        }

        return loadIsNotNecessaryCallback ? loadIsNotNecessaryCallback(action) : [];
      }),
    ),
  );
}
