/**
 * Returns true if the given element is the child of a dropdown
 */
export function isElementInDropdown(element: HTMLElement): boolean {
  while (element.parentElement) {
    if (['LRU-DROPDOWN-OUTLET', 'APP-DROPDOWN-OUTLET', 'APP-TEAM-SELECTOR-DROPDOWN'].includes(element.tagName)) {
      return true;
    }

    element = element.parentElement;
  }

  return false;
}
