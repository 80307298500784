import { Injectable } from '@angular/core';
import { UmbSiteConfiguration } from '@app/core/interfaces/umbraco/umb-site-configuration.interface';
import { SiteColors, SiteHeaderColors } from '@core/interfaces/project/portal-configuration.interface';

@Injectable({
  providedIn: 'root',
})
export class ColorsMapper {
  public getPortalColors(siteConfiguration: UmbSiteConfiguration): SiteColors {
    const result: SiteColors = {
      primaryColor: '',
      primaryTextColor: '',
      secondaryColor: '',
      secondaryTextColor: '',
      siteBackgroundColor: '',
      siteLinkColor: '',
      siteTextColor: '',
    };

    result.primaryColor = siteConfiguration.primaryColor || '#000000';
    result.primaryTextColor = siteConfiguration.primaryTextColor || '#ffffff';
    result.secondaryColor = siteConfiguration.secondaryColor || '#cccccc';
    result.secondaryTextColor = siteConfiguration.secondaryTextColor || '#ffffff';
    result.siteBackgroundColor = siteConfiguration.siteBackgroundColor || '#ffffff';
    result.siteLinkColor = siteConfiguration.siteLinkColor || '#0000ff';
    result.siteTextColor = siteConfiguration.siteTextColor || '#00ff00';
    return result;
  }

  public getPortalHeaderColors(siteConfiguration: UmbSiteConfiguration): SiteHeaderColors {
    const result: SiteHeaderColors = {
      primaryBackgroundColor: '',
      primaryTextColor: '',
      secondaryBackgroundColor: '',
      secondaryTextColor: '',
    };

    result.primaryBackgroundColor = siteConfiguration.headerPrimaryBackgroundColor || siteConfiguration.primaryColor;
    result.primaryTextColor = siteConfiguration.headerPrimaryTextColor || '#ffffff';
    result.secondaryBackgroundColor =
      siteConfiguration.headerSecondaryBackgroundColor ||
      siteConfiguration.headerPrimaryBackgroundColor ||
      siteConfiguration.primaryColor;
    result.secondaryTextColor =
      siteConfiguration.headerSecondaryTextColor || siteConfiguration.headerPrimaryTextColor || '#ffffff';

    return result;
  }
}
