import { NgModule } from '@angular/core';
import { TeamsEffects } from '@app/teams/store/teams.effects';
import { teamsFeatureKey, teamsReducer } from '@app/teams/store/teams.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [StoreModule.forFeature(teamsFeatureKey, teamsReducer), EffectsModule.forFeature([TeamsEffects])],
})
export class TeamsModule {}
