import { SearchResultItem } from '@app/find-content/interfaces/search-result-item.interface';
import { SearchByIdsState } from '@app/search-by-ids/interfaces/search-by-ids-state.interface';
import { searchByIdsFeatureKey } from '@app/search-by-ids/store/search-by-ids.reducers';
import { createKeyForSearchByIds } from '@app/search-by-ids/utils/store.utils';
import {
  initialServerInteractionState,
  ServerInteractionState,
} from '@core/interfaces/server-interaction-state.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';

export const selectSearchByIdsState = createFeatureSelector<SearchByIdsState>(searchByIdsFeatureKey);

export const selectSearchByIdsServerInteractionState = (ids: GUID[]) =>
  createSelector(
    selectSearchByIdsState,
    ({ serverInteractionStates }): ServerInteractionState =>
      serverInteractionStates[createKeyForSearchByIds(ids)] ?? initialServerInteractionState,
  );

export const selectSearchByIdsResultItems = (ids: GUID[]) =>
  createSelector(
    selectSearchByIdsState,
    ({ results }): SearchResultItem[] => results[createKeyForSearchByIds(ids)] ?? [],
  );
