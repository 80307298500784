import { RouterLinkCommands } from '@shared-types/router-link-command.type';

/**
 * Returns true if the given router link command(s) represents an absolute path
 */
export function isAbsolutePath(path: RouterLinkCommands): boolean {
  return isAbsolutePathString(path) || (Array.isArray(path) && path.length > 0 && isAbsolutePathString(path[0]));
}

/**
 * Returns true if the given router link command(s) is an absolute path string
 */
export function isAbsolutePathString(routerLinkCommands: RouterLinkCommands): boolean {
  return typeof routerLinkCommands === 'string' && routerLinkCommands.indexOf('/') === 0;
}

/**
 * Creates an absolute path array based on the current path and the given relative path
 */
export function createAbsolutePathFromRelativePath(relativePath: RouterLinkCommands, basePath: string): string[] {
  // Use the path as is, if it's already an absolute path
  if (isAbsolutePath(relativePath)) {
    return typeof relativePath === 'string' ? [relativePath] : relativePath!;
  }

  // Convert to an array and remove empty sections
  if (typeof relativePath === 'string') {
    relativePath = (relativePath as string).split('/').filter((a) => a);
  }

  // Build the new path
  const newPath = ['/', ...basePath.split('/').filter((a) => a)];
  [...relativePath!].reverse().forEach((part) => {
    if (part === '..') {
      newPath.pop();
    } else {
      newPath.push(part);
    }
  });

  return newPath;
}
