import { ServerInteractionStateType } from '@app/core/enums/server-interaction-state-type.enum';
import { ParsedServerError } from '@app/core/interfaces/project/parsed-server-error.interface';
import { createAction, props } from '@ngrx/store';

import { PageState } from '../interfaces/page-state.interface';
import { pageType } from '../page.types';

export const setPage = createAction('[Page] Set page state', props<{ data: PageState }>());

export const setPageType = createAction('[Page] Set pageType', props<{ pageType: pageType }>());

export const setDocumentTitle = createAction('[Page] Set documentTitle', props<{ title: string }>());

export const setUserServerInteractionState = createAction(
  '[Page] Set page server interaction state',
  props<{ state: ServerInteractionStateType; error?: ParsedServerError }>(),
);
