import { animate, style, transition, trigger } from '@angular/animations';
import { softBezier } from '@shared-animations/beziers.animation';

const transitionTiming = `.3s ${softBezier}`;
const fadeHiddenStyle = style({ opacity: 0 });
const fadeVisibleStyle = style({ opacity: 1 });

export const fadeInOutAnimation = trigger('fadeInOut', [
  transition(':enter', [fadeHiddenStyle, animate(transitionTiming, fadeVisibleStyle)]),
  transition(':leave', [animate(transitionTiming, fadeHiddenStyle)]),
]);
