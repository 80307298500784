import { createReducer, on } from '@ngrx/store';

import * as ToastActions from './toast.actions';
import { initialToastState } from './toast.state';

export const toastFeatureKey = 'toastState';

export const toastReducer = createReducer(
  initialToastState,
  on(ToastActions.addMessage, (state, action) => ({
    ...state,
    messages: [...state.messages, action.message],
  })),
  on(ToastActions.updateMessage, (state, action) => {
    const messages = [...state.messages];
    const currentIndex = messages.findIndex((message) => message.id === action.message.id);

    if (currentIndex !== -1) {
      messages[currentIndex] = { ...action.message };
    }

    return {
      ...state,
      messages,
    };
  }),
  on(ToastActions.removeMessage, (state, action) => {
    const messages = state.messages.filter((message) => message.id !== action.id);

    return { ...state, messages: messages };
  }),
);
