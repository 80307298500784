import { Injectable } from '@angular/core';
import { UserService } from '@app/user/services/user.service';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { OperationalNotification } from '@core/interfaces/umbraco/operational-notification.interface';
import * as CoreActions from '@core/store/core.actions';
import * as CoreSelectors from '@core/store/core.selectors';
import { CoreState } from '@core/store/interfaces/core-state.interface';
import { Store } from '@ngrx/store';
import { getObservableValueSync } from '@utils/observables';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OperationalNotificationService {
  public readonly operationalNotification$: Observable<OperationalNotification | undefined> = this.store.select(
    CoreSelectors.selectOperationalNotification,
  );

  public readonly operationalNotificationLoadServerInteractionState$: Observable<ServerInteractionState> =
    this.store.select(CoreSelectors.selectOperationalNotificationServerInteractionState);

  public readonly canDismissOperationalNotification$: Observable<boolean> = this.userService.userId$.pipe(
    map((userId) => !!userId),
  );

  constructor(
    private store: Store<CoreState>,
    private userService: UserService,
  ) {}

  public dismissOperationalNotification(notificationToken: string): void {
    if (getObservableValueSync(this.canDismissOperationalNotification$)) {
      this.store.dispatch(
        CoreActions.operationalNotificationDismiss({
          notificationToken,
          userId: getObservableValueSync(this.userService.userId$)!,
        }),
      );
    }
  }
}
