<ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async, user: user$ | async } as sub">
  <div class="user-wrapper" [class.logged-in]="sub.isLoggedIn">
    <ng-container *ngIf="!sub.isLoggedIn; else templateUserLoggedIn">
      <div>{{ 'app.components.user.not-logged-in-message' | translate }}</div>
      <app-button [size]="ButtonSize.SMALL" (click)="onLogin()">{{
        'app.components.user.login-button' | translate
      }}</app-button>
    </ng-container>
  </div>

  <ng-template #templateUserLoggedIn>
    <div>
      {{ 'app.components.user.logged-in-message' | translate: { username: sub.user!.displayName } }}
    </div>
    <div class="buttons">
      <app-button [size]="ButtonSize.SMALL" (click)="onLogout()">{{
        'app.components.user.logout-button' | translate
      }}</app-button>

      <app-button
        *ngIf="azureAppConfigurationService.getFeatureFlagObservable('achievementsPage') | async"
        [size]="ButtonSize.SMALL"
        [routerLink]="['mf20-achievements-page'] | pageTypeLink"
      >
        {{ 'achievement.title' | translate }}</app-button
      >
    </div>
  </ng-template>
</ng-container>
