import { HttpErrorResponse } from '@angular/common/http';
import { ParsedServerError } from '@core/interfaces/project/parsed-server-error.interface';

/**
 * Parses response errors in our custom format and returns a consistent error object with HTTP status and error message.
 */
export function parseCustomErrorResponse(errorResponse: HttpErrorResponse): ParsedServerError {
  return {
    httpStatus: errorResponse.status,
    message:
      errorResponse.error?.errorCode ||
      errorResponse.error?.errorMessage ||
      `server.error.response-code-${errorResponse.status}`,
    messageParameters: errorResponse.error?.parameters || {},
    validationErrors: errorResponse.error?.validationErrors,
  } as ParsedServerError;
}

/**
 * Parses general http response errors and returns a consistent error object with HTTP status and error message.
 */
export function parseHttpErrorResponse(errorResponse: HttpErrorResponse): ParsedServerError {
  return {
    httpStatus: errorResponse.status,
    message: `server.error.response-code-${errorResponse.status}`,
    messageParameters: {},
  } as ParsedServerError;
}
