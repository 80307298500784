import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs/operators';

import * as ToastActions from './toast.actions';
import * as ToastSelectors from './toast.selectors';

@Injectable()
export class ToastEffects {
  /** An effect that maps the message to an InitializeMessageTimeout action */
  addMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ToastActions.addMessage),
      map(({ message }) => ToastActions.initializeMessageTimeout({ message })),
    );
  });

  updateMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ToastActions.updateMessage),
      exhaustMap(({ message, ignoreTiming }) => {
        if (ignoreTiming) {
          return [];
        }

        if (message.expirationTimestamp) {
          message.duration = Date.now() - message.expirationTimestamp;
          delete message.expirationTimestamp;
        }

        return [ToastActions.clearMessageTimeout({ message }), ToastActions.initializeMessageTimeout({ message })];
      }),
    );
  });

  /** If message has a duration we start a timeout with the message's duration. After the timeout we dispatch an action to clear the message */
  initializeMessageTimeout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ToastActions.initializeMessageTimeout),
        map(({ message }) => {
          if (message.duration) {
            const expirationTimestamp = Date.now() + message.duration;

            const timeoutId = window.setTimeout(() => {
              this.store.dispatch(ToastActions.removeMessage({ id: message.id }));
            }, message.duration);

            this.store.dispatch(
              ToastActions.updateMessage({
                message: { ...message, timeoutId, expirationTimestamp },
                ignoreTiming: true,
              }),
            );
          }
        }),
      );
    },
    { dispatch: false },
  );

  /**
   * Clears timeout for specific toast message.
   * This is needed since timeout can be paused.
   */
  clearMessageTimeout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ToastActions.clearMessageTimeout),
      exhaustMap((action) => {
        window.clearTimeout(action.message.timeoutId);
        const message = { ...action.message };

        delete message.timeoutId;

        return [ToastActions.updateMessage({ message })];
      }),
    );
  });

  /** Pause the countdown for toast messages to go away  */
  pauseMessageExpiration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ToastActions.pauseMessageExpiration),
      concatLatestFrom(() => [this.store.select(ToastSelectors.selectAllMessages)]),
      exhaustMap(([action, messages]) => {
        const now = Date.now();

        return messages
          .filter((message) => message.expirationTimestamp)
          .map((message) => {
            clearTimeout(message.timeoutId);
            const newMessage = {
              ...message,
              // Update the duration to match the remaining time
              duration: message.expirationTimestamp! - now,
            };

            // Remove expiration timestamp since it's now invalid
            delete newMessage.expirationTimestamp;

            return ToastActions.updateMessage({ message: newMessage, ignoreTiming: true });
          });
      }),
    );
  });

  /** Continue the countdown for toast messages to go away */
  continueMessageExpiration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ToastActions.continueMessageExpiration),
      concatLatestFrom(() => [this.store.select(ToastSelectors.selectAllMessages)]),
      exhaustMap(([action, messages]) => messages.map((message) => ToastActions.initializeMessageTimeout({ message }))),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}
}
