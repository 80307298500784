import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ToastService } from '@app/toast/services/toast.service';
import { ToastEventType } from '@app/toast/toast-types';
import { TrackingService } from '@app/tracking/services/tracking.service';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { TranslateService } from '@ngx-translate/core';
import { Icons } from '@shared-data/icons';
import { Icon } from '@shared-interfaces/icon.interface';

interface DisplayState {
  icon: Icon;
  duration: number;
}

@Pipe({
  standalone: true,
  name: 'serverInteractionIcon',
  pure: false, // required to update the value when the timeout finishes
})
export class ServerInteractionIconPipe implements PipeTransform, OnDestroy {
  private defaultIcon: Icon = Icons.BLANK;
  private currentIcon: Icon = Icons.BLANK;
  private currentStateType?: ServerInteractionStateType;

  private updateTimeoutId?: number;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private trackingService: TrackingService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  transform(
    defaultIcon: Icon,
    state?: ServerInteractionState | null,
    trackingPrefix: string = 'server-interaction-icon',
  ): Icon {
    if (defaultIcon === this.defaultIcon && state?.state === this.currentStateType) {
      return this.currentIcon;
    }

    this.defaultIcon = defaultIcon;
    this.currentStateType = state?.state;

    const displayState = this.decideDisplayState(defaultIcon, this.currentStateType);

    this.currentIcon = displayState.icon;

    if (this.currentIcon !== this.defaultIcon) {
      this.scheduleReturnToDefaultIcon(displayState.duration);
    }

    if (this.currentStateType === ServerInteractionStateType.ERROR && state?.error?.message) {
      this.toastService.displayMessage({
        message: this.translateService.instant(state.error.message),
        duration: displayState.duration,
        type: ToastEventType.ERROR_ALERT,
      });

      this.trackingService.trackEvent(`${trackingPrefix}-error`, {
        message: state.error.message,
        httpStatus: state.error.httpStatus,
      });
    }

    return this.currentIcon;
  }

  ngOnDestroy(): void {
    this.clearUpdateTimer();
  }

  /**
   * Returns the correct display state with icon and timeout for the current state.
   */
  private decideDisplayState(defaultIcon: Icon, state?: ServerInteractionStateType): DisplayState {
    switch (state) {
      case ServerInteractionStateType.SUCCESS:
        return { icon: Icons.CHECKMARK, duration: 3000 };
      case ServerInteractionStateType.ERROR:
        return { icon: Icons.CLOSE, duration: 5000 };
    }

    return { icon: defaultIcon, duration: 0 };
  }

  /**
   * Schedules a change from the current temporary icon to the default icon
   */
  private scheduleReturnToDefaultIcon(duration: number): void {
    this.clearUpdateTimer();
    this.updateTimeoutId = window.setTimeout(() => {
      this.returnToDefaultIcon();
    }, duration);
  }

  /**
   * Replaces the current icon with the default icon
   */
  private returnToDefaultIcon(): void {
    this.currentIcon = this.defaultIcon;
    this.changeDetector.markForCheck();
  }

  /**
   * Clears the update time if one is present
   */
  private clearUpdateTimer(): void {
    clearTimeout(this.updateTimeoutId);
  }
}
