import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared-components/ui/icon/icon.component';
import { Icons } from '@shared-data/icons';
import { IconSize } from '@shared-enums/icon-size.enum';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';
import { TooltipDirective } from '@shared-modules/tooltip/directives/tooltip.directive';
import { RouterLinkCommands } from '@shared-types/router-link-command.type';

/**
 * Renders the page title and optional back button for use at the top of a page.
 * Please note: Both the previous page title and router link are required for the link to render to ensure accessibility
 */
@Component({
  selector: 'app-page-title',
  standalone: true,
  imports: [CommonModule, RouterLink, IconComponent, TooltipDirective, TranslateSharedModule],
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent {
  @Input() heading: string = '';
  @Input() previousPageRouterLink: RouterLinkCommands = null;
  @Input() previousPageTitle: string = '';

  protected readonly Icons = Icons;
  protected readonly IconSize = IconSize;
}
