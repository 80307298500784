import { Injectable } from '@angular/core';
import { TrackingService } from '@app/tracking/services/tracking.service';
import { UserState } from '@app/user/interfaces/user-state.interface';
import * as UserActions from '@app/user/store/user.actions';
import * as UserSelectors from '@app/user/store/user.selectors';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { AzureAppConfigurationService } from '@core/services/azure-app-configuration.service';
import { RoutingBuilderService } from '@core/services/routing-builder.service';
import * as CoreActions from '@core/store/core.actions';
import * as CoreSelectors from '@core/store/core.selectors';
import { CoreState } from '@core/store/interfaces/core-state.interface';
import { isFinalServerInteractionStateType, shouldLoadData } from '@core/utils/server-interaction-state.utils';
import { Store } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';
import { combineLatest, filter, take } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private coreStore: Store<CoreState>,
    private userStore: Store<UserState>,
    private trackingService: TrackingService,
    private routingBuilderService: RoutingBuilderService,
    private azureAppConfigurationService: AzureAppConfigurationService,
  ) {}

  public initializeApplication(): void {
    this.coreStore.dispatch(CoreActions.loadAzureAppConfiguration());

    // Load user data once we know what API endpoint to use
    this.coreStore.select(CoreSelectors.selectAzureAppConfiguration).subscribe((appConfiguration) => {
      if (appConfiguration) {
        this.coreStore.dispatch(UserActions.loadUser({}));

        // Load operational notification right away
        this.coreStore.dispatch(CoreActions.operationalNotificationLoad());

        // Update the operational notification every 5 minutes
        setInterval(
          () => {
            this.coreStore.dispatch(CoreActions.operationalNotificationLoad());
          },
          // Load the refresh interval from settings. Default to 300 seconds (5 minutes)
          parseInt(this.azureAppConfigurationService.getSetting('operationalNotificationRefreshInterval', '300')) *
            1000,
        );
      }
    });

    /**
     * The previous user ID is used the validate whether the user logged in has been replaced in another tab.
     */
    let previousUserId: GUID | undefined = undefined;

    combineLatest([
      this.userStore.select(UserSelectors.selectCurrentUserId),
      this.userStore.select(UserSelectors.selectUserServerInteractionState),
      this.userStore.select(CoreSelectors.selectLoadSiteBootstrapServerInteractionState),
    ]).subscribe(([currentUserId, loadUserServerInteractionState, siteBootstrapServerInteractionState]) => {
      // Load/reload the portal configuration if the user has changed, or we haven't loaded any configuration yet
      // ...and only if the current load user server interaction state is in a final state
      if (
        isFinalServerInteractionStateType(loadUserServerInteractionState.state) &&
        (shouldLoadData(siteBootstrapServerInteractionState.state) || previousUserId !== currentUserId)
      ) {
        if (currentUserId) {
          this.coreStore.dispatch(
            CoreActions.operationalNotificationReadDismissedFromLocalStorage({ userId: currentUserId }),
          );
        }

        this.coreStore.dispatch(CoreActions.loadSiteBootstrap());
      }

      previousUserId = currentUserId;
    });

    // Set up the routing once the data from Umbraco is ready
    combineLatest([
      this.coreStore.select(CoreSelectors.selectLoadSiteBootstrapServerInteractionState),
      this.coreStore.select(CoreSelectors.selectPortalConfiguration),
    ])
      .pipe(
        filter(([status, data]) => {
          return status.state === ServerInteractionStateType.SUCCESS;
        }),
        map(([status, data]) => data),
        take(1),
      )
      .subscribe({
        next: (data) => {
          this.routingBuilderService.CreateRouting(data.customPagesInfo);
        },
        error: (error) => {
          this.routingBuilderService.CreateRouting([]);
        },
      });

    this.coreStore.select(CoreSelectors.selectPortalConfiguration).subscribe((config) => {
      if (config.projectGroup) {
        this.trackingService.initialize({ googleTagManagerId: config.googleTagManagerId });
      }
    });
  }
}
