import { CartItemRequest } from '@app/cart/interfaces/cart-item-request.interface';
import { CartState } from '@app/cart/interfaces/cart-state.interface';
import { getInternalCartItemRequestId } from '@app/cart/store/cart.utils';
import {
  initialServerInteractionState,
  ServerInteractionState,
} from '@app/core/interfaces/server-interaction-state.interface';
import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { cartFeatureKey } from './cart.reducers';

export const selectCartState = createFeatureSelector<CartState>(cartFeatureKey);

export const selectItems = createSelector(selectCartState, (state: CartState) => state.items);
// Returns undefined until the cart has been loaded for the first time
export const selectItemCount = createSelector(selectCartState, (state: CartState) =>
  state.initialized ? state.items.length : undefined,
);

export const selectCartServerInteractionState = createSelector(
  selectCartState,
  (state: CartState) => state.cartServerInteractionState,
);

export const selectEmptyCartServerInteractionState = createSelector(
  selectCartState,
  (state: CartState) => state.emptyCartServerInteractionState,
);

export const selectAddToCartServerInteractionState = (
  item: CartItemRequest,
): MemoizedSelector<object, ServerInteractionState, DefaultProjectorFn<ServerInteractionState>> =>
  createSelector(
    selectCartState,
    (state: CartState) => state.addToCartServerInteractionStates[getInternalCartItemRequestId(item)],
  );

export const selectRemoveFromCartServerInteractionStates = createSelector(
  selectCartState,
  (state: CartState) => state.removeFromCartServerInteractionStates,
);

export const selectRemoveFromCartServerInteractionState = (
  item: CartItemRequest,
): MemoizedSelector<object, ServerInteractionState, DefaultProjectorFn<ServerInteractionState>> =>
  createSelector(
    selectCartState,
    (state: CartState) =>
      state.removeFromCartServerInteractionStates[getInternalCartItemRequestId(item)] ?? initialServerInteractionState,
  );
