import { alignType, containerWidthType } from '@app/page/page.types';

export function getUmbracoAlign(align?: alignType, fallback?: alignType): alignType {
  if (!align) {
    return fallback || 'left';
  }
  return align.toLowerCase() as alignType;
}

export function getUmbracoWidth(width?: containerWidthType): containerWidthType {
  if (width === 'fullWidth') {
    return 'full-width';
  }
  return width || 'large';
}

export function getUmbracoPercentageWidth(width?: number): string {
  return width ? `${width}%` : '100%';
}

export function getCustomUrl(url?: string): string | undefined {
  return url ? `/${url.replace('/', '')}` : url;
}

export function getHttpsUrl(url?: string): string | undefined {
  if (!url) {
    return;
  }

  if (url.startsWith('www.') || !new RegExp('^(http|https)://', 'i').test(url)) {
    return 'https://' + url;
  }

  return url;
}
