import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { UmbMetaData } from '../interfaces/umbraco/umb-meta-data.interface';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta,
  ) {}

  setMetaData(data: UmbMetaData): void {
    let image = '';

    if (data.defaultImage) {
      image = data.defaultImage;
    } else if (data.logoImage) {
      image = data.logoImage;
    }

    data.metaTitle = data.metaTitle ? data.metaTitle : '';
    data.metaDescription = data.metaDescription ? data.metaDescription : '';

    this.meta.updateTag({
      name: 'keywords',
      content: data.keywords ? data.keywords.join(', ') : '',
    });
    this.meta.updateTag({ name: 'description', content: data.metaDescription });

    this.meta.updateTag({
      name: 'twitter:card',
      content: 'summary_large_image',
    });
    this.meta.updateTag({ name: 'twitter:title', content: data.metaTitle });
    this.meta.updateTag({
      name: 'twitter:text:title',
      content: data.metaTitle,
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content: data.metaDescription,
    });
    this.meta.updateTag({ name: 'twitter:image', content: image });
    this.meta.updateTag({ name: 'twitter:image:alt', content: data.metaTitle });

    this.meta.updateTag({ property: 'og:url', content: window.location.href });
    this.meta.updateTag({ property: 'og:title', content: data.metaTitle });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({
      property: 'og:image:alt',
      content: image ? data.metaTitle : '',
    });
    this.meta.updateTag({
      property: 'og:description',
      content: data.metaDescription,
    });

    this.meta.updateTag({
      name: 'robots',
      content: data.excludeFromSearchEngines ? 'noindex' : 'all',
    });
  }

  setTitle(tabTitle: string): void {
    this.title.setTitle(tabTitle);
  }
}
