import { LessonsState } from '@app/content/interfaces/lessons-state.interface';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ContentState } from '../interfaces/content.interface';
import { Printable, PrintableState } from '../interfaces/printable.interface';
import * as ContentActions from './content.actions';

export const contentFeatureKey: string = 'contentState';

export const PrintableAdapter: EntityAdapter<Printable> = createEntityAdapter<Printable>();

export const initialLessonsState: LessonsState = {
  lessons: {},
  loadLessonServerInteractionStates: {},
};

export const initialPrintableAdapter = PrintableAdapter.getInitialState({
  printable: {
    ids: [],
    entities: null,
  },
});

export const initialPrintableState: PrintableState = {
  printable: initialPrintableAdapter,
  loadPrintableServerInteractionStates: {},
};

export const initialContentState: ContentState = {
  lessonState: initialLessonsState,
  printableState: initialPrintableState,
};

export const contentReducer = createReducer(
  initialContentState,
  on(
    ContentActions.setPrintable,
    (state, action): ContentState => ({
      ...state,
      printableState: {
        ...state.printableState,
        printable: PrintableAdapter.addOne(action.data, state.printableState.printable),
      },
    }),
  ),

  on(
    ContentActions.setLoadPrintableServerInteractionState,
    (state, action): ContentState => ({
      ...state,
      printableState: {
        ...state.printableState,
        loadPrintableServerInteractionStates: {
          ...state.printableState.loadPrintableServerInteractionStates,
          [action.printableId]: {
            state: action.state,
            error: action.error ?? null,
          },
        },
      },
    }),
  ),

  on(
    ContentActions.setLesson,
    (state, action): ContentState => ({
      ...state,
      lessonState: {
        ...state.lessonState,
        lessons: {
          ...state.lessonState.lessons,
          [action.lesson.id]: action.lesson,
        },
      },
    }),
  ),

  on(
    ContentActions.setLoadLessonServerInteractionState,
    (state, action): ContentState => ({
      ...state,
      lessonState: {
        ...state.lessonState,
        loadLessonServerInteractionStates: {
          ...state.lessonState.loadLessonServerInteractionStates,
          [action.lessonId]: {
            state: action.state,
            error: action.error ?? null,
          },
        },
      },
    }),
  ),
);
