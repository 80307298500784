import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { GradeLevelsService } from '@app/grade-levels/services/grade-levels.service';
import { GUID } from '@shared-types/guid.type';
import { arrayEqualsArray } from '@utils/array';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Transforms an array of grade-level IDs to a human-readable grade-level span: `1st - 3rd grade`
 */
@Pipe({
  standalone: true,
  name: 'formatGradeLevelSpan',
  pure: false, // required to update the value when the observable emits
})
export class FormatGradeLevelSpanPipe implements PipeTransform, OnDestroy {
  value: string = '';
  lastGradeLevelIds: GUID[] = [];
  lastAbbreviated: boolean = false;

  sub?: Subscription;

  constructor(
    private levelsService: GradeLevelsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  transform(gradeLevelIds: GUID[], abbreviated: boolean = false): string {
    // Return the last value if the input hasn't changed
    if (abbreviated === this.lastAbbreviated && arrayEqualsArray(gradeLevelIds, this.lastGradeLevelIds)) {
      return this.value;
    }

    // Store the new input for reference in the check above
    this.lastGradeLevelIds = gradeLevelIds;
    this.lastAbbreviated = abbreviated;

    // Clean up any old subscriptions to make sure we're not running them multiple times
    this.cleanUpSubscription();

    const formattedLevelSpan$ = this.levelsService
      .getLevelObservables(gradeLevelIds)
      .pipe(map((gradeLevelIds) => this.levelsService.formatLevelSpan(gradeLevelIds, abbreviated)));

    this.sub = formattedLevelSpan$.subscribe((levelSpan) => {
      // Update the cached value
      this.value = levelSpan;

      // Re-trigger the rendering
      this.changeDetectorRef.markForCheck();
    });

    // Return the cached value
    return this.value;
  }

  private cleanUpSubscription(): void {
    this.sub?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.cleanUpSubscription();
  }
}
