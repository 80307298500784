import { NgModule } from '@angular/core';
import { SearchByIdsModule } from '@app/search-by-ids/search-by-ids.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PageEffects } from './store/page.effects';
import { pageFeatureKey, pageReducer } from './store/page.reducers';

@NgModule({
  imports: [
    SearchByIdsModule,
    StoreModule.forFeature(pageFeatureKey, pageReducer),
    EffectsModule.forFeature([PageEffects]),
  ],
})
export class PageModule {}
