import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '@app/guards/login.guard';
import { PageLayout } from '@shared-enums/page-layout.enum';

import { RouteHasPage } from './page/guards/route-has-page.guard';
import { SetPageForRoute } from './page/guards/set-page-for-route.guard';
import { pageType } from './page/page.types';

export function getPageType(pageType: pageType): pageType {
  return pageType;
}

export const routes: Routes = [
  {
    path: 'debug',
    loadComponent: () => import('@app/debug/pages/debug-page/debug-page.component').then((x) => x.DebugPageComponent),
  },
  {
    path: 'home/profile',
    loadComponent: () =>
      import('@shared-components/profile-page/profile-page.component').then((x) => x.ProfilePageComponent),
    pathMatch: 'full',
    canActivate: mapToCanActivate([LoginGuard]),
  },
  {
    path: 'geogebra-test',
    loadComponent: () =>
      import('@app/geogebra-tools/pages/geogebra-test-page/geogebra-test-page.component').then(
        (x) => x.GeogebraTestPageComponent,
      ),
  },
  {
    path: 'logout',
    loadComponent: () => import('./components/logout/logout.component').then((x) => x.LogoutComponent),
  },
];

export const lastRoutes = [
  // {
  //   path: getPageType('frontpage'),
  //   loadComponent: () => import('@app/page/page.component').then((x) => x.PageComponent),
  //   data: { pageType: getPageType('contentpage'), layout: { pageLayout: PageLayout.PAGE } },
  //   canActivate: mapToCanActivate([RouteHasPage]),
  // },
  {
    path: getPageType('contentpage'),
    loadComponent: () => import('@app/page/page.component').then((x) => x.PageComponent),
    data: { pageType: getPageType('contentpage'), layout: { pageLayout: PageLayout.PAGE } },
    canActivate: mapToCanActivate([RouteHasPage]),
  },
  {
    path: getPageType('error-503'),
    loadComponent: () => import('@app/page/page.component').then((x) => x.PageComponent),
    data: { pageType: getPageType('error-503') },
    canActivate: mapToCanActivate([RouteHasPage]),
  },
  {
    path: '**',
    loadComponent: () => import('@app/components/empty/empty.component').then((x) => x.EmptyComponent),
    canActivate: mapToCanActivate([SetPageForRoute]),
    data: { lastRoute: true },
  },
];

export const appRoutes: Routes = [
  ...routes,
  /*
   * The Angular Router removes the path from the URL as soon as an unknown route is found.
   * This causes a bug where routing defined in Umbraco will fail, because the router removes the path before we
   * have had the chance to set up the proper routes.
   * To prevent this issue, we're catching all unknown routes with an empty route initially.
   * Once routing has been rebuild by the `RoutingBuilderService` this route will be removed and the use will be
   * presented with either the actual route or an error page if the route didn't exist.
   */
  {
    path: '**',
    loadComponent: () => import('@app/components/empty/empty.component').then((x) => x.EmptyComponent),
  },
];
