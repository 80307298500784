let counter = 0;

export function createUniqueId(prefix?: string): string {
  return `${prefix || 'uuid'}-${counter++}`;
}

/**
 * Returns true if the given string looks like a valid GUID.
 */
export function isGuid(guid: string): boolean {
  return /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/.test(guid);
}
