import { Pipe, PipeTransform } from '@angular/core';
import { pageType } from '@app/page/page.types';
import { PageService } from '@app/page/services/page.service';
import { RouterLinkCommands } from '@shared-types/router-link-command.type';

@Pipe({
  name: 'pageTypeLink',
  standalone: true,
})
export class PageTypeLinkPipe implements PipeTransform {
  constructor(private pageService: PageService) {}
  transform(value: pageType | [pageType, ...RouterLinkCommands[]]): string | undefined {
    const valueIsPageTypeOnly = typeof value === 'string';

    return valueIsPageTypeOnly
      ? this.pageService.getUrlForPageType(value)
      : this.pageService.getUrlForPageType(...value);
  }
}
