<div class="base-circle" *ngIf="showProgressBackground"><img src="assets/svg/badges/Progress.svg" /></div>

<app-progress-circle
  *ngIf="showProgress"
  [progress]="{
    state: AssignedAssignmentProgressState.COMPLETED,
    steps: targetAmount,
    stepsCompleted: currentAmount
  }"
></app-progress-circle>

<div class="badge-container" [class.show-progress]="showProgressBackground">
  <img [src]="badgePath" [alt]="badgeAltText" />
  <div class="new-sticker" *ngIf="badge?.achievedAt && !badge?.seenInPageAt">
    <img [alt]="badgeNewAltText" [class.one-off-badge]="oneOff" src="assets/svg/badges/new-sticker.svg" />
    <span class="heading-5">{{ 'achievement.new-sticker-text' | translate }}</span>
  </div>
</div>
