import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { GeogebraModule } from '@lru/geogebra';

@NgModule({
  imports: [
    GeogebraModule.forRoot({
      locale: environment.defaultLanguage,
      jsPath: 'https://stocontentbcvprod.blob.core.windows.net/geogebra',
      useGeogebraCdn: true,
    }),
  ],
  exports: [GeogebraModule],
})
export class GeogebraSharedForRootModule {
  // static forRoot(config?: GeogebraLibraryConfig): ModuleWithProviders<GeogebraModule> {
  //   if (!config) {
  //     console.log('config NOT provided - config:', config);
  //     return GeogebraModule.forRoot({ locale: environment.defaultLanguage, jsPath: 'https://stoassetserviceprod.blob.core.windows.net/geogebra', });
  //   }
  //   console.log('config present - config:', config);
  //   return GeogebraModule.forRoot(config);
  // }
  // static forChild(config?: GeogebraLibraryConfig): ModuleWithProviders<GeogebraModule> {
  //   return GeogebraModule.forChild(config);
  // }
}
