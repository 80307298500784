import { MegaMenu } from '@app/page/interfaces/mega-menu.interface';
import { NavbarItem } from '@app/page/interfaces/navbar.interface';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { AzureAppConfiguration } from '@core/interfaces/azure-app-configuration/azure-app-configuration.interface';
import { ParsedServerError } from '@core/interfaces/project/parsed-server-error.interface';
import { PortalConfiguration } from '@core/interfaces/project/portal-configuration.interface';
import { OperationalNotification } from '@core/interfaces/umbraco/operational-notification.interface';
import { createAction, props } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';

export const loadAzureAppConfiguration = createAction('[App Settings] Load Azure App Configuration');
export const setAzureAppConfiguration = createAction(
  '[App Settings] Set Azure App Configuration',
  props<{ azureAppConfiguration: AzureAppConfiguration }>(),
);
export const setLoadAzureAppConfigurationServerInteractionState = createAction(
  '[App Settings] Set load Azure App Configuration server interaction state',
  props<{ state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const loadSiteBootstrap = createAction('[App] Load site bootstrap');

export const setPortalConfiguration = createAction('[App] Set config', props<{ config: PortalConfiguration }>());

export const setNavbar = createAction('[App] Set Navbar', props<{ navbar: NavbarItem[] }>());

export const setMegaMenu = createAction('[App] Set MegaMenu', props<{ megaMenu: MegaMenu }>());

export const setLoadSiteBootstrapServerInteractionState = createAction(
  '[App] Set load site bootstrap server interaction state',
  props<{ state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const setStylesheet = createAction('[App] Set stylesheet');
export const setBrowserIndicator = createAction('[App] Set browser indicator');

export const operationalNotificationLoad = createAction('[App] Load operational notification');

export const operationalNotificationLoadSetServerInteractionState = createAction(
  '[App] Set load operational notification server interaction state',
  props<{ state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const operationalNotificationSetData = createAction(
  '[App] Set operational notification',
  props<{ operationalNotification: OperationalNotification }>(),
);

export const operationalNotificationClearData = createAction('[App] Remove operational notification');

export const operationalNotificationReadDismissedFromLocalStorage = createAction(
  '[App] Read dismissed operational notifications from local storage',
  props<{ userId: GUID }>(),
);

export const operationalNotificationWriteDismissedToLocalStorage = createAction(
  '[App] Write dismissed operational notifications to local storage',
  props<{ userId: GUID; dismissedNotificationTokens: string[] }>(),
);

export const operationalNotificationStoreDismissed = createAction(
  '[App] Store dismissed operational notifications in the store',
  props<{ dismissedNotificationTokens: string[] }>(),
);

export const operationalNotificationDismiss = createAction(
  '[App] Dismiss operational notification',
  props<{ notificationToken: string; userId: GUID }>(),
);
