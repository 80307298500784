import { animate, style, transition, trigger } from '@angular/animations';
import { softBezier } from '@shared-animations/beziers.animation';

const transitionTiming = `.3s ${softBezier}`;

const leave = style({
  opacity: 0,
  transform: `translate(-50%, {{ translateYLeave }})`,
});

const enter = style({
  opacity: 1,
  transform: 'translate(-50%, {{ translateYEnter }})',
});

const transitionConfig = {
  params: { translateYLeave: '-100px', translateYEnter: '100px' },
};

/**
 * Fades a newly added DOM node in with a bit of downward motion and out with the opposite motion.
 */
export const tooltipAnimation = trigger('tooltipAnimation', [
  transition(':enter', [leave, animate(transitionTiming, enter)], transitionConfig),
  transition(':leave', [enter, animate(transitionTiming, leave)], transitionConfig),
]);
