import { arrayEqualsArray } from '@utils/array';

/**
 * Returns true if the given objects have the same keys and the same values.
 * Only does a shallow comparison.
 */
export function objectEqualsObject(obj1: { [key: string]: any }, obj2: { [key: string]: any }): boolean {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  const keysMatch = arrayEqualsArray(obj1Keys, obj2Keys);

  if (!keysMatch) {
    return false;
  }

  let valuesMatch = false;

  for (let i = 0; i < obj1Keys.length; i++) {
    valuesMatch = obj1[obj1Keys[i]] === obj2[obj1Keys[i]];

    if (!valuesMatch) {
      break;
    }
  }

  return valuesMatch;
}

export function hasOwnProperty(obj: object, property: string): boolean {
  return Object.prototype.hasOwnProperty.call(obj, property);
}

/**
 * Returns true if the given object is either empty or doesn't exist at all.
 */
export function isEmptyObject(object?: object | null): boolean {
  return !object || Object.keys(object).length === 0;
}

/**
 * Safely search an object for a property using a variable amount of property keys.
 * Will check that all needed parts of the object exists, and return undefined if not.
 *
 * @param object The element for which to search for a property.
 * @param key Variable amount of property keys to look for in the given object.
 * @param defaultValue value to return if nothing was found.
 * @return The requested property, or undefined if it doesn't exist.
 */
export function getObjectProperty<T>(object: { [index: string]: any }, key: string, defaultValue?: T): T | undefined {
  if (!object) {
    return defaultValue;
  }

  const keys = key.split('.');
  const currentKey = keys.shift();

  let value = undefined;

  if (!currentKey || !hasOwnProperty(object, currentKey)) {
    return defaultValue;
  } else {
    value = object[currentKey];
  }

  if (keys.length > 0) {
    if (value) {
      return getObjectProperty(value, keys.join('.'), defaultValue);
    } else {
      return defaultValue;
    }
  }

  return value;
}

export function sortObjectKeys<T>(object: { [key: string]: any }): T {
  return Object.keys(object)
    .sort()
    .reduce(
      (obj, key) => {
        obj[key] = object[key];
        return obj;
      },
      {} as { [key: string]: any },
    ) as T;
}

/**
 * Returns the name of the property
 * Copied from https://stackoverflow.com/questions/13612006/get-object-property-name-as-a-string
 */
export function getPropertyName<T extends object>(o: T, expression: (x: { [Property in keyof T]: string }) => string) {
  const res = {} as { [Property in keyof T]: string };
  Object.keys(o).map((k) => (res[k as keyof T] = k));
  return expression(res);
}
