import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { TrackingService } from '@app/tracking/services/tracking.service';

/**
 * A toggle switch with a checkbox aria role.
 * Implements the ARIA description at https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/checkbox_role
 */
@Component({
  standalone: true,
  selector: 'app-toggle-switch',
  template: '',
  styleUrls: ['./toggle-switch.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleSwitchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() trackingPrefix?: string;
  @Input() preventBubbling?: boolean = false;

  @HostBinding('attr.aria-checked') @Input() state = false;
  @HostBinding('attr.aria-disabled') @Input() disabled = false;
  @HostBinding('attr.aria-labelledby') @Input() labelledBy: string | null = null;

  @HostBinding('attr.role') role = 'checkbox';
  @HostBinding('attr.tabindex') tabindex = 0;

  @Output() stateChange = new EventEmitter<boolean>();

  labelElement: HTMLElement | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private changeDetectorRef: ChangeDetectorRef,
    private trackingService: TrackingService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.createLabelClickListener();
  }

  ngOnDestroy(): void {
    this.destroyLabelClickListener();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labelledBy) {
      // Update the label click event listener
      this.destroyLabelClickListener();
      this.createLabelClickListener();
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: PointerEvent): void {
    // Don't react on click events if the switch is located inside its label.
    // In that case, reacting would result in two changes cancelling each other out.
    if (!this.labelElement?.contains(this.elementRef.nativeElement)) {
      this.toggleState(event);
    }
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    if (event.code === 'Space') {
      this.toggleState(event);
    }
  }

  // The use of an arrow function here is important:
  // It binds to `this` making sure the context is still correct when called by the click event
  labelClickCallback = (event: MouseEvent): void => {
    this.toggleState(event);

    // Changes from external events aren't marked for check automatically, so we'll have to help a little
    this.changeDetectorRef.markForCheck();
  };

  /**
   * Sets up a click listener on the `labelled-by` element to mimic the behaviour of native checkboxes.
   */
  createLabelClickListener(): void {
    if (this.labelledBy) {
      this.labelElement = this.document.getElementById(this.labelledBy);

      if (this.labelElement) {
        this.labelElement.addEventListener('click', this.labelClickCallback);
      }
    }
  }

  /**
   * Tears down the current label click event listener.
   */
  destroyLabelClickListener(): void {
    if (this.labelElement) {
      this.labelElement.removeEventListener('click', this.labelClickCallback);
    }
  }

  /**
   * Toggles the current state and emits the new state.
   */
  private toggleState(event: KeyboardEvent | PointerEvent | MouseEvent): void {
    if (this.preventBubbling) {
      event.preventDefault();
    }
    // Don't toggle when disabled
    if (this.disabled) {
      return;
    }

    this.state = !this.state;
    this.stateChange.emit(this.state);
    if (this.trackingPrefix) {
      this.trackingService.trackEvent(`${this.trackingPrefix}-toggle`, { enabled: this.state });
    }
  }
}
