import { CartItem } from '@app/cart/interfaces/cart-item.interface';
import { CartItemRequest } from '@app/cart/interfaces/cart-item-request.interface';

/**
 * Creates an ID based on the type and ID of the given item.
 * Only for use in the Cart store.
 */
export function getInternalCartItemId(item: CartItem): string {
  return `${item.contentType}-${item.contentId}`;
}

/**
 * Creates an ID based on the type and ID of the given item.
 * Only for use in the Cart store.
 */
export function getInternalCartItemRequestId(item: CartItemRequest): string {
  return `${item.contentType}-${item.id}`;
}
