<div class="individual-background-elements">
  <div
    *ngFor="let element of backgroundElements; trackBy: trackByFilename"
    [style.transform]="
      'translate(' +
      (element.renderedX + element.offsetX) +
      'vw, ' +
      (element.renderedY + element.offsetY) +
      'vh)' +
      'scale(' +
      element.scale +
      ')'
    "
    [onSVGLoaded]="onSvgLoaded"
    [inlineSVG]="'backgrounds/individual-items/' + element.filename + '.svg'"
  ></div>
</div>
