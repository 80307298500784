import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { TeleportService } from '@app/teleport/services/teleport.service';
import { TeleportOutletId } from '@app/teleport/teleport-outlet-id';

/**
 * This directive receives content from the `appTeleport` directive wit the same ID.
 * Note: Only one element can be teleported to any given outlet at a time.
 */
@Directive({
  selector: '[appTeleportOutlet]',
  standalone: true,
})
export class TeleportOutletDirective implements OnInit, OnDestroy {
  @Input() appTeleportOutlet!: TeleportOutletId;

  constructor(
    private elementRef: ElementRef,
    private teleportService: TeleportService,
  ) {}

  ngOnInit() {
    this.teleportService.registerOutlet(this.appTeleportOutlet, this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.teleportService.unregisterOutlet(this.appTeleportOutlet);
  }
}
