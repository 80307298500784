import { DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';

import { modalOverlayEnterLeaveAnimation } from '../../modal.animations';
import { ModalService } from '../../modal.service';

/**
 * The outlet for modals.
 * This component is controlled by the modal service and relays global keypress
 * and focus events back to the service for further processing.
 */
@Component({
  selector: 'app-modal-outlet',
  standalone: true,
  template: '',
  styleUrls: ['./modal-outlet.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [modalOverlayEnterLeaveAnimation],
})
export class ModalOutletComponent implements AfterViewInit, OnDestroy {
  /**
   * @ignore
   */
  constructor(
    private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private modalService: ModalService,
    private elementRef: ElementRef,
  ) {
    modalService.displayOverlay$.subscribe((displayOverlay) => {
      if (displayOverlay) {
        this.lockScroll();
        this.setUpFocusHandler();
      } else {
        this.destroyFocusHandler();
      }
    });
  }

  ngAfterViewInit(): void {
    // Set up a dom portal outlet and pass it on to the modal service
    const outlet = new DomPortalOutlet(
      this.elementRef.nativeElement,
      this.componentFactoryResolver,
      this.appRef,
      this.injector,
    );

    this.modalService.setPortalOutlet(outlet);
  }

  ngOnDestroy(): void {
    // Stop any focus handling
    this.destroyFocusHandler();
  }

  /**
   * Inspired by https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
   */
  private lockScroll(): void {
    const scrollY = this.window.scrollY;

    // When the modal is shown, we want a fixed body
    this.document.documentElement.style.overflowY = 'hidden';

    this.document.body.style.setProperty('padding-right', `var(--scrollbar-width)`, 'important');
    this.document.body.style.position = 'fixed';
    this.document.body.style.top = '0';
    this.document.body.style.left = '0';
    this.document.body.style.right = '0';
    this.document.body.style.bottom = '0';
    this.document.body.style.overflow = 'hidden';
    this.document.body.setAttribute('data-scrollY', scrollY.toString());

    this.document.body.scroll({
      left: 0,
      top: scrollY,
    });
    this.document.documentElement.setAttribute('data-scroll-locked', 'true');
  }

  /**
   * Add event listener that traps focus
   */
  private setUpFocusHandler(): void {
    // It would be nicer to use Angular host bindings for the event handling, but angular doesn't support capture instead of bubbling
    this.document.addEventListener('focus', this.focusHandler, true);
  }

  /**
   * Removes event listener that traps focus
   */
  private destroyFocusHandler(): void {
    this.document.removeEventListener('focus', this.focusHandler, true);
  }

  /**
   * Relays focus event on to the modal service.
   */
  private focusHandler = (event: FocusEvent): void => {
    this.modalService.handleFocus(event);
  };

  /**
   * Hides the current modal if the escape button is pressed.
   */
  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    const dropdownOutlets = Array.from(document.querySelectorAll('lru-dropdown-outlet, app-dropdown-outlet'));
    const dropdownFocus = dropdownOutlets.some((outlet) => outlet.matches(':focus-within'));

    if (event.code === 'Escape' && !dropdownFocus) {
      this.modalService.hideCurrentModal();
    }
  }

  /**
   * Hides the current modal if the outlet element is clicked outside of the modal content.
   */
  @HostListener('click', ['$event.target'])
  onClick(element: HTMLElement): void {
    if (element === this.elementRef.nativeElement) {
      this.modalService.hideCurrentModal();
    }
  }
}
