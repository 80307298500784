import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { isHoverableDevice } from '@app/utils/device';
import { Icon } from '@shared-interfaces/icon.interface';
import { isElementInDropdown } from '@shared-utils/dropdown.utils';
import { isElementInModal } from '@shared-utils/modal.utils';
import { createUniqueId } from '@utils/uuid';

import { TooltipDirection } from '../interfaces/tooltip.interface';
import { TooltipService } from '../services/tooltip.service';

/**
 * Ads a tooltip to the element.
 * The tooltip positions itself, so that it will never overflow the viewport.
 * The tooltip can either be triggered by hovering the element or by tabbing to it.
 * Clicking on an element also displays its tooltip, since it will trigger focus the samme way as tabbing.
 *
 * Please note: According to the WAI ARIA role of "tooltip" the tooltip may not contain any interactive elements.
 */
@Directive({
  standalone: true,
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() appTooltip: Icon | string | TemplateRef<any> | undefined | null = '';
  @Input() appTooltipHeading: string = '';
  @Input() appTooltipClass: string = '';
  @Input() appTooltipDirection: TooltipDirection = 'auto';
  @Input() appTooltipDisableTabindex: boolean = false;

  /**
   * Only displays the tooltip if the element is actively hiding some of its content using the `.clamp` utility class.
   */
  @Input() appTooltipIfLineClamped: boolean = false;
  /**
   * Only displays the tooltip if the element has overflow/ellipsis.
   */
  @Input() appTooltipIfEllipsis: boolean = false;

  /**
   * Make the tooltip target accessible by the tab key
   */
  @HostBinding('attr.tabindex') private get tabIndex() {
    return this.isAllowedToShow && !this.appTooltipDisableTabindex ? '0' : null;
  }

  /**
   * Link the target element to the tooltip element
   */
  @HostBinding('attr.aria-describedby') private tooltipElementId = createUniqueId('tooltip');

  private domManipulationTimeoutId: number | null = null;

  private hasFocus = false;
  private hasFocusFromKeyboard = false;
  private hasMouseOver = false;

  private activationDelay = 400;
  private deactivationDelay = 350;

  /**
   * Handles repositioning of the tooltip when the target changes
   */
  private resizeObserver: ResizeObserver;

  constructor(
    private el: ElementRef,
    private tooltipService: TooltipService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.resizeObserver = new ResizeObserver(() => {
      this.updateTooltipPosition();
    });
  }

  get shouldBeVisible(): boolean {
    return this.hasFocusFromKeyboard || this.hasMouseOver;
  }

  get hasContent(): boolean {
    const isAppIcon = this.el.nativeElement.nodeName === 'APP-ICON';
    if (!isHoverableDevice() && !isAppIcon) {
      return false;
    }
    return !!this.appTooltip;
  }

  private get isAllowedToShow(): boolean {
    return (
      this.hasContent &&
      (!this.appTooltipIfLineClamped || this.isLineClamped(this.el.nativeElement)) &&
      (!this.appTooltipIfEllipsis || this.isEllipsis(this.el.nativeElement))
    );
  }

  ngOnDestroy(): void {
    this.clearDomManipulationTimeout();
    this.clearTooltip();
    this.resizeObserver.disconnect();
  }

  @HostListener('focus') onFocus(): void {
    this.hasFocus = true;
  }

  @HostListener('keyup', ['$event']) onKeyup(event: KeyboardEvent): void {
    if (this.hasFocus && event.key === 'Tab') {
      this.hasFocusFromKeyboard = true;
      this.showTooltip();
    }
    if ('class.owl-container') {
      this.hasFocusFromKeyboard = true;
      this.showTooltip();
    }
  }

  @HostListener('blur') onBlur(): void {
    this.hasFocus = false;
    this.hasFocusFromKeyboard = false;
    this.hideTooltip();
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    if (!isHoverableDevice()) {
      return;
    }
    this.hasMouseOver = true;
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.hasMouseOver = false;
    this.hideTooltip();
  }

  @HostListener('document:keydown', ['$event'])
  keypress(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.hasFocus = false;
      this.hasFocusFromKeyboard = false;
      this.hasMouseOver = false;
      this.hideTooltip();
    }
  }

  private isLineClamped = (e: HTMLElement): boolean => {
    return e.offsetHeight < e.scrollHeight;
  };
  private isEllipsis = (e: HTMLElement): boolean => {
    return e.offsetWidth < e.scrollWidth;
  };

  private showTooltip(): void {
    if (!this.isAllowedToShow) {
      return;
    }

    this.resizeObserver.observe(this.el.nativeElement);

    this.updateDomManipulationTimeout(
      window.setTimeout(() => {
        const targetBoundingRect = this.el.nativeElement.getBoundingClientRect() as DOMRect;

        this.tooltipService.addTooltip({
          content: this.appTooltip,
          heading: this.appTooltipHeading,
          className: this.appTooltipClass,
          elementId: this.tooltipElementId,
          direction: this.getDirection(targetBoundingRect),
          targetBoundingRect,
          inModal: isElementInModal(this.el.nativeElement),
          inDropdown: isElementInDropdown(this.el.nativeElement),
        });
      }, this.activationDelay),
    );
  }

  private hideTooltip(): void {
    // Don't hide the tooltip anyway if something else requires it to be visible
    if (this.shouldBeVisible) {
      return;
    }

    this.clearDomManipulationTimeout();

    this.domManipulationTimeoutId = window.setTimeout(() => {
      this.clearTooltip();
    }, this.deactivationDelay);
  }

  private updateTooltipPosition(): void {
    if (!this.shouldBeVisible) {
      return;
    }

    const targetBoundingRect = this.el.nativeElement.getBoundingClientRect() as DOMRect;

    this.tooltipService.updateTooltip(this.tooltipElementId, {
      direction: this.getDirection(targetBoundingRect),
      targetBoundingRect,
    });

    // Force the change detector to react to the update of the tooltip position
    this.changeDetectorRef.detectChanges();
  }

  private getDirection(targetBoundingRect: DOMRect): 'up' | 'down' {
    const y = targetBoundingRect.y + targetBoundingRect.height / 2;

    if (!this.appTooltipDirection || this.appTooltipDirection === 'auto') {
      return y < window.innerHeight / 2 ? 'down' : 'up';
    }

    return this.appTooltipDirection;
  }

  /**
   * Clears the current DOM manipulation timeout and stores the new timeout ID instead.
   */
  private updateDomManipulationTimeout(timeoutId: number): void {
    this.clearDomManipulationTimeout();
    this.domManipulationTimeoutId = timeoutId;
  }

  private clearDomManipulationTimeout(): void {
    if (this.domManipulationTimeoutId) {
      window.clearTimeout(this.domManipulationTimeoutId);
    }
  }

  /**
   * Removes the tooltip from the DOM and disconnects the resize observer.
   */
  private clearTooltip(): void {
    this.resizeObserver.disconnect();
    this.tooltipService.removeTooltip(this.tooltipElementId);
  }
}
