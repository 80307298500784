import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CartItem } from '@app/cart/interfaces/cart-item.interface';
import { CartItemServerInteractionStates } from '@app/cart/interfaces/cart-state.interface';
import { PageTypeLinkPipe } from '@app/page/pipes/page-type-link.pipe';
import { TrackingFunctionsService } from '@app/tracking/services/tracking-functions.service';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import {
  initialServerInteractionState,
  ServerInteractionState,
} from '@core/interfaces/server-interaction-state.interface';
import { ButtonComponent, ButtonSize, ButtonVariant } from '@shared-components/ui/button/button.component';
import { IconButtonComponent } from '@shared-components/ui/icon-button/icon-button.component';
import { LoadingAnimationComponent } from '@shared-components/ui/loading-animation/loading-animation.component';
import { ServerErrorMessageComponent } from '@shared-components/ui/server-error-message/server-error-message.component';
import { Icons } from '@shared-data/icons';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';
import { ServerInteractionIconPipe } from '@shared-pipes/server-interaction-icon.pipe';

import { CartEmptyStateComponent } from '../cart-empty-state/cart-empty-state.component';
import { CartItemComponent } from '../cart-item/cart-item.component';

@Component({
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    TranslateSharedModule,
    ButtonComponent,
    LoadingAnimationComponent,
    IconButtonComponent,
    ServerErrorMessageComponent,
    CartItemComponent,
    CartEmptyStateComponent,
    PageTypeLinkPipe,
    ServerInteractionIconPipe,
  ],
  selector: 'app-cart-presentational',
  templateUrl: './cart-presentational.component.html',
  styleUrls: ['./cart-presentational.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartPresentationalComponent {
  @Input() items: CartItem[] = [];
  @Input() loading: boolean = false;
  @Input() cartServerInteractionState: ServerInteractionState = initialServerInteractionState;
  @Input() emptyCartServerInteractionState: ServerInteractionState = initialServerInteractionState;
  @Input() removeItemServerInteractionStates: CartItemServerInteractionStates = {};

  @Output() removeItem = new EventEmitter<CartItem>();
  @Output() emptyCart = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter<void>();

  public ButtonSize = ButtonSize;
  public ButtonVariant = ButtonVariant;
  public Icons = Icons;
  public ServerInteractionStateType = ServerInteractionStateType;

  constructor(
    private elementRef: ElementRef,
    private trackingFunctionsService: TrackingFunctionsService,
  ) {}

  get isEmpty(): boolean {
    return this.items?.length === 0;
  }

  public focus(): void {
    this.elementRef.nativeElement.focus();
  }

  onEmptyCartClick(): void {
    this.trackingFunctionsService.trackCartEmptyBasket();
    this.emptyCart.emit();
  }

  onDismissClick(routeToAssignContent: boolean): void {
    if (routeToAssignContent) {
      this.trackingFunctionsService.trackCartGoToCreateAssignment();
    }
    this.dismiss.emit();
  }

  onRemoveItem(cartItem: CartItem): void {
    this.trackingFunctionsService.trackCartRemoveItem();
    this.removeItem.emit(cartItem);
  }
}
