import { AsyncPipe, CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { fadeInOutAnimation } from '@shared-animations/fade-in-out.animation';
import { BackgroundElementsComponent } from '@shared-components/background-elements/background-elements.component';
import { SnowflakesComponent } from '@shared-components/snowflakes/snowflakes.component';
import { InlineSVGSharedModule } from '@shared-modules/shared-inline-svg.module';

@Component({
  standalone: true,
  imports: [CommonModule, AsyncPipe, SnowflakesComponent, InlineSVGSharedModule, BackgroundElementsComponent],
  selector: 'app-background-graphics',
  templateUrl: './background-graphics.component.html',
  styleUrls: ['./background-graphics.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAnimation],
})
export class BackgroundGraphicsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() hasLoadedUserData: boolean = false;
  @Input() isTeacher: boolean = false;
  @Input() showGraphics: boolean = false;
  @Input() showPattern: boolean = false;
  @Input() isWinter: boolean = false;
  @Input() backgroundColor?: string;
  @Input() backgroundImageUrl?: string;

  @HostBinding('class.in-container')
  @Input()
  inContainer?: boolean;

  public get userTypeTheme(): 'dotted' | 'square' | undefined {
    return this.hasLoadedUserData ? (this.isTeacher ? 'dotted' : 'square') : undefined;
  }

  public get backgroundColorFallback(): string {
    switch (this.userTypeTheme) {
      case 'dotted':
        return 'var(--color-blue-50)';
      case 'square':
        return 'var(--color-blurple-50)';
      default:
        return 'var(--color-blurple-50)';
    }
  }

  @HostBinding('style.--page-background-color')
  public get pageBackgroundColor(): string {
    return this.backgroundColor || this.backgroundColorFallback;
  }

  @HostBinding('style.--page-background-image-url')
  public get pageBackgroundImageUrl(): string {
    return this.backgroundImageUrl ? `url(${this.backgroundImageUrl})` : '';
  }
  @HostBinding('class.background-image')
  public get pageBackgroundImageProvided() {
    return this.backgroundImageUrl;
  }

  ngOnInit(): void {
    document.body.style.transition = 'background-color var(--transition-duration-medium-slow) ease-in-out';
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      document.body.style.backgroundColor = this.pageBackgroundColor;
    });
  }

  ngOnDestroy(): void {
    document.body.style.transition = '';
  }
}
