import { Injectable } from '@angular/core';
import { GoogleTagManagerEventProperties } from '@app/tracking/interfaces/google-tag-manager-event-properties.interface';
import { TrackingServiceOptions } from '@app/tracking/interfaces/tracking-service-options.interface';
import { GoogleTagManagerService } from '@app/tracking/services/google-tag-manager.service';
import { UserService } from '@app/user/services/user.service';
import { isLocalhost } from '@utils/network';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private options: TrackingServiceOptions = {};

  constructor(
    private googleTagManagerService: GoogleTagManagerService,
    private userService: UserService,
  ) {}

  public initialize(options: TrackingServiceOptions): void {
    this.options = options;

    this.addGoogleTagManager();
  }

  public trackPageView(url: string): void {
    const [pagePath, pageParams] = url.replace(/\#.*$/, '').split('?');

    if (this.useGoogleTagManager) {
      this.googleTagManagerService.pushTag({
        event: 'Pageview',
        pagePath,
        pageParams,
        visitorType: this.userService.userType,
      });
    }
  }

  /**
   * Sends a tracking event to Google
   *
   * The event name, a string, to track the event on in Google tag manager
   * @param event
   *
   * The data we want to use in Google tag manager an object with key/value pairs
   * @param eventData
   */
  public trackEvent(event: string, eventData: GoogleTagManagerEventProperties = {}): void {
    if (isLocalhost()) {
      console.log('track event', ...arguments);
    }

    if (this.useGoogleTagManager) {
      this.googleTagManagerService.pushTag({ ...eventData, event });
    }
  }

  trackEventWithUserType(event: string, properties?: GoogleTagManagerEventProperties): void {
    let obj = {
      visitorType: this.userService.userType,
    };

    if (properties) {
      obj = { ...obj, ...properties };
    }
    this.trackEvent(event, obj);
  }

  addGoogleTagManager(): void {
    if (this.useGoogleTagManager) {
      this.googleTagManagerService.setId(this.options.googleTagManagerId!);
      this.googleTagManagerService.addGtmToDom();
    }
  }

  private get useGoogleTagManager(): boolean {
    return !isLocalhost() && !!this.options.googleTagManagerId;
  }
}
