import { User } from '@app/user/interfaces/user.interface';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { ParsedServerError } from '@core/interfaces/project/parsed-server-error.interface';
import { createAction, props } from '@ngrx/store';

export const loadUser = createAction('[User] Load User', props<{ noLoadingAnimation?: boolean }>());

export const loadUserSuccess = createAction('[User] Load User Success', props<{ user: User }>());

export const validateAndApplyUser = createAction('[User] Validate and apply user', props<{ user: User | null }>());

export const removeUser = createAction('[User] Remove user');

export const renewRefreshToken = createAction('[User] Renew RefreshToken Success');

export const setUserServerInteractionState = createAction(
  '[User] Set user server interaction state',
  props<{ state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const setRenewRefreshTokenServerInteractionState = createAction(
  '[User] Set renew refresh token server interaction state',
  props<{ state: ServerInteractionStateType; error?: ParsedServerError }>(),
);
