import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { ServerInteractionStateType } from '@app/core/enums/server-interaction-state-type.enum';
import { PageTypeLinkPipe } from '@app/page/pipes/page-type-link.pipe';
import { initialSubTeamsForTeam, SubTeam, SubTeamsForTeam } from '@app/teams/interfaces/sub-team.interfaces';
import { Team } from '@app/teams/interfaces/teams.interfaces';
import { TeamsService } from '@app/teams/services/teams.service';
import { TeamsModule } from '@app/teams/teams.module';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { concatLatestFrom } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ButtonVariant } from '@shared-components/ui/button/button.component';
import { DropdownButtonComponent } from '@shared-components/ui/dropdown-button/dropdown-button.component';
import { DropdownVariant } from '@shared-components/ui/dropdown-button/dropdown-button.enum';
import { IconComponent } from '@shared-components/ui/icon/icon.component';
import { LoadingAnimationVariant } from '@shared-components/ui/loading-animation/loading-animation.component';
import { Icons } from '@shared-data/icons';
import { DropDownButtonType } from '@shared-enums/drop-down-button-type.enum';
import { IconSize } from '@shared-enums/icon-size.enum';
import { DropdownComponent } from '@shared-modules/dropdown/components/dropdown/dropdown.component';
import { DropdownService } from '@shared-modules/dropdown/services/dropdown.service';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';
import { GUID } from '@shared-types/guid.type';
import { getObservableValueSync } from '@utils/observables';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
@Component({
  standalone: true,
  imports: [
    TeamsModule,
    NgIf,
    AsyncPipe,
    DropdownButtonComponent,
    PageTypeLinkPipe,
    TranslateSharedModule,
    RouterLink,
    IconComponent,
  ],
  selector: 'app-team-selector-dropdown',
  templateUrl: './team-selector-dropdown.component.html',
  styleUrls: ['./team-selector-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamSelectorDropdownComponent implements OnChanges, OnDestroy {
  @Input() selectedTeamId?: GUID | undefined;
  @Input() selectedSubTeamId?: GUID;

  @Output() selectTeam = new EventEmitter<GUID | undefined | null>();
  @Output() selectSubTeam = new EventEmitter<GUID | undefined | null>();

  @ViewChild('teamDropdown') dropdown?: DropdownComponent;

  public Icons = Icons;
  public IconSize = IconSize;
  public LoadingAnimationVariant = LoadingAnimationVariant;
  public ServerInteractionStateType = ServerInteractionStateType;
  public DropDownButtonType = DropDownButtonType;
  public ButtonVariant = ButtonVariant;
  public DropdownVariant = DropdownVariant;

  public selectedTeamId$: BehaviorSubject<GUID | undefined>;
  public selectedSubTeamId$: BehaviorSubject<GUID | undefined>;
  public loadTeamsServerInteractionState$: Observable<ServerInteractionState>;
  public teams$: Observable<Team[]>;
  public selectedSubTeams$: Observable<SubTeamsForTeam>;
  public selectedTeam$: Observable<Team | undefined>;
  public selectedSubTeam$: Observable<SubTeam | undefined>;
  public selectedTeamTitle$: Observable<string>;
  public selectedSubTeamTitle$: Observable<string>;
  public buttonText$: Observable<string>;
  public navigationDepth$: Observable<number>;
  public globalTeamId$: Observable<GUID | undefined>;
  public teamSubscription: Subscription = new Subscription();

  constructor(
    private dropdownService: DropdownService,
    public teamsService: TeamsService,
    private translateService: TranslateService,
  ) {
    this.selectedTeamId$ = new BehaviorSubject(this.selectedTeamId);
    this.selectedTeam$ = new BehaviorSubject<Team | undefined>(undefined);

    this.selectedSubTeamId$ = new BehaviorSubject(this.selectedSubTeamId);

    this.globalTeamId$ = this.teamsService.globalTeamId$;
    this.loadTeamsServerInteractionState$ = teamsService.loadTeamsServerInteractionState$;
    this.teams$ = teamsService.teams$;

    this.selectedSubTeams$ = this.selectedTeamId$.pipe(
      mergeMap((teamId) => (teamId ? teamsService.getSubTeamsForTeam(teamId) : of(initialSubTeamsForTeam))),
    );

    this.selectedTeam$ = this.selectedTeamId$.pipe(
      mergeMap((teamId) => (teamId ? teamsService.getTeam(teamId) : of(undefined))),
    );

    this.teamSubscription.add(
      this.globalTeamId$.subscribe((teamId) => {
        this.selectedTeamId$.next(teamId);
      }),
    );

    this.selectedSubTeam$ = this.selectedSubTeams$.pipe(
      concatLatestFrom(() => this.selectedSubTeamId$),
      mergeMap(([subTeams, currentSubTeamId]) =>
        of(currentSubTeamId ? subTeams.subTeams.find((subTeam) => subTeam.id === currentSubTeamId) : undefined),
      ),
    );

    this.selectedTeamTitle$ = this.selectedTeam$.pipe(map((team) => team?.title ?? ''));

    this.selectedSubTeamTitle$ = this.selectedSubTeam$.pipe(map((subTeam) => subTeam?.title ?? ''));

    this.buttonText$ = combineLatest([
      this.translateService.get('teams.components.team-selector-dropdown.select-placeholder'),
      this.selectedTeamTitle$,
      this.selectedSubTeamTitle$,
    ]).pipe(
      map(
        ([translation, teamTitle, subTeamTitle]) =>
          [teamTitle, subTeamTitle].filter((title) => title).join(' | ') || translation || '-',
      ),
    );

    this.navigationDepth$ = combineLatest([this.selectedTeamId$, this.selectedSubTeams$]).pipe(
      tap(([teamId, subTeams]) => {
        if (teamId && subTeams.serverInteractionState.state === ServerInteractionStateType.SUCCESS) {
          this.selectTeamInternal(teamId, true);
        }
      }),
      map(([teamId, subTeams]) =>
        teamId &&
        (subTeams.serverInteractionState.state === ServerInteractionStateType.ERROR || subTeams.subTeams.length > 0)
          ? 1
          : 0,
      ),
    );
  }

  public ngOnDestroy(): void {
    this.teamSubscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTeamId) {
      this.selectedTeamId$.next(changes.selectedTeamId.currentValue);
    }

    if (changes.selectedSubTeamId) {
      this.selectedSubTeamId$.next(changes.selectedSubTeamId.currentValue);
    }
  }

  public onTeamClick(teamId: GUID): void {
    this.selectedTeamId$.next(teamId);
    this.teamsService.setGlobalTeam(teamId, true);
  }

  public onSubTeamClick(subTeam: SubTeam): void {
    this.selectSubTeam.emit(subTeam.id);
  }

  public onNavigationIndexChange(newIndex: number): void {
    if (newIndex === 0) {
      this.selectTeamInternal(undefined);
    }
  }

  /**
   * Deselects the current team if on navigation depth 0.
   * Selects the current team if on navigation depth 1.
   * Always deselects the current sub team and closes the dropdown.
   */
  public onCloseClick(navigationDepth: number): void {
    this.selectTeamInternal(navigationDepth === 0 ? undefined : getObservableValueSync(this.selectedTeamId$), true);
    this.selectSubTeam.emit(undefined);
    this.dropdownService.closeDropdown();
  }

  public isLoadingSubTeams(teamId: GUID): boolean {
    return (
      getObservableValueSync<SubTeamsForTeam>(this.teamsService.getSubTeamsForTeam(teamId, true)).serverInteractionState
        .state === ServerInteractionStateType.LOADING
    );
  }

  private selectTeamInternal(teamId: GUID | undefined | null, closeDropdown = false): void {
    this.selectTeam.emit(teamId);

    if (closeDropdown) {
      this.dropdownService.closeDropdown();
    }
  }
}
