import { Injectable } from '@angular/core';
import { HtmlService } from '@shared-services/html.service';
import { getHttpsUrl } from '@utils/umbraco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UmbSectionTypeEnum } from '../enums/page.umbraco.enum';
import { ContentList } from '../interfaces/content-list.interface';
import { UmbCardList_AKA_GridEditor } from '../interfaces/umbraco/umb-card-list.interface';
import { CallToActionMapper } from './call-to-action.mapper';

@Injectable({
  providedIn: 'root',
})
export class ContentListMapper {
  constructor(
    private htmlService: HtmlService,
    private callToActionMapper: CallToActionMapper,
  ) {}

  mapContentList(item: UmbCardList_AKA_GridEditor): Observable<ContentList | undefined> {
    return this.callToActionMapper.mapCallToActionButtonsFromApi_v1(item).pipe(
      map((buttons) => ({
        // Filter out any IDs that doesn't have the length of a GUID to avoid API errors
        contentCollection: (item.contentCollection ?? []).filter((id) => id.length === 36),
        type: UmbSectionTypeEnum.ContentList,
        title: item.heading || item.header,
        useSlider: item.carousel || (item.body?.length !== undefined && item.body?.length > 0),
        maxSlidesPerView: 5,
        removeTileFromList: true,
        description: this.htmlService.fixHtml(item.body),
        showAllUrl: getHttpsUrl(item.url),
        messageWhenNoItemsShow: true,
        buttons: buttons,
      })),
    );
  }
}
