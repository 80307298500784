import { Badge } from '@shared-interfaces/badge.interface';

export const Badges = {
  UNDEFINED: { path: 'badges/placeholder-badge' } as Badge,

  // Badge type ONE-OFF
  ARCHIMEDES: { path: 'badges/one-off/archimedes' } as Badge,
  BRONZE_MEDAL: { path: 'badges/one-off/bronze-medal' } as Badge,
  BUTTERFLY: { path: 'badges/one-off/butterfly' } as Badge,
  CHRISTMAS_ELF: { path: 'badges/one-off/christmas-elf' } as Badge,
  CURIE: { path: 'badges/one-off/curie' } as Badge,
  EASTER_CHICKEN: { path: 'badges/one-off/easter-chicken' } as Badge,
  EINSTEIN: { path: 'badges/one-off/einstein' } as Badge,
  EULER: { path: 'badges/one-off/euler' } as Badge,
  FASTELAVN: { path: 'badges/one-off/fastelavn' } as Badge,
  FATTER_MINUS: { path: 'badges/one-off/fatter-minus' } as Badge,
  FESSOR_BIRTHDAY: { path: 'badges/one-off/fessor-birthday' } as Badge,
  GOLD_DIGGER: { path: 'badges/one-off/gold-digger' } as Badge,
  GOLD_FEVER: { path: 'badges/one-off/gold-fever' } as Badge,
  GOLD_MEDAL: { path: 'badges/one-off/gold-medal' } as Badge,
  GOLD_MINE: { path: 'badges/one-off/gold-mine' } as Badge,
  GOLD_SHOWER: { path: 'badges/one-off/gold-shower' } as Badge,
  MORNING_PERSON: { path: 'badges/one-off/morning-person' } as Badge,
  NIGHT_OWL: { path: 'badges/one-off/night-owl' } as Badge,
  PETER_PLUS: { path: 'badges/one-off/peter-plus' } as Badge,
  PI_DAY: { path: 'badges/one-off/pi-day' } as Badge,
  PYTHAGORAS: { path: 'badges/one-off/pythagoras' } as Badge,
  SELF_TRAINER: { path: 'badges/one-off/self-trainer' } as Badge,
  SILVER_MEDAL: { path: 'badges/one-off/silver-medal' } as Badge,
  TROPHY: { path: 'badges/one-off/trophy' } as Badge,
  WEEKEND_WARRIOR: { path: 'badges/one-off/weekend-warrior' } as Badge,
  WELCOME: { path: 'badges/one-off/welcome' } as Badge,
  ZOMBIE: { path: 'badges/one-off/zombie' } as Badge,

  // Badge type SUPERTRAINER
  EXPERT: { path: 'badges/supertrainer/expert' } as Badge,
  EMBER: { path: 'badges/supertrainer/ember' } as Badge,
  EXERCISE_BREAKER: { path: 'badges/supertrainer/exercise-breaker' } as Badge,
  EXERCISE_DESTROYER: { path: 'badges/supertrainer/exercise-destroyer' } as Badge,
  EXERCISE_EXTERMINATOR: { path: 'badges/supertrainer/exercise-exterminator' } as Badge,
  EXERCISE_GRINDER: { path: 'badges/supertrainer/exercise-grinder' } as Badge,
  FIREFLY: { path: 'badges/supertrainer/firefly' } as Badge,
  FIRE_SPIRIT: { path: 'badges/supertrainer/fire-spirit' } as Badge,
  FIRE: { path: 'badges/supertrainer/fire' } as Badge,
  FLAME: { path: 'badges/supertrainer/flame' } as Badge,
  GURU: { path: 'badges/supertrainer/guru' } as Badge,
  MASTER: { path: 'badges/supertrainer/master' } as Badge,
  MAXI_TRAINER: { path: 'badges/supertrainer/maxi-trainer' } as Badge,
  MEGA_TRAINER: { path: 'badges/supertrainer/mega-trainer' } as Badge,
  MENTOR: { path: 'badges/supertrainer/mentor' } as Badge,
  MINI_TRAINER: { path: 'badges/supertrainer/mini-trainer' } as Badge,
  PASTIME: { path: 'badges/supertrainer/pastime' } as Badge,
  PHOENIX: { path: 'badges/supertrainer/phoenix' } as Badge,
  SUPER_TRAINER: { path: 'badges/supertrainer/super-trainer' } as Badge,
  TIME_MACHINE: { path: 'badges/supertrainer/time-machine' } as Badge,
  TIME_TAKER: { path: 'badges/supertrainer/time-taker' } as Badge,

  // Badge type TOPICS
  ADDITION: { path: 'badges/topic/addition' } as Badge,
  ROUNDING_OF_NUMBERS: { path: 'badges/topic/rounding-of-numbers' } as Badge,
  ALGEBRA: { path: 'badges/topic/algebra' } as Badge,
  QUADRATIC_POLYNOMIALS: { path: 'badges/topic/quadratic-polynomials' } as Badge,
  AREA: { path: 'badges/topic/area' } as Badge,
  FRACTIONS_AND_FRACTIONAL_ARITHMETIC_RULES: {
    path: 'badges/topic/fractions-and-fractional-arithmetic-rules',
  } as Badge,
  ACADEMIC_READING_TEXT_EXERCISES: { path: 'badges/topic/academic-reading-text-exercises' } as Badge,
  ANGLES: { path: 'badges/topic/angles' } as Badge,
  BASIC_GEOMETRIC_CONCEPTS: { path: 'badges/topic/basic-geometric-concepts' } as Badge,
  CHANCE_AND_PROBABILITY: { path: 'badges/topic/chance-and-probability' } as Badge,
  CIRCLES: { path: 'badges/topic/circles' } as Badge,
  CIRCUMREFERENCE: { path: 'badges/topic/circumreference' } as Badge,
  COORDINATE_SYSTEM: { path: 'badges/topic/coordinate-system' } as Badge,
  DIVISION: { path: 'badges/topic/division' } as Badge,
  EQUATIONS: { path: 'badges/topic/equations' } as Badge,
  EXPONENTIAL_GROWTH: { path: 'badges/topic/exponential-growth' } as Badge,
  FRACTIONS_DECIMALS_AND_PERCENTAGES: { path: 'badges/topic/fractions-decimals-and-percentages' } as Badge,
  FUNCTIONS: { path: 'badges/topic/functions' } as Badge,
  INEQUALITIES: { path: 'badges/topic/inequalities' } as Badge,
  LENGTH_MEASUREMENT: { path: 'badges/topic/length-measurement' } as Badge,
  LINEAR_CORRELATIONS: { path: 'badges/topic/linear-correlations' } as Badge,
  MONEY_ECONOMY_AND_CURRENCY: { path: 'badges/topic/money-economy-and-currency' } as Badge,
  MULTIPLICATION: { path: 'badges/topic/multiplication' } as Badge,
  PATTERNS_AND_SEQUENCE_OF_NUMBERS: { path: 'badges/topic/patterns-and-sequence-of-numbers' } as Badge,
  POWERS: { path: 'badges/topic/powers' } as Badge,
  PERCENTAGE_CALCULATION: { path: 'badges/topic/percentage-calculation' } as Badge,
  REDUCTION_WITH_NUMBERS: { path: 'badges/topic/reduction-with-numbers' } as Badge,
  SCALE_RATIO: { path: 'badges/topic/scale-ratio' } as Badge,
  SPATIAL_DIMENSIONS: { path: 'badges/topic/spatial-dimensions' } as Badge,
  STATISTICS: { path: 'badges/topic/statistics' } as Badge,
  SUBTRACTION: { path: 'badges/topic/subtraction' } as Badge,
  SURFACE_MEASUREMENTS: { path: 'badges/topic/surface-measurements' } as Badge,
  TIME: { path: 'badges/topic/time' } as Badge,
  TRIANGLES: { path: 'badges/topic/triangles' } as Badge,
  TRIGONOMETRY: { path: 'badges/topic/trigonometry' } as Badge,
  VOLUME: { path: 'badges/topic/volume' } as Badge,
  UNDERSTANDING_OF_NUMBERS: { path: 'badges/topic/understanding-of-numbers' } as Badge,
  WEIGHT: { path: 'badges/topic/weight' } as Badge,
};
