import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { BadgeType } from '@app/achievements-page/enums/achievements-page.enums';
import { AchievementsBadgesService } from '@app/achievements-page/services/achievements-badges.service';
import { AssignedAssignmentProgressState } from '@app/assignments-shared/assignments-shared.enums';
import { TranslateService } from '@ngx-translate/core';
import { ProgressCircleComponent } from '@shared-components/progress-circle/progress-circle.component';
import { DefaultBadge, OneOffBadge } from '@shared-interfaces/badge.interface';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';

@Component({
  selector: 'app-badge',
  standalone: true,
  imports: [CommonModule, ProgressCircleComponent, TranslateSharedModule],
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() achieved: boolean = false;
  @Input() oneOff: boolean = false;
  @Input() badgeName: string = '';
  @Input() badge?: OneOffBadge | DefaultBadge;

  AssignedAssignmentProgressState = AssignedAssignmentProgressState;

  BadgeType = BadgeType;

  public svgSize = 100;
  public progressStrokeWidth = 8;

  public get svgViewBox(): string {
    return `0 0 ${this.svgSize} ${this.svgSize}`;
  }

  public get showProgressBackground(): boolean {
    return !this.oneOff && this.badge?.achievedAt === null;
  }

  public get showProgress(): boolean {
    let badge: DefaultBadge = this.badge as DefaultBadge;

    if (!!badge?.currentAmount && !this.oneOff && badge?.achievedAt === null) {
      if (badge?.currentAmount > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public get targetAmount(): number {
    let badge: DefaultBadge = this.badge as DefaultBadge;

    if (!!badge?.targetAmount) {
      return badge?.targetAmount;
    } else {
      return 0;
    }
  }

  public get currentAmount(): number {
    let badge: DefaultBadge = this.badge as DefaultBadge;

    if (!!badge?.currentAmount) {
      return badge?.currentAmount;
    } else {
      return 0;
    }
  }

  constructor(
    private achievementsBadgesService: AchievementsBadgesService,
    private translate: TranslateService,
  ) {}

  public get badgePath(): string {
    return `assets/svg/${this.achievementsBadgesService.getBadge(this.badgeName).path}.svg`;
  }

  public get badgeAltText(): string {
    const badgeAltText = this.badge?.titleKey ? this.translate.instant(this.badge?.titleKey) : this.badgeName;
    return `Badge: ${badgeAltText}`;
  }

  public get badgeNewAltText(): string {
    const badgeNewAltText = this.badge?.titleKey ? this.translate.instant(this.badge?.titleKey) : this.badgeName;
    return `${this.translate.instant('achievement.new-badge-achieved')}: ${badgeNewAltText}`;
  }
}
