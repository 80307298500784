import { AnimationEvent } from '@angular/animations';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ZIndexService } from '@app/z-index/services/z-index.service';
import { scrollTo } from '@shared-utils/scroll.utils';
import { getLastItemFromArray } from '@utils/array';
import { focusableElements } from '@utils/query-selectors';
import { v4 as uuidv4 } from 'uuid';

import { modalEnterLeaveAnimation, modalOverlayEnterLeaveAnimation } from '../../modal.animations';
import { ModalService } from '../../modal.service';

/**
 * A component for displaying modals.
 * The content passed will be the content of the modal.
 * The visibility is controlled by the `isVisible` attribute.
 * If the modal is hidden (or displayed) by user interaction an `isVisibleChange` event will be fired.
 * The component isn't rendered in place, but moved to the <app-modal-outlet> component automatically to prevent issues with semantics and style bleeding.
 */
@Component({
  selector: 'app-base-modal',
  standalone: true,
  imports: [CommonModule, PortalModule],
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [modalEnterLeaveAnimation, modalOverlayEnterLeaveAnimation],
})
export class BaseModalComponent implements AfterViewInit, OnDestroy, OnChanges {
  /**
   * Hides scrollbar when false
   * Used to ensure scrollbars isn't shown while it plays transition when showing/hiding modal
   */
  overflowVisible = false;

  @Input() spacingFromWindow?: string;

  @Input() width?: string;

  @Input() height?: string;

  @Input() variant?: string;

  /**
   * Should the modal be visible to begin with?
   * This is to make it possible to show modal without user interaction
   */
  @Input() isVisible = false;

  /**
   * Emits modal visibility changes so the place that contains the modal can run custom logic when it happens
   */
  @Output() isVisibleChange = new EventEmitter<boolean>();

  /**
   * Emits when modal is fully shown or hidden so the place that contains the modal can run custom logic when it happens
   */
  @Output() animationEnded = new EventEmitter<boolean>();

  /**
   * Used by the modal service to get a reference of the internal modal portal.
   */
  @ViewChild(CdkPortal) public readonly portal?: CdkPortal;

  /**
   * Used to trap focus logic
   */
  @ViewChild('modalContent') private modalContent: ElementRef | undefined;

  /**
   * A reference to the element in focus when the modal was displayed.
   * Used to restore focus when the modal is hidden.
   */
  private previouslyFocusedElement: Element | null = null;

  /**
   * A reference to the last value we reacted to.
   */
  private lastReactionValue: boolean = false;

  /**
   * A reference to the last focus event target. Used to prevent unnecessary focus jumps.
   */
  private lastFocusEventTarget: EventTarget | null = null;

  /** unique id for this component */
  private uuid = uuidv4();

  /** z-index for dropdown content */
  public readonly zIndex$ = this.zIndexService.item$(this.uuid);

  /**
   * @ignore
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private zIndexService: ZIndexService,
  ) {}

  ngAfterViewInit(): void {
    // Make sure the correct state is reflected in the modal service
    this.reactOnVisibilityChange(this.isVisible);
  }

  ngOnDestroy(): void {
    // Hide the modal to prevent it from getting stuck in the modal outlet
    this.setVisibility(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isVisible) {
      if (changes.isVisible.currentValue !== changes.isVisible.previousValue) {
        // this.isVisibleChange.emit(changes.isVisible.currentValue);

        // The portal doesn't yet exist when ngOnChanges is called the first time
        if (this.portal) {
          this.reactOnVisibilityChange(changes.isVisible.currentValue);
        }
      }
    }
  }

  /**
   * Hides the modal when the wrapper is clicked
   */
  public onWrapperClick(event: MouseEvent): void {
    // Only react to clicks on the actual wrapper to prevent clicks inside the modal from bubbling through
    if (event.target === event.currentTarget) {
      this.overflowVisible = false;
      this.cdr.detectChanges();
      this.setVisibility(false);
    }
  }

  /**
   * Sets a new visibility and emits a change event if necessary.
   */
  public setVisibility(visible: boolean): void {
    const wasAlreadyVisible = this.isVisible === visible;

    this.isVisible = visible;
    if (!wasAlreadyVisible) {
      this.isVisibleChange.emit(visible);
    }

    this.reactOnVisibilityChange(visible);
  }

  /**
   * Traps focus within the modal as long as it's visible.
   * Called from the modal service.
   */
  public trapFocus(eventTarget: EventTarget): void {
    const dropdownOutlets = Array.from(document.querySelectorAll('lru-dropdown-outlet, app-dropdown-outlet'));
    const isValidFocusElement =
      this.modalContent?.nativeElement.contains(eventTarget) ||
      dropdownOutlets.some((outlet) => outlet.contains(eventTarget as HTMLElement));

    if (!isValidFocusElement) {
      if (eventTarget instanceof HTMLElement && eventTarget.classList.contains('focus-trap')) {
        if (eventTarget.classList.contains('focus-trap-begin')) {
          // If no timeout is set, we're constantly brought back to the last focus item for some strange reason
          setTimeout(() => {
            this.focusOnLastDescendant();
          });
        } else if (eventTarget.classList.contains('focus-trap-end')) {
          this.focusOnFirstDescendant();
        }
      } else if (this.lastFocusEventTarget === eventTarget) {
        return;
      }

      this.lastFocusEventTarget = eventTarget;
      this.focusOnModal();
    }
  }

  /**
   * Function will get triggered when modal is fully shown or hidden
   * It will get called regardless of whether transitions are enabled or not
   * @param event event with info about the animation
   */
  public animationDone(event: AnimationEvent): void {
    this.overflowVisible = this.isVisible;
    if (!this.isVisible) {
      this.unlockScroll();
    }

    this.cdr.detectChanges();
    this.animationEnded.emit(event.toState === null);
  }

  /**
   * Inspired by https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
   */
  private unlockScroll(): void {
    const scrollY = this.document.body.dataset.scrolly;

    this.document.documentElement.style.overflowY = '';

    this.document.body.style.paddingRight = '';
    this.document.body.style.position = '';
    this.document.body.style.top = '';
    this.document.body.style.left = '';
    this.document.body.style.right = '';
    this.document.body.style.bottom = '';
    this.document.body.style.overflowY = '';
    this.document.body.removeAttribute('data-scrollY');
    scrollTo(parseInt(scrollY || '0'), 'auto');
    this.document.documentElement.removeAttribute('data-scroll-locked');
  }

  /**
   * Reacts on all visibility changes:
   * - Relays the current state to the modal service.
   * - Sets focus on the correct element.
   */
  private reactOnVisibilityChange(visible: boolean): void {
    const inStack = this.modalService.isModalInVisibleStack(this);

    if (visible) {
      this.zIndexService.setItem(this.uuid);
    } else {
      this.zIndexService.unsetItem(this.uuid);
    }

    //console.log('store modal visibility', this, visible);
    this.modalService.storeModalVisibility(this, visible);

    if (visible === this.lastReactionValue) {
      return;
    }

    this.lastReactionValue = visible;

    if (visible) {
      this.previouslyFocusedElement = this.document.activeElement;
    }

    if (inStack && !visible) {
      setTimeout(() => {
        this.focusOnPreviousElement();
      });
    } else if (!inStack && visible) {
      setTimeout(() => {
        this.focusOnModal();
      });
    }
  }

  /**
   * Sets focus on the element that had focus when the modal was displayed.
   */
  private focusOnPreviousElement(): void {
    if (this.previouslyFocusedElement instanceof HTMLElement) {
      this.previouslyFocusedElement.focus();
    }
  }

  /**
   * Sets focus on the most relevant element in the modal.
   */
  private focusOnModal(): void {
    const modalElement = this.modalContent?.nativeElement;

    if (!modalElement) {
      return;
    }

    // Try to find an element with the autofocus attribute
    const autofocusElement = modalElement.querySelector('[autofocus]');

    if (autofocusElement) {
      autofocusElement.focus();
      return;
    }

    // Try to find any focusable element
    modalElement.querySelector(focusableElements)?.focus();
  }

  /**
   * Sets focus on the first focusable element in the modal.
   */
  private focusOnFirstDescendant(): void {
    this.modalContent?.nativeElement.querySelector(focusableElements)?.focus();
  }

  /**
   * Sets focus on the last focusable element in the modal.
   */
  private focusOnLastDescendant(): void {
    const lastElement = getLastItemFromArray(
      this.modalContent?.nativeElement.querySelectorAll(focusableElements) || [],
    );

    if (lastElement instanceof HTMLElement) {
      lastElement.focus();
    }
  }
}
