import { Injectable } from '@angular/core';
import { CartState } from '@app/cart/interfaces/cart-state.interface';
import { HomeworkApiService } from '@app/core/services/homework-api.service';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { shouldLoadData } from '@core/utils/server-interaction-state.utils';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, switchMap } from 'rxjs/operators';

import * as CartActions from './cart.actions';
import * as CartSelectors from './cart.selectors';

@Injectable()
export class CartEffects {
  fetchCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.fetchCart),
      exhaustMap(() => {
        this.cartStore.dispatch(
          CartActions.setCartServerInteractionState({ state: ServerInteractionStateType.LOADING }),
        );

        return this.homeworkApiService.fetchCart().pipe(
          switchMap((cart) => {
            return [
              CartActions.setCartItems({ items: cart.items }),
              CartActions.setCartServerInteractionState({ state: ServerInteractionStateType.SUCCESS }),
            ];
          }),
          catchError((error) =>
            of(CartActions.setCartServerInteractionState({ state: ServerInteractionStateType.ERROR, error })),
          ),
        );
      }),
    );
  });

  fetchCartIfNecessary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.fetchCartIfNecessary),
      concatLatestFrom(() => [this.cartStore.select(CartSelectors.selectCartServerInteractionState)]),
      exhaustMap(([action, serverInteractionState]) => {
        if (shouldLoadData(serverInteractionState.state)) {
          return [CartActions.fetchCart()];
        }

        return [];
      }),
    ),
  );

  emptyCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.emptyCart),
      exhaustMap(() => {
        this.cartStore.dispatch(
          CartActions.setEmptyCartServerInteractionState({ state: ServerInteractionStateType.LOADING }),
        );

        return this.homeworkApiService.emptyCart().pipe(
          switchMap((cart) => {
            return [
              CartActions.setCartItems({ items: [] }),
              CartActions.setEmptyCartServerInteractionState({ state: ServerInteractionStateType.SUCCESS }),
            ];
          }),
          catchError((error) =>
            of(CartActions.setEmptyCartServerInteractionState({ state: ServerInteractionStateType.ERROR, error })),
          ),
        );
      }),
    );
  });

  addItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.addItem),
      exhaustMap((action) => {
        this.cartStore.dispatch(
          CartActions.setAddToCartServerInteractionState({
            item: action.item,
            state: ServerInteractionStateType.LOADING,
          }),
        );

        return this.homeworkApiService.addItem(action.item).pipe(
          switchMap((cart) => {
            return [
              CartActions.setCartItems({ items: cart.items }),
              CartActions.setAddToCartServerInteractionState({
                item: action.item,
                state: ServerInteractionStateType.SUCCESS,
              }),
            ];
          }),
          catchError((error) => [
            CartActions.setAddToCartServerInteractionState({
              item: action.item,
              state: ServerInteractionStateType.ERROR,
              error,
            }),
          ]),
        );
      }),
    );
  });

  removeItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.removeItem),
      exhaustMap((action) => {
        this.cartStore.dispatch(
          CartActions.setRemoveFromCartServerInteractionState({
            item: action.item,
            state: ServerInteractionStateType.LOADING,
          }),
        );

        return this.homeworkApiService.removeItem(action.item).pipe(
          switchMap((cart) => {
            return [
              CartActions.setCartItems({ items: cart.items }),
              CartActions.setRemoveFromCartServerInteractionState({
                item: action.item,
                state: ServerInteractionStateType.SUCCESS,
              }),
            ];
          }),
          catchError((error) => [
            CartActions.setRemoveFromCartServerInteractionState({
              item: action.item,
              state: ServerInteractionStateType.ERROR,
              error,
            }),
          ]),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private homeworkApiService: HomeworkApiService,
    private cartStore: Store<CartState>,
  ) {}
}
