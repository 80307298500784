import { AssignedAssignment } from './interfaces/assigned-assignment.interface';

export function mapAssignedAssignmentResponseToAssignedAssignment(
  assignedAssignmentResponse: AssignedAssignment,
): AssignedAssignment {
  return {
    ...assignedAssignmentResponse,
    startDate: new Date(assignedAssignmentResponse.startDate),
    endDate: new Date(assignedAssignmentResponse.endDate),
  };
}
