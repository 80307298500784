import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ButtonComponent, ButtonSize, ButtonVariant } from '@shared-components/ui/button/button.component';

@Component({
  selector: 'app-modal-buttons',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './modal-buttons.component.html',
  styleUrls: ['./modal-buttons.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalButtonsComponent {
  protected readonly ButtonVariant = ButtonVariant;
  protected readonly ButtonSize = ButtonSize;
}
