import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import * as CoreSelectors from '@app/core/store/core.selectors';
import { CoreState } from '@app/core/store/interfaces/core-state.interface';
import { TrackingService } from '@app/tracking/services/tracking.service';
import { UmbracoApiService } from '@core/services/umbraco-api.service';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { pageType } from '../page.types';
import { NavbarService } from '../services/navbar.service';
import { PageService } from '../services/page.service';
import { SeoService } from '../services/seo.service';

@Injectable({
  providedIn: 'root',
})
export class RouteHasPage {
  constructor(
    private coreStore: Store<CoreState>,
    private pageService: PageService,
    private seoService: SeoService,
    private trackingService: TrackingService,
    private umbracoApiService: UmbracoApiService,
    private navbarService: NavbarService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const pageId = this.pageService.pageId; // || (route.data.customRoute && route.data.pageId) ? route.data.pageId : null;
    if (pageId) {
      const pageUrl = this.pageService.pageUrl;

      if (pageUrl) {
        this.trackingService.trackPageView(pageUrl);
        this.navbarService.setCurrentUrl(pageUrl);
      }
      this.coreStore.select(CoreSelectors.selectPortalId).subscribe((portalId) => {
        this.umbracoApiService
          .getMetaData(pageId, portalId)
          .pipe(take(1))
          .subscribe((metaData) => {
            this.seoService.setMetaData(metaData);
          });
      });
      return true;
    } else {
      const page: pageType = 'error-503';

      if (route.data.pageType === page) {
        return true;
      } else {
        this.pageService.showError(window.location.pathname, 404);
      }

      return false;
    }
  }
}
