<ng-container
  *ngIf="{
    currentUrl: (navbarService.currentUrl$ | async)!,
    navbar: (coreService.navbar$ | async)!,
    embedVideo: (embedVideo$ | async)!,
    isLoggedIn: (userService.isLoggedIn$ | async)!
  } as sub"
>
  <ul>
    <ng-container *ngFor="let item of sub.navbar">
      <ng-container [ngSwitch]="item.type">
        <ng-container *ngSwitchCase="'dropdown'">
          <li>
            <ng-container [ngSwitch]="navType">
              <ng-container *ngSwitchCase="NavigationType.MEGAMENU">
                <span class="item" [appTooltip]="item.tooltip!">{{ item.title }}</span>
                <ng-container [ngTemplateOutlet]="dropDownContent" [ngTemplateOutletContext]="{ $implicit: item }">
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <app-panel-button
                  [buttonType]="DropDownButtonType.BASIC"
                  [variant]="ButtonVariant.PRIMARY"
                  [active]="false"
                  [buttonText]="item.title"
                  [class.active-link]="isDropdownActive(item.items)"
                  class="item"
                  [tooltip]="item.tooltip"
                >
                  <ng-container [ngTemplateOutlet]="dropDownContent" [ngTemplateOutletContext]="{ $implicit: item }">
                  </ng-container>
                </app-panel-button>
              </ng-container>
            </ng-container>
          </li>
        </ng-container>
        <ng-container *ngSwitchCase="'embed-video'">
          <li>
            <a [appTooltip]="item.tooltip!" (click)="onShowEmbedVideo(item, $event)" class="item" tabindex="0">
              {{ item.title }}
            </a>
          </li>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container [ngTemplateOutlet]="regularContent" [ngTemplateOutletContext]="{ $implicit: item }">
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>
  <app-modal
    *ngIf="sub.embedVideo"
    [isVisible]="!!sub.embedVideo"
    (isVisibleChange)="onClickEmbedVideoOverlay()"
    [width]="'unset'"
  >
    <div [innerHtml]="sub.embedVideo.embed"></div>
  </app-modal>
</ng-container>

<ng-template #dropDownContent let-item>
  <ul>
    <li *ngFor="let child of item.items">
      <a
        *ngIf="child.type === 'link-content' || child.type === 'internal-module'"
        class="sub-item selectable"
        routerLink="{{ child.url }}"
        [class.active-link]="isLinkActive(child)"
        tabindex="0"
        [appTooltip]="child.tooltip!"
        [attr.data-testid]="'headernavbar-' + item.pageType + '-link'"
      >
        <span>{{ child.title }}</span>
      </a>
      <a
        *ngIf="child.type === 'link-external'"
        class="sub-item selectable"
        target="{{ child.target }}"
        rel="noopener"
        href="{{ child.url }}"
        tabindex="0"
        [appTooltip]="child.tooltip!"
      >
        <span>{{ child.title }}</span>
      </a>
    </li>
  </ul>
</ng-template>
<ng-template #regularContent let-item>
  <li>
    <a
      *ngIf="item.type === 'link-content' || item.type === 'internal-module'"
      class="item"
      routerLink="{{ item.url }}"
      [class.active-link]="isLinkActive(item)"
      tabindex="0"
      [appTooltip]="item.tooltip!"
      [attr.data-testid]="'headernavbar-' + item.pageType + '-link'"
    >
      {{ item.title }}
    </a>
    <a
      *ngIf="item.type === 'link-external'"
      class="item"
      target="{{ item.target }}"
      rel="noopener"
      href="{{ item.url }}"
      tabindex="0"
      [appTooltip]="item.tooltip!"
      >{{ item.title }}</a
    >
  </li>
</ng-template>
