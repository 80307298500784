import { IconSize } from '@shared-enums/icon-size.enum';
import { Icon } from '@shared-interfaces/icon.interface';

/**
 * Returns the SVG path for the given icon.
 * If the requested icon size isn't available, the nearest size will be returned.
 */
export function getIconPath(icon: Icon, size?: IconSize): string {
  if (icon.sizes) {
    let preferredSize: number = size || IconSize.DEFAULT;

    // Adjust the preferred size to the nearest available size if not present in the sizes array
    if (!icon.sizes.includes(preferredSize)) {
      preferredSize = icon.sizes.reduce((a, b) => (Math.abs(b - preferredSize) < Math.abs(a - preferredSize) ? b : a));
    }

    // Return the nearest size
    return `icons/${preferredSize}/${icon.path}.svg`;
  }

  // Just return the path
  return `icons/${icon.path}.svg`;
}

/**
 * Returns true if the given data represents an icon
 */
export function isAnIcon(obj: any): obj is Icon {
  return !!obj && typeof obj === 'object' && 'path' in obj && 'sizes' in obj;
}
