import { NgModule } from '@angular/core';
import { ContentEffects } from '@app/content/store/content.effects';
import { contentFeatureKey, contentReducer } from '@app/content/store/content.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [StoreModule.forFeature(contentFeatureKey, contentReducer), EffectsModule.forFeature([ContentEffects])],
})
export class ContentModule {}
