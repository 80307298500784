<div class="empty-state">
  <h2 class="empty-state-heading heading-2">
    {{ 'cart.components.cart-empty-state.empty-state-heading' | translate }}
  </h2>
  <img class="empty-state-image" src="/assets/png/cart-empty-state.png" alt="" />
  <p *ngIf="allowNavigateToFindContent" class="empty-state-message">
    {{ 'cart.components.cart-empty-state.empty-state-message' | translate }}
  </p>
</div>
<div class="buttons-wrapper">
  <app-button
    *ngIf="allowNavigateToFindContent"
    class="navigate-to-content-button"
    [routerLink]="'mf20-find-content' | pageTypeLink"
    (click)="onDismissClick()"
  >
    {{ 'cart.components.cart-empty-state.navigate-to-content-button' | translate }}
  </app-button>
</div>
