<button
  #button
  class="button"
  [title]="buttonTitle"
  [class]="dropdownVariantClassName + ' ' + selectionType"
  [class.active]="isActive"
  [disabled]="disabled"
  [class.loading]="loading"
  [appTooltip]="tooltip"
  [appTooltipDirection]="tooltipDirection"
  (click)="toggledropDown()"
>
  <div class="selected-container button-content">
    <app-loading-animation *ngIf="loading" class="button-loading-animation" @loadingEnterLeave></app-loading-animation>
    <span class="button-text" [class.button-text-hide-on-resize]="removeTextOnResize">{{ buttonText }}</span>
    <app-icon [icon]="isActive ? Icons.CHEVRON_UP : Icons.CHEVRON_DOWN" [size]="IconSize.DEFAULT"></app-icon>
  </div>
</button>
<div *ngIf="isActive" class="dropdown-wrapper">
  <div class="modal-content" #dropdownContent [class]="leftAligned ? 'left-aligned' : 'right-aligned'">
    <ul
      #dropDown
      [class]="dropdownVariantClassName + ' ' + selectionType + ' ' + renderDirection"
      [style.--dropdown-content-max-height]="allowedHeightInRenderDirection + 'px'"
    >
      <li *ngIf="showAddRemoveAllButtons" class="add-remove-all-button">
        <button
          tabindex="-1"
          class="nonselectable"
          (click)="addRemoveAll()"
          (focus)="suggestionsOnFocus(0)"
          (focusout)="suggestionsOnFocusOut()"
        >
          {{
            selectedItemsLength() === items?.length
              ? ('find-content.components.filter-content.deselect-all' | translate)
              : ('find-content.components.filter-content.select-all' | translate)
          }}
        </button>
      </li>
      <li *ngFor="let item of items; let index = index; trackBy: trackItem">
        <input
          [name]="radioInputName"
          [id]="radioInputName + findValueByKey(item, identifier.toString())"
          role="presentation"
          [type]="selectionType"
          [value]="findValueByKey(item, itemLabelIdentifier)"
          [checked]="isChecked(findValueByKey(item, identifier.toString()))"
          (click)="handleChange($event, findValueByKey(item, identifier.toString()))"
          (focus)="suggestionsOnFocus((showAddRemoveAllButtons ? 1 : 0) + index)"
          (focusout)="suggestionsOnFocusOut()"
          tabindex="-1"
        />

        <label
          role="option"
          [for]="radioInputName + findValueByKey(item, identifier.toString())"
          class="nonselectable"
          [class.checked]="isChecked(findValueByKey(item, identifier.toString()))"
        >
          <span [appTooltip]="findValueByKey(item, itemLabelIdentifier)" [appTooltipIfEllipsis]="true">{{
            findValueByKey(item, itemLabelIdentifier)
          }}</span>
          <app-icon
            *ngIf="selectionType === DropdownSelectionType.RADIO"
            [icon]="Icons.CHECKMARK"
            [size]="IconSize.DEFAULT"
          ></app-icon>
        </label>
      </li>
      <li *ngIf="action" class="dropdown-action">
        <button
          class="nonselectable"
          [routerLink]="action"
          (click)="toggledropDown(false)"
          (focus)="suggestionsOnFocus((showAddRemoveAllButtons ? 1 : 0) + this.selectionLength)"
          (focusout)="suggestionsOnFocusOut()"
        >
          {{ actionLabel | translate }}

          <app-icon [class.show]="true" [icon]="Icons.PLUS" [size]="IconSize.DEFAULT"></app-icon>
        </button>
      </li>
    </ul>
  </div>
</div>
