import { CourseItemType } from '@app/assignments-shared/assignments-shared.enums';
import { ContentTypeOrder } from '@app/find-content/enums/content-type-order.enum';
import { camelToKebab, kebabToPascal } from '@app/utils/string';
import { Icons } from '@shared-data/icons';
import { Icon } from '@shared-interfaces/icon.interface';

import { ContentType } from './enums/content-type.enum';

/**
 * Get a content type display name derived from a content type
 * @param contentType defines the content type enum to be found in content-type.enum.ts
 *
 * See {@link ContentType}
 * @param plural Set to true if the name should be plural. eg Lesson or Lessons
 * @returns a string with the name in kebab case
 */
export function getContentTypeName(contentType?: ContentType, plural?: boolean): string {
  return !plural && contentType
    ? `content.content-type.${camelToKebab(contentType)}`
    : plural && contentType
      ? `content.content-type-plural.${camelToKebab(contentType)}`
      : '';
}

/**
 * @param contentType defines the content type enum to be found in content-type.enum.ts
 *
 * See {@link ContentType}
 * @returns the icon name to be used in the icon component of this application
 *  * See {@link Icons}
 */
export function getContentTypeIcon(contentType: ContentType): Icon | undefined {
  switch (contentType) {
    case ContentType.WORKSHEET:
      return Icons.WORKSHEET;
    case ContentType.ACTIVITY:
      return Icons.ACTIVITY;
    case ContentType.EXERCISE_COLLECTION:
      return Icons.EXERCISE_COLLECTION;
    case ContentType.LESSON:
      return Icons.LESSON;
    case ContentType.TRAINING_COURSE:
      return Icons.TRAINING_COURSE;
    default:
      return;
  }
}

/**
 * Gets the order of content types in lists and arrays defined by business logic
 * @param contentType defines the content type enum to be found in content-type.enum.ts
 * - See {@link ContentType}
 * @returns The index to be found in content-type-order.enum.ts
 * - See {@link ContentTypeOrder}
 * @todo move business logic out of frontend
 */
export function getContentTypeOrder(contentType: ContentType): number {
  switch (kebabToPascal(contentType)) {
    case ContentType.WORKSHEET:
      return ContentTypeOrder.WORKSHEET;
    case ContentType.ACTIVITY:
      return ContentTypeOrder.ACTIVITY;
    case ContentType.EXERCISE_COLLECTION:
      return ContentTypeOrder.EXERCISE_COLLECTION;
    case ContentType.LESSON:
      return ContentTypeOrder.LESSON;
    case ContentType.TRAINING_COURSE:
      return ContentTypeOrder.TRAINING_COURSE;
    case ContentType.UNDEFINED:
      return ContentTypeOrder.UNDEFINED;
    default:
      return ContentTypeOrder.UNDEFINED;
  }
}

/**
 * Fetches a description for a content type
 * @param contentType defines the content type enum to be found in content-type.enum.ts
 *
 * See {@link ContentType}
 * @returns the string to get in our translations document which describes a content type.
 * For content types with no descriptions it returns an empty string.
 *
 * See {@link ./src/assets/i18n}
 */
export function getContentTypeDescription(contentType?: ContentType): string {
  if (!contentType || [ContentType.TRAINING_CONTEXT].includes(contentType)) {
    return '';
  }

  return contentType ? `content.content-type-description.${camelToKebab(contentType)}` : '';
}

/**
 * Creates a link for a course item page route. It must have the
 * course type and the order of the course item to be able to call the api
 * @param courseItemType defines the type enum of course to be found in assignments-shared.enums.ts
 *  *
 * See {@link CourseItemType}
 * @param courseItemOrder a number equvalent to the order that is needed to create the link
 * for routing and calling the api
 * @returns a string that is a link that can be routed to
 */
export function getCourseItemLink(courseItemType: CourseItemType, courseItemOrder: number): string {
  return [camelToKebab(courseItemType), courseItemOrder].join('/');
}
