import { Injectable } from '@angular/core';
import { CoreService } from '@core/services/core.service';
import { HtmlService } from '@shared-services/html.service';
import { getHttpsUrl, getUmbracoAlign, getUmbracoPercentageWidth, getUmbracoWidth } from '@utils/umbraco';
import { BehaviorSubject, Observable } from 'rxjs';

import { UmbSectionCategoryEnum, UmbSectionTypeEnum } from '../enums/page.umbraco.enum';
import { CallToAction } from '../interfaces/call-to-action.interface';
import { ImageColumn } from '../interfaces/image-column.interface';
import { LruEmbedVideo } from '../interfaces/lru-embed-video.interface';
import { LruMfHero_BaseTemplate, LruMfHero_Boxed, LruMfHero_ImageCard } from '../interfaces/lru-mf-hero.interface';
import { LruMfSignup } from '../interfaces/lru-mf-signup.interface';
import { LruMfUsp } from '../interfaces/lru-mf-usp.interface';
import { LruMfUspColumn } from '../interfaces/lru-mf-usp-column.interface';
import { Section } from '../interfaces/section.interface';
import { SectionColumn } from '../interfaces/section-column.interface';
import { SectionItem } from '../interfaces/section-item.interface';
import { UmbCardList_AKA_GridEditor } from '../interfaces/umbraco/umb-card-list.interface';
import { UmbLruMfUspColumn } from '../interfaces/umbraco/umb-lru-mf-usp-column.interface';
import { UmbPageSection } from '../interfaces/umbraco/umb-page-section.interface';
import { CallToActionMapper } from './call-to-action.mapper';
import { ContentListMapper } from './content-list.mapper';
import { ImageExtendedMapper } from './image-extended.mapper';
@Injectable({
  providedIn: 'root',
})
export class SectionMapper {
  constructor(
    private contentListMapper: ContentListMapper,
    private callToActionMapper: CallToActionMapper,
    private htmlService: HtmlService,
    private coreService: CoreService,
    private imageExtendedMapper: ImageExtendedMapper,
  ) {}

  mapSectionsFromApi(sections: UmbPageSection[]): Section[] {
    const result: Section[] = [];

    for (const section of sections) {
      const sectionItem: Section = {
        config: {
          align: getUmbracoAlign(section.alignment),
          width: getUmbracoWidth(section.width),
          backgroundImageUrl: section.backgroundImageUrl,
          hexBackgroundColor: section.hexBackgroundColor,
          hexForegroundColor: section.hexForegroundColor,
          hexTextColor: section.hexFontColor,
        },
        columns: [],
        anyColumnLoaded: false,
      };

      sectionItem.config.containerImage = section.containerImage;

      for (const column of section.columns) {
        const columnItem: SectionColumn = {
          id: column.id,
          items: [],
          percentageWidth: getUmbracoPercentageWidth(column.width),
        };

        for (const item of column.gridEditors) {
          this.setColumnElem(sectionItem, item, columnItem);
        }

        //// i commented out this if statement
        //// so we always get the columns even if empty
        // if (columnItem.items.length) {
        sectionItem.columns.push(columnItem);
        // }
      }

      if (sectionItem.columns.length) {
        result.push(sectionItem);
      }
    }

    return result;
  }

  setColumnElem(section: Section, item: UmbCardList_AKA_GridEditor, columnItem: SectionColumn): void {
    const elem: SectionItem = {
      id: item.id,
      loaded: false,
      title: '',
      type: item.type,
    };

    switch (item.type) {
      case UmbSectionTypeEnum.CallToActionButtons:
        this.callToActionMapper.mapCallToActionButtonsFromApi_v2(item).subscribe((data) => {
          elem.callToActionButtons = data;
          elem.loaded = true;
          section.anyColumnLoaded = true;
          columnItem.items.push(elem);
        });
        break;
      case UmbSectionTypeEnum.CallToAction:
        this.callToActionMapper.mapCallToActionFromApi_v1(item).subscribe((data) => {
          elem.callToAction = data;
          elem.loaded = true;
          section.anyColumnLoaded = true;
          columnItem.items.push(elem);
        });
        break;
      case UmbSectionTypeEnum.CallToActionStandard:
      case UmbSectionTypeEnum.CallToActionFeaturedTheme:
      case UmbSectionTypeEnum.CallToActionFeaturedCourse:
        this.callToActionMapper.mapCallToActionFromApi_v2(item, section.config).subscribe((data) => {
          if (data) {
            this.lruHeroMod(item, section, elem, data);
          }
          elem.callToAction = data;
          elem.loaded = true;
          section.anyColumnLoaded = true;
          columnItem.items.push(elem);
        });
        break;
      case UmbSectionTypeEnum.ContentList:
        this.contentListMapper.mapContentList(item).subscribe((data) => {
          elem.contentList = data;
          elem.loaded = true;
          section.anyColumnLoaded = true;
          if (section.config.width === 'full-width' || section.config.width === 'fullWidth') {
            section.config.width = 'large';
          }
          columnItem.items.push(elem);
        });
        break;
      case UmbSectionTypeEnum.Image:
        this.getImage(item).subscribe((data) => {
          elem.image = data;
          elem.loaded = true;
          section.anyColumnLoaded = true;
          columnItem.items.push(elem);
        });
        break;
      case UmbSectionTypeEnum.H1:
      case UmbSectionTypeEnum.H2:
      case UmbSectionTypeEnum.H3:
      case UmbSectionTypeEnum.H4:
        elem.title = item.value || '';
        elem.loaded = true;
        section.anyColumnLoaded = true;
        columnItem.items.push(elem);
        break;
      case UmbSectionTypeEnum.RichTextEditor:
        elem.html = this.htmlService.fixHtml(item.html);
        elem.loaded = true;
        section.anyColumnLoaded = true;
        columnItem.items.push(elem);
        break;
      case UmbSectionTypeEnum.Quote:
        elem.html = this.htmlService.fixHtml(item.value);
        elem.loaded = true;
        section.anyColumnLoaded = true;
        columnItem.items.push(elem);
        break;
      case UmbSectionTypeEnum.Embed:
        elem.html = this.htmlService.fixHtml(item.preview);
        elem.loaded = true;
        section.anyColumnLoaded = true;
        columnItem.items.push(elem);
        break;
      case UmbSectionTypeEnum.LruEmbedVideo:
        this.getLruEmbedVideo(item).subscribe((data) => {
          elem.lruEmbedVideo = data;
          elem.loaded = true;
          section.anyColumnLoaded = true;
          columnItem.items.push(elem);
        });
        break;
      case UmbSectionTypeEnum.LruMfHero:
        this.getLruMfHero(item).subscribe((data) => {
          if (data) {
            elem.type = data.type;
          }
          elem.lruMfHero = data;
          elem.loaded = true;
          section.anyColumnLoaded = true;
          columnItem.items.push(elem);
        });
        break;
      case UmbSectionTypeEnum.LruMfSignup:
        this.getLruMfSignup(item).subscribe((data) => {
          if (data) {
            elem.type = data.type;
          }
          elem.lruMfSignup = data;
          elem.loaded = true;
          section.anyColumnLoaded = true;
          columnItem.items.push(elem);
        });
        break;
      case UmbSectionTypeEnum.LruMfUsp:
        this.getLruMfUsp(item).subscribe((data) => {
          if (data) {
            elem.type = data.type;
          }
          elem.lruMfUsp = data;
          elem.loaded = true;
          section.anyColumnLoaded = true;
          columnItem.items.push(elem);
        });
        break;
      default:
        if (this.coreService.isDevServer) {
          console.log('NOT IMPLEMENTED: ', item);
          elem.title = `${elem.title} (org type:${elem.type})`;
          elem.type = UmbSectionTypeEnum.NotImplemented;
          elem.loaded = true;
          section.anyColumnLoaded = true;
          columnItem.items.push(elem);
        }
    }
  }

  private lruHeroMod(item: UmbCardList_AKA_GridEditor, section: Section, elem: SectionItem, data: CallToAction): void {
    if (item.type !== UmbSectionTypeEnum.CallToActionFeaturedTheme) {
      return;
    }
    if (section.config.backgroundImageUrl && !item.insideImage) {
      data.type = UmbSectionTypeEnum.LruHeroBackground;
      elem.type = UmbSectionTypeEnum.LruHeroBackground;
    } else if (!section.config.backgroundImageUrl && item.insideImage) {
      data!.type = UmbSectionTypeEnum.LruHeroCard;
      elem.type = UmbSectionTypeEnum.LruHeroCard;
      data!.slatedCard = {
        image: item.insideImage,
        text:
          data.buttons && data.buttons && data.buttons.buttons && data.buttons.buttons.length > 0
            ? data.buttons.buttons[0].text
            : '',
        url:
          data.buttons && data.buttons && data.buttons.buttons && data.buttons.buttons.length > 0
            ? data.buttons.buttons[0].href
            : '',
      };
      section.config.hexBackgroundColor += '80';
    }
  }

  private getImage(item: UmbCardList_AKA_GridEditor): Observable<ImageColumn | undefined> {
    const subject = new BehaviorSubject<ImageColumn | undefined>(undefined);
    const result: ImageColumn = {
      caption: item.caption,
      imageUrl: getHttpsUrl(item.imageUrl),
      copyright: item.imageCopyrightText,
      alternativeText: item.imageAlternativeText,
    };

    subject.next(result);
    return subject;
  }

  private getLruEmbedVideo(item: UmbCardList_AKA_GridEditor): Observable<LruEmbedVideo | undefined> {
    const subject = new BehaviorSubject<LruEmbedVideo | undefined>(undefined);
    const result: LruEmbedVideo = {
      caption: item.caption!,
      copyright: item.videoCopyrightText!,
      header: item.heading || item.header!,
      headingRight: item.headingRight!,
      inline: item.inline!,
      enableOverlay: item.overlay!,
      stackCcw: item.stackCcw!,
      url: item.url!,
    };

    subject.next(result);
    return subject;
  }

  private getLruMfHero(
    item: UmbCardList_AKA_GridEditor,
  ): Observable<LruMfHero_Boxed | LruMfHero_ImageCard | undefined> {
    const heroBaseTemplate: LruMfHero_BaseTemplate = {
      body: item.body,
      heading: item.heading,
      ctaText: item.ctaText,
      ctaUrl: item.ctaUrl,
      placementOfBoxCard: item.placementOfBoxCard || 'Right',
      variant: item.variant,
      type: UmbSectionTypeEnum.LruMfHero,
      category: UmbSectionCategoryEnum.LruMfHero,
    };
    const subject = new BehaviorSubject<LruMfHero_Boxed | LruMfHero_ImageCard | undefined>(undefined);
    let result = undefined;
    switch (heroBaseTemplate.variant) {
      case 'Boxed':
        result = heroBaseTemplate as LruMfHero_Boxed;
        result.backgroundImage = this.imageExtendedMapper.mapToImageExtended(
          item.backgroundImage!,
          item.backgroundImageCropUrl!,
          item.backgroundImageOriginCss!,
          item.backgroundImageFocalPoint!,
        );
        result.type = UmbSectionTypeEnum.LruMfHeroBoxed;
        break;

      case 'Image-Card':
        result = heroBaseTemplate as LruMfHero_ImageCard;
        result.cardImage = this.imageExtendedMapper.mapToImageExtended(
          item.cardImage!,
          item.cardImageCropUrl!,
          item.cardImageOriginCss!,
          item.cardImageFocalPoint!,
        );
        result.type = UmbSectionTypeEnum.LruMfHeroImageCard;
        result.body = item.body;
        result.slatedCard = {
          text: item.ctaText,
          url: item.ctaUrl,
          image: item.cardImage!,
          imageExtended: result.cardImage,
        };
        break;

      default:
        result = undefined;
        break;
    }

    subject.next(result);
    return subject;
  }

  private getLruMfSignup(item: UmbCardList_AKA_GridEditor): Observable<LruMfSignup | undefined> {
    const subject = new BehaviorSubject<LruMfSignup | undefined>(undefined);
    const result: LruMfSignup = {
      body: item.body,
      heading: item.heading,
      ctaText: item.ctaText,
      ctaUrl: item.ctaUrl,
      image: this.imageExtendedMapper.mapToImageExtended(
        item.signupImage!,
        item.signupImageCropUrl!,
        item.signupImageOriginCss!,
        item.signupImageFocalPoint!,
      ),
      type: UmbSectionTypeEnum.LruMfSignup,
      category: UmbSectionCategoryEnum.LruMfSignup,
    };

    subject.next(result);
    return subject;
  }

  private getLruMfUsp(item: UmbCardList_AKA_GridEditor): Observable<LruMfUsp | undefined> {
    const subject = new BehaviorSubject<LruMfUsp | undefined>(undefined);
    const result: LruMfUsp = {
      heading: item.heading,
      boxBody: item.boxBody,
      boxBackgroundColor: item.boxColor,
      boxUrl: item.boxUrl,
      uspColumns: item.uspColumns?.map(
        (column: UmbLruMfUspColumn) =>
          ({
            body: column.body,
            heading: column.heading,
            image: this.imageExtendedMapper.mapToImageExtended(
              column.imageUrl!,
              column.imageCropUrl!,
              column.imageOriginCss!,
              column.imageFocalPoint!,
            ),
          }) as LruMfUspColumn,
      ),
      type: UmbSectionTypeEnum.LruMfUsp,
      category: UmbSectionCategoryEnum.LruMfUsp,
    };

    subject.next(result);
    return subject;
  }
}
