import { style, transition, trigger } from '@angular/animations';
import { softBezier } from '@shared-animations/beziers.animation';

export const routeAnimationDuration = 600;
const transitionTiming = `${routeAnimationDuration}ms ${softBezier}`;
const beforeEnter = style({ position: 'absolute', transform: 'translateY(25px)', opacity: 0, width: '100%' });
const afterLeave = style({ position: 'absolute', transform: 'translateY(-25px)', opacity: 0, width: '100%' });
const visible = style({ transform: 'scale(1)', opacity: 1 });

export const routeAnimation = trigger('route-animation', [
  // Prevent animating when either the previous or current page is a full overlay
  transition('fullscreen <=> *', []),
  // Animate between all the other pages

  // FNI outcommented this since it gave visual bugs when scrolled down on a page

  // transition('* => *', [
  //   query(':enter', [beforeEnter], { optional: true }),
  //   query(':leave', animateChild(), { optional: true }),
  //   query(':leave', [animate(transitionTiming, afterLeave)], { optional: true }),
  //   query(':enter', [animate(transitionTiming, visible)], { optional: true }),
  //   query(':enter', animateChild(), { optional: true }),
  // ]),
]);
