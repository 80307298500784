import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { PortalConfiguration } from '@core/interfaces/project/portal-configuration.interface';
import { initialServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { createServerInteractionState } from '@core/utils/server-interaction-state.utils';
import { createReducer, on } from '@ngrx/store';

import * as CoreActions from './core.actions';
import { CoreState } from './interfaces/core-state.interface';

export const coreFeatureKey = 'coreState';

export const initialPortalConfigurationState: PortalConfiguration = {
  customPagesInfo: [],
  navbar: [],
  googleTagManagerId: '',
  projectGroup: '',
  ISBNs: [],
  errorPages: [],
  portalId: '',
  id: '',
  frontpageId: undefined,
  title: '',
};

export const initialCoreState: CoreState = {
  azureAppConfiguration: null,
  loadAzureAppConfigurationServerInteractionState: initialServerInteractionState,
  loadSiteBootstrapServerInteractionState: initialServerInteractionState,
  portalConfiguration: initialPortalConfigurationState,
  refreshTokenInterval: undefined,
  initialBootstrapCompleted: false,
  /*
   * Operational notification
   */
  operationalNotification: undefined,
  operationalNotificationServerInteractionState: initialServerInteractionState,
  operationalNotificationDismissedTokens: [],
};

export const coreReducer = createReducer(
  initialCoreState,
  on(CoreActions.setAzureAppConfiguration, (state, action) => ({
    ...state,
    azureAppConfiguration: action.azureAppConfiguration,
  })),
  on(CoreActions.setLoadAzureAppConfigurationServerInteractionState, (state, action) => ({
    ...state,
    loadAzureAppConfigurationServerInteractionState: { state: action.state, error: action.error || null },
  })),
  on(CoreActions.setPortalConfiguration, (state, action) => ({
    ...state,
    portalConfiguration: action.config,
  })),
  on(
    CoreActions.setLoadSiteBootstrapServerInteractionState,
    (state, action): CoreState => ({
      ...state,
      loadSiteBootstrapServerInteractionState: { state: action.state, error: action.error || null },
      initialBootstrapCompleted: state.initialBootstrapCompleted || action.state === ServerInteractionStateType.SUCCESS,
    }),
  ),
  on(CoreActions.setNavbar, (state, action) => ({
    ...state,
    portalConfiguration: {
      ...state.portalConfiguration,
      navbar: action.navbar,
    },
  })),
  on(CoreActions.setMegaMenu, (state, action) => ({
    ...state,
    portalConfiguration: {
      ...state.portalConfiguration,
      megaMenu: action.megaMenu,
    },
  })),

  /*
   * Operational notification
   */
  on(
    CoreActions.operationalNotificationSetData,
    (state, action): CoreState => ({
      ...state,
      operationalNotification: action.operationalNotification,
    }),
  ),
  on(
    CoreActions.operationalNotificationClearData,
    (state, action): CoreState => ({
      ...state,
      operationalNotification: undefined,
    }),
  ),
  on(
    CoreActions.operationalNotificationLoadSetServerInteractionState,
    (state, action): CoreState => ({
      ...state,
      operationalNotificationServerInteractionState: createServerInteractionState(action.state, action.error),
    }),
  ),
  on(
    CoreActions.operationalNotificationStoreDismissed,
    (state, action): CoreState => ({
      ...state,
      operationalNotificationDismissedTokens: action.dismissedNotificationTokens,
    }),
  ),
);
