import { ModuleWithProviders, NgModule } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { TranslateModule, TranslateModuleConfig } from '@ngx-translate/core';

@NgModule({
  imports: [TranslateModule],
  exports: [TranslateModule],
})
export class TranslateSharedModule {
  static forRoot(config?: TranslateModuleConfig): ModuleWithProviders<TranslateModule> {
    if (!config) {
      // console.log('forRoot: config NOT provided - config:', config);
      return TranslateModule.forRoot({});
    }
    // console.log('forRoot: config present - config:', config);
    return TranslateModule.forRoot(config);
  }

  static forChild(config?: TranslateModuleConfig): ModuleWithProviders<TranslateModule> {
    if (!config) {
      // console.log('forChild: config NOT provided - config:', config);
      return TranslateModule.forChild({});
    }
    // console.log('forChild: config present - config:', config);
    return TranslateModule.forChild(config);
  }
}
