import { DomPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { TeleportOutletId } from '@app/teleport/teleport-outlet-id';

/**
 * Handles registration of and communication between teleports.
 * Should only be used by the two teleport directives.
 */
@Injectable({
  providedIn: 'root',
})
export class TeleportService {
  private outlets: Partial<Record<TeleportOutletId, DomPortalOutlet>> = {};

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
  ) {}

  public registerOutlet(id: TeleportOutletId, outletElement: HTMLElement): void {
    if (this.outlets[id]) {
      throw new Error(`The teleport outlet ID "${id}" is already in use`);
    }

    this.outlets[id] = new DomPortalOutlet(
      outletElement,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector,
      document,
    );
  }

  public unregisterOutlet(id: TeleportOutletId): void {
    this.outlets[id]?.dispose();
    delete this.outlets[id];
  }

  public attachToOutlet(id: TeleportOutletId, domPortal: DomPortal): void {
    if (!this.outlets[id]) {
      throw new Error(`The teleport outlet ID "${id}" is wasn't found`);
    }

    this.outlets[id]?.attach(domPortal);
  }

  public detachFromOutlet(id: TeleportOutletId): void {
    this.outlets[id]?.detach();
  }
}
