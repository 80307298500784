export interface ZIndexState {
  initialZIndex: number;
  currentZIndex: number;
  items: ZIndexItem[];
}

export interface ZIndexItem {
  id: string;
  zIndex: number;
}

export const initialZIndexState: ZIndexState = {
  initialZIndex: 100,
  currentZIndex: 100,
  items: [],
};
