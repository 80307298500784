import { Injectable } from '@angular/core';
import { HtmlService } from '@shared-services/html.service';
import { getCustomUrl, getUmbracoAlign } from '@utils/umbraco';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { CallToAction } from '../interfaces/call-to-action.interface';
import { CallToActionButton } from '../interfaces/call-to-action-button.interface';
import { CallToActionButtons } from '../interfaces/call-to-action-buttons.interface';
import { SectionConfig } from '../interfaces/section-config.interface';
import {
  UmbCallToActionButton_v1,
  UmbCallToActionButton_v2,
} from '../interfaces/umbraco/umb-call-to-action-button.interface';
import { UmbCardList_AKA_GridEditor } from '../interfaces/umbraco/umb-card-list.interface';

@Injectable({
  providedIn: 'root',
})
export class CallToActionMapper {
  constructor(private htmlService: HtmlService) {}

  mapCallToActionFromApi_v1(item: UmbCardList_AKA_GridEditor): Observable<CallToAction | undefined> {
    let subject = new BehaviorSubject<CallToAction | undefined>(undefined);
    this.mapCallToActionButtonsFromApi_v1(item).subscribe((buttons) => {
      const result: CallToAction = {
        hexBackgroundColor: item.backgroundColor,
        hexTextColor: item.textColor,
        title: item.heading,
        description: this.htmlService.fixHtml(item.teaser),
        boxAlign: getUmbracoAlign(item.boxAlignment),
        textAlign: getUmbracoAlign(item.textAlignment, 'center'),
        buttons: buttons,
        type: item.type || undefined,
      };

      subject.next(result);
    });
    return subject;
  }

  mapCallToActionButtonsFromApi_v1(item: UmbCardList_AKA_GridEditor): Observable<CallToActionButtons | undefined> {
    let subject = new BehaviorSubject<CallToActionButtons | undefined>(undefined);

    if (item.callToActionButton) {
      const result: CallToActionButtons = {
        buttons: [],
        buttonsAlign: getUmbracoAlign(item.callToActionButton?.ctaAlign),
      };

      this.getButton(item.callToActionButton).subscribe((oldButton: CallToActionButton | undefined) => {
        if (oldButton) {
          result.buttons!.push(oldButton);
        }

        subject.next(result);
      });
    } else {
      subject.next(undefined);
    }

    return subject;
  }

  private getButton(item: UmbCallToActionButton_v1): Observable<CallToActionButton | undefined> {
    let subject = new BehaviorSubject<CallToActionButton | undefined>(undefined);

    if (!item) {
      subject.next(undefined);
    } else {
      const result: CallToActionButton = {
        target: item.ctaTarget,
        text: item.ctaText,
        href: item.ctaUrl,
      };

      subject.next(result);
    }

    return subject;
  }

  mapCallToActionButtonsFromApi_v2(item: UmbCardList_AKA_GridEditor): Observable<CallToActionButtons | undefined> {
    let subject = new BehaviorSubject<CallToActionButtons | undefined>(undefined);

    if (item.buttons?.length) {
      this.getButtons(item.buttons).subscribe((buttons: CallToActionButton[]) => {
        const result: CallToActionButtons = {
          buttons: buttons,
          buttonsAlign: getUmbracoAlign(item.buttonsAlignment),
        };

        subject.next(result);
      });
    } else {
      subject.next(undefined);
    }

    return subject;
  }

  private getButtons(items: UmbCallToActionButton_v2[]): Observable<CallToActionButton[]> {
    let subject = new BehaviorSubject<CallToActionButton[]>([]);

    if (!items) {
      subject.next([]);
    } else {
      const result: CallToActionButton[] = [];

      const observables = items.map((item) => this.mapCallToActionButtonFromApi(item));

      combineLatest(observables).subscribe((res) => {
        res.forEach((item) => {
          if (item) {
            result.push(item);
          }
        });
        subject.next(result);
      });
    }

    return subject;
  }

  mapCallToActionFromApi_v2(
    item: UmbCardList_AKA_GridEditor,
    sectionConfig?: SectionConfig,
  ): Observable<CallToAction | undefined> {
    let subject = new BehaviorSubject<CallToAction | undefined>(undefined);
    this.mapCallToActionButtonsFromApi_v2(item).subscribe((buttons: CallToActionButtons | undefined) => {
      const result: CallToAction = {
        hexBackgroundColor: item.backgroundColor,
        hexTextColor: item.textColor,
        title: item.heading,
        description: this.htmlService.fixHtml(item.body),
        textAlign: getUmbracoAlign(item.textAlignment),
        boxAlign: getUmbracoAlign(item.boxAlignment, 'center'),
        buttons: buttons,
        type: item.type || undefined,
        opacity: (sectionConfig?.containerImage && !!sectionConfig?.backgroundImageUrl) ?? false,
        insideImage: item.insideImage || '',
      };
      subject.next(result);
    });
    return subject;
  }

  mapCallToActionButtonFromApi(item: UmbCallToActionButton_v2): Observable<CallToActionButton | undefined> {
    let subject = new BehaviorSubject<CallToActionButton | undefined>(undefined);

    if (!item) {
      subject.next(undefined);
    } else {
      // const background = item.buttonBackgroundColor || this.appInitService.siteConfiguration.PrimaryColor;
      // const backgroundHover = item.buttonBackgroundColorHover ? item.buttonBackgroundColorHover : background;
      // const textColor = item.buttonTextColor || this.appInitService.siteConfiguration.PrimaryTextColor;
      // const textHover = item.buttonTextColorHover ? item.buttonTextColorHover : textColor;

      const result: CallToActionButton = {
        target: item.target,
        text: item.buttonText,
        href: item.contentCustomUrl
          ? getCustomUrl(item.contentCustomUrl)
          : item.externalUrl
            ? item.externalUrl
            : `/${item.contentId}`,
        // hexBackgroundColor: background,
        // hexBackgroundColorHover: backgroundHover,
        // hexTextColor: textColor,
        // hexTextColorHover: textHover,
        // effect: this.appInitService.isPs ? ButtonEffectEnum.Bubbles : undefined,
      };

      subject.next(result);
    }

    return subject;
  }
}
