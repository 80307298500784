import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { trackById } from '@app/utils/track-by';
import { createUniqueId } from '@app/utils/uuid';
import { loadingEnterLeaveAnimation } from '@shared-animations/loading.animation';
import { Icons } from '@shared-data/icons';
import { IconSize } from '@shared-enums/icon-size.enum';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';
import { TooltipDirective } from '@shared-modules/tooltip/directives/tooltip.directive';
import { TooltipDirection } from '@shared-modules/tooltip/interfaces/tooltip.interface';
import { GUID } from '@shared-types/guid.type';
import { findValueByKey } from '@shared-utils/find-value-by-key';
import { combineLatest, debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';

import { IconComponent } from '../icon/icon.component';
import { LoadingAnimationComponent } from '../loading-animation/loading-animation.component';
import { DropdownSelectionType, DropdownVariant } from './dropdown-button.enum';

@Component({
  standalone: true,
  imports: [
    RouterLink,
    TranslateSharedModule,
    LoadingAnimationComponent,
    IconComponent,
    TooltipDirective,
    CommonModule,
  ],
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [loadingEnterLeaveAnimation],
})
export class DropdownButtonComponent<T> implements OnDestroy, OnChanges {
  //REQUIRED
  //These parameters are required when using this component.
  @Input() public active: boolean = false;
  @Input() public buttonText?: string = '';
  @Input() public disabled: boolean = false;
  //Assign array iterate over using dropdownItems
  @Input() public items?: any[] | null;
  //Assign what parameter from the item should be used to name the label
  @Input() public itemLabelIdentifier: string = '';
  //Assign what the unique identifier is such that the component knows the difference between items
  @Input() public identifier!: string | GUID | number;
  //Assign the currently selected unique identifier to dropdownCheckedCondition
  @Input() public selectedItems: T[] | string | GUID | number | undefined | null;

  // for now we have this due to UX stuff
  @Input() public removeTextOnResize?: boolean = false;

  //OPTINAL Parameters
  //These parameters are optional when using this component.
  @Input() public buttonTitle?: string = '';
  @Input() public tooltip: string | TemplateRef<any> = '';
  @Input() public tooltipDirection: TooltipDirection = 'auto';
  @Input() public variant: DropdownVariant = DropdownVariant.PRIMARY;
  //Add a "+" route at the end of the dropdown menu by adding a route like 'lounge-team-admin' to dropdownAction
  @Input() public action: string | undefined = '';
  //Add label to the dropdownAction.
  @Input() public actionLabel: string = '';
  @Input() public loading: boolean = false;
  @Input() public leftAligned?: boolean = true;
  @Input() public keyboardSelectionChangeDelay: number = 500;
  @Input() public selectionType: DropdownSelectionType = DropdownSelectionType.RADIO;
  @HostBinding('class.full-width')
  @Input()
  public fullWidth: boolean = false;

  @Output() public selectionChange = new EventEmitter<T | T[]>();
  @Output() public activeState = new EventEmitter<boolean>();

  @ViewChild('dropdownContent') private dropdownContent!: ElementRef | undefined;
  // Only exist when the dropdown is open
  @ViewChild('dropDown') dropDown?: ElementRef;
  @ViewChild('button', { read: ElementRef }) private button!: ElementRef;

  public Icons = Icons;
  public IconSize = IconSize;
  public DropdownSelectionType = DropdownSelectionType;
  public readonly trackById = trackById;
  public readonly radioInputName = createUniqueId('dropdown-selector-radio-input');
  public findValueByKey = findValueByKey;

  public selectionSubjectOnMouse = new Subject<T>();
  public selectionSubjectOnKeyboard = new Subject<T>();
  private subCollection: Subscription = new Subscription();
  private _identifier = new Subject<T>();
  public isActive: boolean = false;
  public lastTimeStamp?: number;
  public renderDirection?: string;

  protected selectionFocus: boolean = false;
  protected selectionFocusIndex: number = -1;

  public allowedHeightInRenderDirection: number = 0;

  get showAddRemoveAllButtons(): boolean {
    return this.selectionType === DropdownSelectionType.CHECKBOX;
  }

  @HostListener('document:pointerdown', ['$event'])
  onGlobalClick(event: Event): void {
    if (this.button?.nativeElement) {
      if (!this.button?.nativeElement.contains(event.target)) {
        if (!this.dropdownContent?.nativeElement?.contains(event.target)) {
          this.isActive = false;
          this.activeState.emit(this.isActive);
        }
      }
    }
  }

  @HostListener('keydown', ['$event'])
  keypress(event: KeyboardEvent): void {
    if (event.key === 'Tab' || event.key === 'Escape') {
      this.selectionFocusIndex = -1;
      this.selectionFocus = false;
      this.isActive = false;
      this.toggledropDown(false);
    }
    if (event.key === 'Enter') {
      if (
        (this.selectionType === DropdownSelectionType.CHECKBOX && this.selectionFocusIndex === 0) ||
        this.selectionFocusIndex === -1
      ) {
        return;
      }

      if (this.selectionFocusIndex > -1 && this.selection) {
        let btn: HTMLLIElement | HTMLButtonElement = this.selection[this.selectionFocusIndex].children[0] as
          | HTMLLIElement
          | HTMLButtonElement;

        if (this.selectionType === DropdownSelectionType.RADIO) {
          this.selectionFocusIndex = -1;
          this.selectionFocus = false;
          this.isActive = false;
        }

        btn.click();
      }
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (!this.isActive) {
        this.toggledropDown(true);
      } else if (this.items && this.selection) {
        if (this.selectionFocusIndex + 1 < this.selection.length) {
          let btn: HTMLLIElement | HTMLButtonElement = this.selection[this.selectionFocusIndex + 1].children[0] as
            | HTMLLIElement
            | HTMLButtonElement;

          this.selectionFocus = true;
          btn.focus();
        }
      }
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (this.items && this.selection) {
        if (this.selectionFocusIndex - 1 >= 0) {
          let btn: HTMLLIElement = this.selection[this.selectionFocusIndex - 1].children[0] as HTMLLIElement;
          this.selectionFocus = true;
          btn.focus();
        } else {
          this.button.nativeElement.focus();
        }
      }
    }
  }

  constructor() {
    this.subCollection.add(
      combineLatest([this.selectionSubjectOnMouse, this._identifier])
        .pipe(
          debounceTime(0),
          distinctUntilChanged(),
          // Only changes if its different from current
        )
        .subscribe(([selection, id]) => {
          if (selection === id) {
            if (
              this.selectionType === DropdownSelectionType.CHECKBOX &&
              this.selectedItems &&
              Array.isArray(this.selectedItems)
            ) {
              if (this.selectedItems.some((item) => item === selection)) {
                this.selectedItems = this.selectedItems.filter((item) => item !== selection);
              } else {
                this.selectedItems = [...this.selectedItems, selection];
              }
              this.selectionChange.emit(this.selectedItems);
            } else {
              this.selectionFocusIndex = -1;
              this.selectionFocus = false;
              this.isActive = false;
              this.selectionChange.emit(selection);
            }
          }
        }),
    );
    this.subCollection.add(
      combineLatest([this.selectionSubjectOnKeyboard, this._identifier])
        .pipe(
          debounceTime(this.keyboardSelectionChangeDelay),
          distinctUntilChanged(),
          // Only changes if its different from current
        )
        .subscribe(([selection, id]) => {
          if (selection === id) {
            if (
              this.selectionType === DropdownSelectionType.CHECKBOX &&
              this.selectedItems &&
              Array.isArray(this.selectedItems)
            ) {
              if (this.selectedItems.some((item) => item === selection)) {
                this.selectedItems = this.selectedItems.filter((item) => item !== selection);
              } else {
                this.selectedItems = [...this.selectedItems, selection];
              }
              this.selectionChange.emit(this.selectedItems);
            } else {
              this.selectionFocusIndex = -1;
              this.selectionFocus = false;
              this.isActive = false;
              this.selectionChange.emit(selection);
            }
          }
        }),
    );
  }

  /**
   * Called whenever an item gains focus.
   */
  protected suggestionsOnFocus(index: number): void {
    this.selectionFocusIndex = index;
    this.selectionFocus = true;
  }

  /**
   * Called whenever an item loses focus.
   */
  protected suggestionsOnFocusOut(): void {
    this.selectionFocusIndex = -1;
    this.selectionFocus = false;
  }

  public trackItem(index: number, item: any): any {
    return item[this.identifier];
  }

  public get selection(): HTMLCollection | undefined {
    return this.dropDown?.nativeElement.children;
  }

  public get selectionLength(): number {
    return this.selection?.length ?? 0;
  }

  public handleChange(event: MouseEvent, identifier: any) {
    if (this.selectionType === DropdownSelectionType.CHECKBOX) {
      this.active = true;
    }

    this._identifier.next(identifier);
    if (event.clientX || event.clientY) {
      this.selectionSubjectOnMouse.next(identifier);
      return;
    }
    this.selectionSubjectOnKeyboard.next(identifier);
  }

  public toggledropDown(active?: boolean): void {
    this.isActive = active === undefined ? !this.isActive : active;
    if (this.isActive) {
      this.onResize();
    }
    this.activeState.emit(this.isActive);
  }

  public isChecked(item: any): boolean {
    if (Array.isArray(this.selectedItems)) {
      return this.selectedItems.some((selectedItem) => selectedItem === item);
    } else {
      return this.selectedItems === item;
    }
  }

  public addRemoveAll(): void {
    if (this.items) {
      if (Array.isArray(this.selectedItems) && this.items.length === this.selectedItems.length) {
        this.selectedItems = [];
      } else {
        let allItems: T[] = [];
        this.items.map((item) => {
          allItems.push(findValueByKey(item, this.identifier.toString()));
        });
        this.selectedItems = allItems;
      }
      this.selectionChange.emit(this.selectedItems);
    }
  }

  public selectedItemsLength(): number {
    if (Array.isArray(this.selectedItems)) {
      return this.selectedItems.length;
    }
    return 0;
  }

  get dropdownVariantClassName(): string {
    return `${this.variant}-variant`;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    if (this.isActive) {
      this.onResize();
    }
  }

  private onResize(): void {
    const position = this.button.nativeElement.getBoundingClientRect();
    const marginModifier = 24; // --spacing-24px
    const listElementHeight = 44; // height of a list element
    const listMinHeight = 2.5 * listElementHeight;
    const distanceFromElementToTopOfViewport = 0 + position.top;
    const distanceFromElementToBottomOfViewport = window.innerHeight - position.bottom;

    if (
      distanceFromElementToBottomOfViewport - marginModifier / (this.items!.length / 5) >= listElementHeight &&
      distanceFromElementToBottomOfViewport - marginModifier >= listMinHeight
    ) {
      this.renderDirection = 'render-content-bottom';
      this.allowedHeightInRenderDirection = distanceFromElementToBottomOfViewport - marginModifier;
    } else if (distanceFromElementToBottomOfViewport < distanceFromElementToTopOfViewport) {
      this.renderDirection = 'render-content-top';
      this.allowedHeightInRenderDirection = distanceFromElementToTopOfViewport - marginModifier;
    } else {
      this.renderDirection = 'render-content-bottom';
      this.allowedHeightInRenderDirection = distanceFromElementToBottomOfViewport - marginModifier;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!(this.selectionType === DropdownSelectionType.CHECKBOX)) {
      this.isActive = this.active;
    }
  }

  ngOnDestroy(): void {
    this.subCollection.unsubscribe();
  }
}
