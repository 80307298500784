import { GUID } from '@shared-types/guid.type';

export function activateScrollToOpenedContainer(id: GUID | string): void {
  let timeOut: ReturnType<typeof setTimeout> | undefined;

  if (timeOut) {
    clearTimeout(timeOut);
  }

  timeOut = setTimeout(() => {
    scrollToOpenedContainer(id);
  }, 430);
}

export function scrollTo(top: number, behavior: ScrollBehavior = 'smooth'): void {
  document.defaultView?.scrollTo({
    top,
    behavior,
  });
}

export function scrollToTop(): void {
  scrollTo(0);
}

export function scrollToOpenedContainer(id: GUID | string): void {
  let openedContainer = id ? ((<HTMLTableRowElement>document.getElementById(id)) as HTMLTableRowElement) : undefined;

  if (openedContainer) {
    const targetBoundingRect = openedContainer.getBoundingClientRect() as DOMRect;

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = document.defaultView?.pageYOffset || docEl.scrollTop || body.scrollTop;

    const clientTop = docEl.clientTop || body.clientTop || 0;

    const top = targetBoundingRect.top + scrollTop - clientTop;

    scrollTo(top - 68);
  }
}
