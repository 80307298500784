import { Injectable } from '@angular/core';
import { ContentApiService } from '@app/content/services/content-api.service';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ContentState } from '../interfaces/content.interface';
import * as ContentActions from './content.actions';
import { setLoadPrintableServerInteractionState } from './content.actions';
import * as ContentSelectors from './content.selectors';

@Injectable()
export class ContentEffects {
  constructor(
    private actions$: Actions,
    private contentApiService: ContentApiService,
    private contentStore: Store<ContentState>,
  ) {}

  $loadLesson = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentActions.loadLesson),
      exhaustMap(({ lessonId }) => {
        this.contentStore.dispatch(
          ContentActions.setLoadLessonServerInteractionState({ lessonId, state: ServerInteractionStateType.LOADING }),
        );

        return this.contentApiService.loadLesson(lessonId).pipe(
          switchMap((lesson) => [
            ContentActions.setLoadLessonServerInteractionState({
              lessonId: lessonId,
              state: ServerInteractionStateType.SUCCESS,
            }),

            ContentActions.setLesson({ lesson }),
          ]),
          catchError((error) => [
            ContentActions.setLoadLessonServerInteractionState({
              lessonId: lessonId,
              state: ServerInteractionStateType.ERROR,
              error,
            }),
          ]),
        );
      }),
    ),
  );

  $loadLessonIfNecessary = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentActions.loadLessonIfNecessary),
      concatLatestFrom((action) => [
        this.contentStore.select(ContentSelectors.selectLoadLessonServerInteractionState(action.lessonId)),
      ]),
      exhaustMap(([{ lessonId }, loadLessonServerInteractionState]) => {
        if (
          [ServerInteractionStateType.INITIAL, ServerInteractionStateType.ERROR].includes(
            loadLessonServerInteractionState.state,
          )
        ) {
          return [ContentActions.loadLesson({ lessonId })];
        }
        return [];
      }),
    ),
  );

  //ACTIVITIES & WORKSHEETS
  getActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentActions.getActivity),
      exhaustMap((action) => {
        this.contentStore.dispatch(
          setLoadPrintableServerInteractionState({
            printableId: action.activityId,
            state: ServerInteractionStateType.LOADING,
          }),
        );

        return this.contentApiService.getActivity(action.activityId).pipe(
          switchMap((activity) => [
            ContentActions.setPrintable({ data: activity }),
            setLoadPrintableServerInteractionState({
              printableId: action.activityId,
              state: ServerInteractionStateType.SUCCESS,
            }),
          ]),
          catchError((error) => [
            setLoadPrintableServerInteractionState({
              printableId: action.activityId,
              state: ServerInteractionStateType.ERROR,
              error,
            }),
          ]),
        );
      }),
    ),
  );

  getWorksheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentActions.getWorksheet),
      exhaustMap((action) => {
        this.contentStore.dispatch(
          setLoadPrintableServerInteractionState({
            printableId: action.worksheetId,
            state: ServerInteractionStateType.LOADING,
          }),
        );
        return this.contentApiService.getWorksheet(action.worksheetId).pipe(
          switchMap((worksheet) => [
            ContentActions.setPrintable({ data: worksheet }),
            setLoadPrintableServerInteractionState({
              printableId: action.worksheetId,
              state: ServerInteractionStateType.SUCCESS,
            }),
          ]),
          catchError((error) => [
            setLoadPrintableServerInteractionState({
              printableId: action.worksheetId,
              state: ServerInteractionStateType.ERROR,
              error,
            }),
          ]),
        );
      }),
    ),
  );
}
