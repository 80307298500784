import { initialSubTeamsForTeam, SubTeamsForTeam } from '@app/teams/interfaces/sub-team.interfaces';
import { TeamsState } from '@app/teams/interfaces/teams.interfaces';
import { teamsFeatureKey } from '@app/teams/store/teams.reducers';
import { initialServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';

export const selectTeamsState = createFeatureSelector<TeamsState>(teamsFeatureKey);

export const selectTeams = createSelector(selectTeamsState, (state: TeamsState) => state.teams);

export const selectTeam = (teamId: GUID) =>
  createSelector(selectTeamsState, (state: TeamsState) => state.teams.find((team) => team.id === teamId));

export const selectStudentsForTeam = (teamId: GUID) =>
  createSelector(selectTeamsState, (state) => state.studentsByTeam[teamId] ?? []);

export const selectStudentsForTeamServerInteractionState = (teamId: GUID) =>
  createSelector(
    selectTeamsState,
    (state) => state.loadStudentsForTeamServerInteractionState[teamId] ?? initialServerInteractionState,
  );

export const selectLoadTeamsServerInteractionState = createSelector(
  selectTeamsState,
  (state: TeamsState) => state.loadTeamsServerInteractionState,
);

export const selectNoTeams = createSelector(selectTeamsState, (state: TeamsState) => state.noTeams);
export const selectGlobalTeamId = createSelector(selectTeamsState, (state: TeamsState) => state.globalTeamId);

export const selectGlobalTeam = createSelector(
  selectTeamsState,
  selectGlobalTeamId,
  (state, globalTeamId) => globalTeamId && state.teams.find((team) => team.id === globalTeamId),
);
export const selectGlobalTeamName = createSelector(selectGlobalTeam, (team) => team?.title);

export const selectGlobalSubTeamId = createSelector(selectTeamsState, (state: TeamsState) => state.globalSubTeamId);

export const selectGlobalTeamLevelIds = createSelector(
  selectTeamsState,
  (state: TeamsState) => state.teams.find((team) => team.id === state.globalTeamId)?.gradeLevelIds,
);

export const globalTeamUserSelected = createSelector(
  selectTeamsState,
  (state: TeamsState) => state.globalTeamUserSelected,
);

export const selectAlertTeamChange = createSelector(selectTeamsState, (state: TeamsState) => state.alertTeamChange);

export const selectSubTeamsForTeam = (
  teamId: GUID,
): MemoizedSelector<object, SubTeamsForTeam, DefaultProjectorFn<SubTeamsForTeam>> =>
  createSelector(selectTeamsState, (state: TeamsState) => state.subTeamsByTeam[teamId] || initialSubTeamsForTeam);
