<ng-container *ngIf="megaMenu$ | async as megaMenu">
  <div class="flex column space-between left">
    <ul class="menu">
      <li *ngFor="let tab of megaMenu.tabs">
        <button
          class="menu-item"
          [class.selected]="tab === megaMenu.currentTab"
          (click)="changeTab(tab)"
          [class.open]="tab === megaMenu.activelyOpenedTab"
        >
          <ng-container
            [ngTemplateOutlet]="title"
            [ngTemplateOutletContext]="{
              title: tab.title,
              label: tab.label
            }"
          ></ng-container>
          <app-icon [icon]="Icons.ALINEA_ARROW" [size]="IconSize.MEDIUM"></app-icon>
        </button>
        <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ items: tab.items }"></ng-container>
      </li>
    </ul>
    <a class="menu-item" *ngIf="megaMenu.link" href="{{ megaMenu.link.url }}" target="_blank" rel="noopener">
      <span>{{ megaMenu.link.title }}</span>
      <app-icon [icon]="Icons.ALINEA_OUTSIDE_LINK" [size]="IconSize.MEDIUM"></app-icon>
    </a>
  </div>
  <div class="right">
    <ng-container *ngFor="let tab of megaMenu.tabs">
      <ng-container *ngIf="tab === megaMenu.currentTab">
        <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ items: tab.items }"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #content let-items="items">
  <div class="content flex wrap">
    <ng-container *ngFor="let item of items">
      <a
        *ngIf="!item.icon"
        data-theme="text"
        href="{{ item.url }}"
        target="_blank"
        rel="noopener"
        [class]="item.hover ? item.hover.className : ''"
      >
        <ng-container
          [ngTemplateOutlet]="title"
          [ngTemplateOutletContext]="{
            title: item.title,
            label: item.label
          }"
        ></ng-container>
      </a>
      <div *ngIf="item.icon" data-theme="icon">
        <i class="icon" [style.background-image]="item.icon | umbracoImage: 32 : 32 : 'crop' | backgroundImage"></i>
        <a href="{{ item.url }}" target="_blank" rel="noopener">
          <ng-container
            [ngTemplateOutlet]="title"
            [ngTemplateOutletContext]="{
              title: item.title,
              label: item.label,
              class: 'headline-5'
            }"
          ></ng-container>
          <div class="rte font-size-16" *ngIf="item.description" [innerHtml]="item.description"></div>
        </a>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #title let-title="title" let-label="label" let-class="class">
  <span class="flex space-2 wrap vertical-center">
    <label class="{{ class }}">{{ title }}</label>
    <span>
      <span class="flex space-1 wrap" *ngIf="label">
        <label class="badge">{{ label }}</label>
      </span>
    </span>
  </span>
</ng-template>
