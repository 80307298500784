export function removeQueryParams(url: string): string {
  return url.replace(/(\?.*)#?/, '');
}

export function extractQueryParams(url: string): string {
  const match = url.match(/(\?.*)#?/);

  return match ? match[0] : '';
}

export function isLocalhost(): boolean {
  return window.location.hostname === 'localhost';
}
