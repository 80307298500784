<ng-container *ngIf="messages$ | async as messages">
  <ul class="toast-message-list" [@animateInOut]="messages?.length ?? 0">
    <li
      *ngFor="let message of messages"
      class="toast-message-list-item"
      (mouseenter)="onMouseenter($event)"
      (mouseleave)="onMouseleave()"
    >
      <app-toast-message [message]="message"></app-toast-message>
    </li>
  </ul>
</ng-container>
