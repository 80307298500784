/**
 * Returns true if the given element is the child of a modal
 */
export function isElementInModal(element: HTMLElement): boolean {
  while (element.parentElement) {
    if (['LRU-MODAL-OUTLET', 'APP-MODAL-OUTLET'].includes(element.tagName)) {
      return true;
    }

    element = element.parentElement;
  }

  return false;
}
