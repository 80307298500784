<ng-container
  *ngIf="{
    loadTeamsServerInteractionState: loadTeamsServerInteractionState$ | async,
    teams: teams$ | async,
    buttonText: (buttonText$ | async)!,
    selectedTeam: selectedTeam$ | async,
    navigationDepth: (navigationDepth$ | async)!
  } as sub"
>
  <app-dropdown-button
    *ngIf="sub.teams && sub.teams.length"
    [variant]="DropdownVariant.GLOBAL"
    [active]="false"
    [loading]="sub.loadTeamsServerInteractionState?.state === ServerInteractionStateType.LOADING"
    [buttonText]="sub.buttonText"
    [items]="sub.teams"
    [itemLabelIdentifier]="'title'"
    [identifier]="'id'"
    [selectedItems]="selectedTeamId"
    [action]="['lounge-team-admin', ['create']] | pageTypeLink"
    [actionLabel]="'teams.components.team-selector-dropdown.create-new-team-placeholder'"
    (selectionChange)="onTeamClick($event)"
  >
  </app-dropdown-button>

  <button
    class="button"
    *ngIf="sub.teams && !sub.teams.length"
    [routerLink]="['lounge-team-admin', ['create']] | pageTypeLink"
  >
    <app-icon [class.show]="true" [icon]="Icons.PLUS" [size]="IconSize.DEFAULT"></app-icon>
    {{ 'teams.components.team-selector-dropdown.create-new-team-placeholder' | translate }}
  </button>
</ng-container>
