export enum BadgeType {
  ONE_OFF = 'oneOffAchievements',
  TOPIC = 'topicAchievements',
  LEGACY = 'Legacy',
  SUPER_TRAINER = 'superTrainerAchievements',
}

export enum BadgeTypeOrder {
  TOPIC = 0,
  SUPER_TRAINER = 1,
}

export enum BadgeYearType {
  SCHOOLYEARS = 'schoolYears',
}
