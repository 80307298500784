import { CartItem } from '@app/cart/interfaces/cart-item.interface';
import {
  CartItemRequest,
  CartItemRequestBase,
  CartItemRequestTrainingContext,
} from '@app/cart/interfaces/cart-item-request.interface';
import { ContentType } from '@app/content/enums/content-type.enum';

export function mapCartItemToCartItemRequest(cartItem: CartItem): CartItemRequest {
  const base: CartItemRequestBase = { id: cartItem.contentId, contentType: cartItem.contentType };

  switch (cartItem.contentType) {
    case ContentType.TRAINING_CONTEXT:
      return {
        ...base,
        exerciseCount: cartItem.exerciseCount,
        topicIds: cartItem.topics.map((topic) => topic.id),
      } as CartItemRequestTrainingContext;
  }

  return base;
}
