<app-icon
  *ngIf="message.icon"
  class="icon-badge"
  [icon]="message.icon"
  [size]="message.type === ToastEventType.SINGLE_BADGE ? IconSize.XXLARGE : IconSize.MEDIUM"
  [style.color]="getIconColor"
  [style.--svg-foreground]="message.iconColors?.foreground"
  [style.--svg-background]="message.iconColors?.background"
></app-icon>
<app-badge [badgeName]="message.badgeName" [oneOff]="true" *ngIf="message.badgeName" class="badge-toast"></app-badge>
<p class="toast-message-message" role="alert">
  {{ message.translateMessage ? (message.message | translate) : message.message }}
</p>
<app-icon-button
  class="toast-message-dismiss-button"
  [icon]="Icons.CLOSE_SMALL"
  [iconSize]="IconSize.DEFAULT"
  [title]="'app.actions.dismiss' | translate"
  (click)="onDismissClick()"
></app-icon-button>
