import { CartItem } from '@app/cart/interfaces/cart-item.interface';
import { CartItemRequest } from '@app/cart/interfaces/cart-item-request.interface';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { ParsedServerError } from '@core/interfaces/project/parsed-server-error.interface';
import { createAction, props } from '@ngrx/store';

export const fetchCart = createAction('[Cart/API] Fetch cart');

export const fetchCartIfNecessary = createAction('[Cart/API] Fetch cart if necessary');

export const emptyCart = createAction('[Cart/API] Empty cart');

export const setCartItems = createAction('[Cart/API] Set cart items', props<{ items: CartItem[] }>());

export const setCartServerInteractionState = createAction(
  '[Cart/API] Set cart server interaction state',
  props<{ state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const setEmptyCartServerInteractionState = createAction(
  '[Cart/API] Set empty cart server interaction state',
  props<{ state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const setAddToCartServerInteractionState = createAction(
  '[Cart/API] Set add to cart server interaction state',
  props<{ item: CartItemRequest; state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const setRemoveFromCartServerInteractionState = createAction(
  '[Cart/API] Set remove from cart server interaction state',
  props<{ item: CartItemRequest; state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const addItem = createAction('[Cart/API] Add item', props<{ item: CartItemRequest }>());

export const removeItem = createAction('[Cart/API] Remove item', props<{ item: CartItemRequest }>());
