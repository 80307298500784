<h1 *ngIf="!isEmpty" class="cart-heading">{{ 'cart.components.cart.heading' | translate }}</h1>

<app-button
  *ngIf="!isEmpty"
  data-testid="empty-cart-button"
  class="empty-cart-button"
  [icon]="Icons.DELETE | serverInteractionIcon: emptyCartServerInteractionState : 'empty-cart'"
  [rightAlignIcon]="true"
  [variant]="ButtonVariant.TEXT"
  [size]="ButtonSize.SMALL"
  [disabled]="isEmpty"
  [loading]="
    emptyCartServerInteractionState
      ? emptyCartServerInteractionState.state === ServerInteractionStateType.LOADING
      : true
  "
  (click)="onEmptyCartClick()"
>
  {{ 'cart.components.cart.empty-cart-button' | translate }}
</app-button>

<ng-container *ngIf="cartServerInteractionState" [ngSwitch]="cartServerInteractionState.state">
  <app-loading-animation *ngSwitchCase="ServerInteractionStateType.LOADING"></app-loading-animation>
  <app-server-error-message
    *ngSwitchCase="ServerInteractionStateType.ERROR"
    [serverInteractionState]="cartServerInteractionState"
  ></app-server-error-message>
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="!isEmpty">
      <ul class="cart-list">
        <li class="cart-list-item" *ngFor="let cartItem of items">
          <app-cart-item [attr.data-testid]="'cartItem-' + cartItem.contentId" [data]="cartItem"></app-cart-item>
          <div class="trash-button-wrapper">
            <app-icon-button
              data-testid="remove-from-cart"
              [icon]="
                Icons.DELETE
                  | serverInteractionIcon
                    : removeItemServerInteractionStates[cartItem.contentType + '-' + cartItem.contentId]
                    : 'remove-from-cart'
              "
              [loading]="
                removeItemServerInteractionStates[cartItem.contentType + '-' + cartItem.contentId] &&
                removeItemServerInteractionStates[cartItem.contentType + '-' + cartItem.contentId].state ===
                  ServerInteractionStateType.LOADING
              "
              [title]="'cart.components.cart.remove-item-from-cart-button' | translate"
              (click)="onRemoveItem(cartItem)"
            ></app-icon-button>
          </div>
        </li>
      </ul>
      <div class="cart-buttons-wrapper">
        <app-button
          data-testid="assign-button"
          class="assign-button"
          (click)="onDismissClick(true)"
          [routerLink]="['mf20-assignments-managed', 'assign-content'] | pageTypeLink"
        >
          {{ 'cart.components.cart.assign-button' | translate }}
        </app-button>
      </div>
    </ng-container>
    <app-cart-empty-state
      *ngIf="isEmpty"
      [allowDismiss]="true"
      [allowNavigateToFindContent]="true"
      (dismiss)="onDismissClick(false)"
    ></app-cart-empty-state>
  </ng-container>
</ng-container>
