import { Student } from '@app/teams/interfaces/student.interfaces';
import {
  initialServerInteractionState,
  ServerInteractionState,
} from '@core/interfaces/server-interaction-state.interface';
import { GUID } from '@shared-types/guid.type';

export interface SubTeamsForTeam {
  subTeams: SubTeam[];
  serverInteractionState: ServerInteractionState;
}

export const initialSubTeamsForTeam: SubTeamsForTeam = {
  subTeams: [],
  serverInteractionState: initialServerInteractionState,
};

export interface SubTeam {
  id: GUID;
  teamId: GUID;
  title: string;
  description: string;
  icon: string;
  students: Student[];
  created: Date;
  updated: Date;
}

export interface SubTeamApiResponse {
  id: GUID;
  teamId: GUID;
  title: string;
  description: string;
  icon: string;
  students: Student[];
  created: string;
  updated: string;
}

export interface GetSubTeamsByTeamApiResponse {
  result: SubTeamApiResponse[];
}
