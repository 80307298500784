import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ standalone: true, name: 'backgroundImage' })
export class BackgroundImagePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return 'url(' + value + ')';
    }

    return value;
  }
}
