import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getPageType } from '@app/app.routes';
import { ParsedServerError } from '@app/core/interfaces/project/parsed-server-error.interface';
import * as CoreSelectors from '@app/core/store/core.selectors';
import { CoreState } from '@app/core/store/interfaces/core-state.interface';
import * as PageSelectors from '@app/page/store/page.selectors';
import { UserService } from '@app/user/services/user.service';
import { getObservableValueSync } from '@app/utils/observables';
import { AzureAppConfigurationService } from '@core/services/azure-app-configuration.service';
import { UmbracoApiService } from '@core/services/umbraco-api.service';
import { select, Store } from '@ngrx/store';
import { RouterLinkCommands } from '@shared-types/router-link-command.type';
import { getFirstArrayItem } from '@utils/array';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { PageState } from '../interfaces/page-state.interface';
import { UmbPage } from '../interfaces/umbraco/umb-page.interface';
import { HeroMapper } from '../mapper/hero.mapper';
import { SectionMapper } from '../mapper/section.mapper';
import { pageType } from '../page.types';
import * as PageActions from '../store/page.actions';
import { SeoService } from './seo.service';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  public page$ = this.pageStore.select(PageSelectors.selectPage);

  public documentTitle$: Observable<string | undefined> = this.pageStore.pipe(
    select(PageSelectors.selectDocumentTitle),
  );

  public displayLoginModal: boolean = false;
  public displayNotAuthorizedModal: boolean = false;

  private _pageId?: string;
  private _pageUrl?: string;

  get pageId(): string | undefined {
    return this._pageId;
  }

  get pageUrl(): string | undefined {
    return this._pageUrl;
  }

  public get productId(): string {
    return getObservableValueSync(this.productId$);
  }

  public get productId$(): Observable<string> {
    return this.coreStore.select(CoreSelectors.selectProductId);
  }

  /**
   * The page to display behind the "not authorized" modal.
   * Returns the dashboard for the current user type.
   */
  public get notAuthorizedModalBackgroundPageUrl(): string {
    return this.userService.isTeacher
      ? this.getUrlForPageType('mf20-dashboard-teacher') ?? '/'
      : this.azureAppConfigurationService.getSetting('frontpageLoggedInRedirect');
  }

  constructor(
    private umbracoApi: UmbracoApiService,
    private coreStore: Store<CoreState>,
    private pageStore: Store<PageState>,
    private router: Router,
    private userService: UserService,
    private heroMapper: HeroMapper,
    private sectionMapper: SectionMapper,
    private seoService: SeoService,
    private location: Location,
    private azureAppConfigurationService: AzureAppConfigurationService,
  ) {}

  /**
   * Returns the URL for the first route that matches the given page type
   */
  public getUrlForPageType(pageType: pageType, ...routerLinkCommands: RouterLinkCommands[]): string | undefined {
    const route = getFirstArrayItem(this.router.config.filter((a) => a.data?.pageType === pageType));

    if (!route) {
      return undefined;
    }

    return '/' + [route.path, ...routerLinkCommands].join('/');
  }

  /**
   * Navigates to the given page type and sub path.
   * Skips navigation if the page type can't be found.
   * Returns true if the page type was found and false if it wasn't.
   */
  public navigateToPageType(pageType: pageType, ...routerLinkCommands: RouterLinkCommands[]): boolean {
    const url = this.getUrlForPageType(pageType, ...routerLinkCommands);

    if (url) {
      this.router.navigateByUrl(url);
      return true;
    }

    return false;
  }

  setPage(url: string, id: string): void {
    if (id) {
      this.showPage(url, id);
    } else {
      this.coreStore.select(CoreSelectors.selectFrontpage).subscribe((frontpageId) => {
        if (frontpageId) {
          this.showPage(url, frontpageId.toString());
        } else {
          this.showError(url, 404);
        }
      });
    }
  }

  private getPage(pageId: string, portalId?: string): Observable<UmbPage> {
    let url = `v3/alineaportal/contentpage/fetch/${this.userService.userType}/${pageId}/`;

    if (portalId) {
      url = `${url}${portalId}`;
    }
    return this.umbracoApi.getPage(url);
  }

  public setPageType(pageType: pageType): void {
    this.pageStore.dispatch(PageActions.setPageType({ pageType }));
  }

  public setDocumentTitle(title: string): void {
    this.pageStore.dispatch(PageActions.setDocumentTitle({ title }));
  }

  private showPage(url: string, id: string, fromError?: boolean): void {
    this.coreStore
      .select(CoreSelectors.selectPortalId)
      .pipe(take(1))
      .subscribe((portalId) => {
        this.getPage(id, portalId)
          .pipe(take(1))
          .subscribe({
            next: (page) => {
              this.setDocumentTitle(page.metaTitle);
              this.pageStore.dispatch(
                PageActions.setPage({
                  data: {
                    hero: this.heroMapper.mapHeroFromApi(page),
                    sections: this.sectionMapper.mapSectionsFromApi(page.sectionsV2),
                    id: id,
                    url: url,
                    pageType: page.pageType,
                  },
                }),
              );

              /// i only set these since guards cant wait for observable
              this._pageId = id;
              this._pageUrl = url;
              if (
                !page.pageType ||
                page.pageType === getPageType('contentpage') ||
                page.pageType === getPageType('frontpage') ||
                page.pageType === getPageType('error')
              ) {
                this.router.navigate(['contentpage'], {
                  skipLocationChange: true,
                });
              } else if (page.customUrl) {
                this.coreStore.select(CoreSelectors.selectPortalId).subscribe((portalId) => {
                  this.umbracoApi
                    .getMetaData(id, portalId)
                    .pipe(take(1))
                    .subscribe((metaData) => {
                      this.seoService.setMetaData(metaData);
                    });
                });
              } else {
                this.router.navigate([page.pageType], {
                  skipLocationChange: true,
                });
              }
            },
            error: (error: ParsedServerError) => {
              if (fromError) {
                this.showError(url, 503);
              } else {
                this.showError(url, error.httpStatus);
              }
            },
          });
      });
  }

  showError(url: string, statusCode: number): void {
    let tempStatusCode = statusCode.toString();

    if (!tempStatusCode) {
      tempStatusCode = '500';
    }

    if (tempStatusCode === '503') {
      const page: pageType = 'error-503';

      this.router.navigate([page], {
        skipLocationChange: true,
      });
      return;
    }

    if (tempStatusCode === '400') {
      // Thomas Olsen wants to handle 400 as 404
      tempStatusCode = '404';
    }

    switch (tempStatusCode) {
      case '403':
        // Logged in, but not authorized (students trying to access teacher specific pages or vice versa)
        // Display a background page with a not-authorized modal on top, while maintaining the requested URL
        this.displayNotAuthorizedModal = true;
        this.router.navigateByUrl(this.notAuthorizedModalBackgroundPageUrl, {
          skipLocationChange: true,
        });
        break;
      case '204':
        // Not logged in
        // Display the frontpage with a login modal on top, while maintaining the requested URL
        this.coreStore.select(CoreSelectors.selectFrontpage).subscribe((frontpageId) => {
          if (frontpageId) {
            this.showPage(url, frontpageId.toString(), true);
            this.displayLoginModal = true;

            // This `location.replaceState` shouldn't be necessary, but I cant find out where `this.showPage` changes the url to `contentpage`
            setTimeout(() => {
              this.location.replaceState(url);
            });
          } else {
            this.showError(url, 403);
          }
        });
        break;
      default:
        this.coreStore.select(CoreSelectors.selectErrorPages).subscribe((errorPages) => {
          let errorPageId = errorPages.find((item) => item.statusCode === tempStatusCode)?.id;

          if (!errorPageId) {
            errorPageId = errorPages.find((item) => item.statusCode === '404')?.id;
          }

          if (tempStatusCode && errorPageId) {
            this.showPage(url, errorPageId.toString(), true);
          }
        });
    }
  }
}
