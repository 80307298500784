import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { PageLink } from '../interfaces/page-link.interface';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  ////TODO: keeping this comment out data stuff here for now
  //// - thinking its just spill over from old fagportaler way of doing navbar and we really dont need it
  // private data: Subject<UmbNavbar> = new ReplaySubject<UmbNavbar>(1);
  // public data$ = this.data.asObservable();

  private findContent = new ReplaySubject<PageLink>(1);
  public findContent$ = this.findContent.asObservable();

  private evaluation = new ReplaySubject<PageLink>(1);
  public evaluation$ = this.evaluation.asObservable();

  private assignmentsAssigned = new ReplaySubject<PageLink>(1);
  public assignmentsAssigned$ = this.assignmentsAssigned.asObservable();

  private assignmentsManaged = new ReplaySubject<PageLink>(1);
  public assignmentsManaged$ = this.assignmentsManaged.asObservable();

  private training = new ReplaySubject<PageLink>(1);
  public training$ = this.training.asObservable();

  private lounge = new ReplaySubject<PageLink>(1);
  public lounge$ = this.lounge.asObservable();

  private insight = new ReplaySubject<PageLink>(1);
  public insight$ = this.insight.asObservable();

  private currentUrl = new ReplaySubject<string>();
  public currentUrl$ = this.currentUrl.asObservable();

  public setFindContent(data: PageLink): void {
    this.findContent.next(data);
  }

  public setEvaluation(data: PageLink): void {
    this.evaluation.next(data);
  }

  public setAssignmentsAssigned(data: PageLink): void {
    this.assignmentsAssigned.next(data);
  }

  public setAssignmentsManaged(data: PageLink): void {
    this.assignmentsManaged.next(data);
  }

  public setTraining(data: PageLink): void {
    this.training.next(data);
  }

  public setLounge(data: PageLink): void {
    this.lounge.next(data);
  }

  public setInsight(data: PageLink): void {
    this.insight.next(data);
  }

  public setCurrentUrl(data: string): void {
    this.currentUrl.next(data);
  }
}
