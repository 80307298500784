export const environment = {
  production: true,
  availableLanguages: ['en', 'da-DK', 'no-NO', 'sv-SE'],
  fallbackLanguage: 'en',
  defaultLanguage: 'da-DK',
  includeStoreDevTools: true,
  version: '20240916.2',
  appConfigurationConnectionString: 'https://bffgateway.dev.alineadigital.dk/api/v1/platform',
};

