import { SessionStorageNames } from '@core/store/enums';
import { GUID } from '@shared-types/guid.type';

export function getLocalStorageKeyForGlobalTeamId(userId: GUID): string {
  return [SessionStorageNames.GlobalTeamId, userId].join('-');
}

export function getLocalStorageKeyForGlobalSubTeamId(userId: GUID): string {
  return [SessionStorageNames.GlobalSubTeamId, userId].join('-');
}
