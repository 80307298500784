import { JsonPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';

import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  standalone: true,
  imports: [ErrorMessageComponent, TranslateSharedModule, NgIf, JsonPipe],
  selector: 'app-server-error-message',
  templateUrl: './server-error-message.component.html',
  styleUrls: ['./server-error-message.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerErrorMessageComponent {
  @Input() heading: string = '';
  @Input() serverInteractionState!: ServerInteractionState;
  @Input() trackingPrefix?: string;
  @Input() allowRetry: boolean = false;

  @Output() retry: EventEmitter<void> = new EventEmitter<void>();

  public onRetry(): void {
    this.retry.emit();
  }
}
