import { GUID } from '@shared-types/guid.type';

/**
 * A common function for tracking objects by their IDs in template loops.
 */
export function trackById(index: number, obj: { id: GUID }): GUID {
  return obj.id;
}

/**
 * A common function for tracking objects by their names in template loops.
 */
export function trackByName(index: number, obj: { name: string }): string {
  return obj.name;
}
