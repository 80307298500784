import { Directive, HostListener, Input } from '@angular/core';
import { NavigationService } from '@core/services/navigation.service';
import { RouterLinkCommands } from '@shared-types/router-link-command.type';

@Directive({
  standalone: true,
  selector: '[appBackButton]',
})
export class BackButtonDirective {
  @Input() appBackButton: RouterLinkCommands = '/';

  constructor(private navigationService: NavigationService) {}

  @HostListener('click')
  onClick(): void {
    this.navigationService.back(this.appBackButton);
  }
}
