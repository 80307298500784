import { UserState } from '@app/user/interfaces/user-state.interface';
import { userFeatureKey } from '@app/user/store/user.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

export const selectUserServerInteractionState = createSelector(
  selectUserState,
  (state: UserState) => state.userServerInteractionState,
);

export const selectCurrentUser = createSelector(selectUserState, (state: UserState) => state?.user);

export const isLoggedIn = createSelector(selectCurrentUser, (user) => !!user);

export const hasAccess = createSelector(selectCurrentUser, (user) => !!user?.hasAccess);

export const isTeacher = createSelector(
  selectCurrentUser,
  hasAccess,
  (user, hasAccess) => !!user?.isTeacher && hasAccess,
);

export const isStudent = createSelector(
  selectCurrentUser,
  hasAccess,
  (user, hasAccess) => !user?.isTeacher && hasAccess,
);

export const isAlineaUser = createSelector(selectCurrentUser, (user) => !!user?.isAlineaUser);

export const selectCurrentUserId = createSelector(selectCurrentUser, (user) => user?.userId);

export const selectCurrentUserInitials = createSelector(selectCurrentUser, (user) => user?.initials);

export const selectCurrentUserDisplayName = createSelector(selectCurrentUser, (user) => user?.displayName);

export const selectCurrentUserFirstName = createSelector(selectCurrentUser, (user) => user?.firstName);

export const selectAccessToken = createSelector(selectCurrentUser, (user) => user?.accessToken);

export const selectExpiresAt = createSelector(selectCurrentUser, (user) => user?.expiresAt);

export const selectContextIdentifier = createSelector(selectCurrentUser, (user) => user?.contextIdentifier);

export const selectInstitutionNumber = createSelector(selectCurrentUser, (user) => user?.institutionNumber);

export const selectCurrentUserInstitutionId = createSelector(selectCurrentUser, (user) => user?.institutionId);

export const selectRenewRefreshTokenServerInteractionState = createSelector(
  selectUserState,
  (state: UserState) => state.renewRefreshTokenServerInteractionState,
);
