import { Injectable } from '@angular/core';
import { ToastService } from '@app/toast/services/toast.service';
import { ToastEventType } from '@app/toast/toast-types';
import { ApiService } from '@core/services/api.service';
import { AzureAppConfigurationService } from '@core/services/azure-app-configuration.service';
import { Badges } from '@shared-data/badges';
import { Badge } from '@shared-interfaces/badge.interface';
import { GUID } from '@shared-types/guid.type';
import { camelToObject } from '@utils/string';
import { map, take } from 'rxjs';

import {
  AchievementsNotificationsMarkAsSeenResponse,
  AchievementsNotificationsResponse,
} from '../interfaces/achievements-page.interface';
import { markAsSeenContextType } from '../types/mark-as-seen-context-type';

@Injectable({
  providedIn: 'root',
})
export class AchievementsBadgesService {
  constructor(
    private api: ApiService,
    private toast: ToastService,
    public azureAppConfigurationService: AzureAppConfigurationService,
  ) {}

  public getAchievementsNotifications(): void {
    this.api
      .getBFFGateway<AchievementsNotificationsResponse>(`v1/achievements/notifications`)
      .pipe(
        take(1),
        map((response) => response.result),
      )
      .subscribe((notifications) => {
        notifications.forEach((notification) => {
          this.toast.displayMessage({
            badgeName: notification.badgeType,
            message: notification.titleKey,
            translateMessage: true,
            type: ToastEventType.SINGLE_BADGE,
          });
          this.markAsSeen(notification.id);
        });
      });
  }

  private markAsSeen(id: GUID, context: markAsSeenContextType = 'Toast', schoolYearId?: GUID): void {
    const schoolYearIdParam: string = schoolYearId ? `&schoolYearId=${schoolYearId}` : '';
    this.api
      .postBFFGateway<AchievementsNotificationsMarkAsSeenResponse>(
        `v1/achievements/${id}/mark-as-seen?context=${context}${schoolYearIdParam}`,
        {},
      )
      .pipe(
        map((response) => response.result),
        take(1),
      )
      .subscribe((notification) => {});
  }

  public getBadge(title: string): Badge {
    const BADGE_KEY: keyof typeof Badges = camelToObject(title) as keyof typeof Badges;
    return Badges[BADGE_KEY] ? Badges[BADGE_KEY] : Badges.UNDEFINED;
  }

  public markAsSeenInPage(id: GUID, schoolYearId?: GUID): void {
    this.markAsSeen(id, 'AchievementPage', schoolYearId);
  }
}
