import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getObjectProperty } from '@utils/object';

@Pipe({ standalone: true, name: 'readableArray' })
export class ReadableArrayPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(array: (string | { [key: string]: any })[], options?: { objectKey?: string; addQuotes?: boolean }): string {
    let stringArray: string[];

    if (options?.objectKey) {
      stringArray = array.map((item) =>
        getObjectProperty(item as { [key: string]: string }, options.objectKey! as string),
      ) as string[];
    } else {
      stringArray = array as string[];
    }

    let output = '';

    const lastIndex = stringArray.length - 1;

    stringArray.forEach((item, index) => {
      if (index > 0) {
        output += index === lastIndex ? ' ' + this.translate.instant('shared.pipes.readable-array.and') + ' ' : ', ';
      }

      output += options?.addQuotes ? this.addQuotes(item) : item;
    });

    return output;
  }

  private addQuotes(string: string): string {
    return `“${string}”`;
  }
}
