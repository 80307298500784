import { animate, style, transition, trigger } from '@angular/animations';
import { softBezier } from '@shared-animations/beziers.animation';

const transitionTiming = `.3s ${softBezier}`;
const modalHiddenStyle = style({
  transform: 'scale(.7)',
  opacity: 0,
});
const modalVisibleStyle = style({
  transform: 'scale(1)',
  opacity: 1,
});
const overlayHiddenStyle = style({ opacity: 0 });
const overlayVisibleStyle = style({ opacity: 1 });

export const modalEnterLeaveAnimation = trigger('modal-enter-leave', [
  transition(':enter', [modalHiddenStyle, animate(transitionTiming, modalVisibleStyle)]),
  transition(':leave', [animate(transitionTiming, modalHiddenStyle)]),
]);

export const modalOverlayEnterLeaveAnimation = trigger('modal-overlay-enter-leave', [
  transition(':enter', [overlayHiddenStyle, animate(transitionTiming, overlayVisibleStyle)]),
  transition(':leave', [animate(transitionTiming, overlayHiddenStyle)]),
]);
