import { NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { Icons } from '@shared-data/icons';
import { IconSize } from '@shared-enums/icon-size.enum';
import { Icon } from '@shared-interfaces/icon.interface';
import { InlineSVGSharedModule } from '@shared-modules/shared-inline-svg.module';
import { getIconPath } from '@shared-utils/icon.utils';

@Component({
  standalone: true,
  imports: [NgIf, InlineSVGSharedModule],
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() icon?: Icon;
  @Input() size?: IconSize;
  @Input() title?: string;

  @Input()
  @HostBinding('class.inline')
  inline?: boolean;

  @Input()
  @HostBinding('class.circle-filled')
  public circleFilled: boolean = false;

  @Input()
  @HostBinding('class.circle-outline')
  public circleOutline: boolean = false;

  /// TODO: UNUSED clean up?
  @HostBinding('attr.title')
  private get titleAttribute(): string | null {
    if (!this.title) {
      return null;
    }

    if (this.title.trim().length === 0) {
      return null;
    }

    return this.title;
  }

  /// TODO: UNUSED clean up?
  @HostBinding('attr.aria-hidden')
  private get ariaHidden(): true | null {
    if (!this.title) {
      return true;
    }

    if (this.title.trim().length === 0) {
      return true;
    }

    return null;
  }

  @HostBinding('style.--icon-size')
  get renderedIconSize(): string {
    /* TODO: MF2-4977
     * please review and fix code
     */
    let spacingSize = 4;
    switch (this.size) {
      case IconSize.DEFAULT:
        spacingSize = 4;
        break;
      case IconSize.MEDIUM:
        spacingSize = 6;
        break;
      case IconSize.LARGE:
        spacingSize = 8;
        break;
      case IconSize.XXLARGE:
        spacingSize = 24;
        break;
    }
    // return `var(--spacing-${spacingSize})`;
    spacingSize = spacingSize * 4;
    return `var(--spacing-${spacingSize}px)`;
  }

  get svgPxSize(): number {
    if (this.circle) {
      switch (this.size) {
        case IconSize.DEFAULT:
        case IconSize.MEDIUM:
          return 8;
        case IconSize.LARGE:
        case IconSize.XXLARGE:
          return 16;
      }
    }

    return this.size || IconSize.DEFAULT;
  }

  @HostBinding('style.--svg-size')
  get svgCssSize(): string {
    /* TODO: MF2-4977
     * please review and fix code
     */
    if (this.circle) {
      // return `var(--spacing-${this.svgPxSize / 4})`;
      return `var(--spacing-${this.svgPxSize}px)`;
    }

    return `var(--icon-size)`;
  }

  @HostBinding('class.circle')
  public get circle(): boolean {
    return this.circleFilled || this.circleOutline;
  }

  @HostBinding('class.is-blank')
  public get isBlank(): boolean {
    return !this.icon || this.icon === Icons.BLANK;
  }

  /// TODO: UNUSED clean up?
  @HostBinding('class')
  public get className(): string {
    if (this.size && this.size !== IconSize.DEFAULT) {
      return `icon-size-${this.size}`;
    }

    return '';
  }

  get iconPath(): string | null {
    if (!this.isBlank) {
      return getIconPath(this.icon!, this.svgPxSize);
    }
    return null;
  }
}
