import { createReducer, on } from '@ngrx/store';
import { arrayToDictionary } from '@utils/array';

import { GradeLevelsState } from '../interfaces/grade-levels-state.interface';
import * as GradeLevelsActions from './grade-levels.actions';

export const gradeLevelsFeatureKey = 'GradeLevelsState';

export const initialGradeLevelsState: GradeLevelsState = {
  gradeLevels: {},
  gradeLevelGroups: {},
  educationGroups: {},
};

export const gradeLevelsReducer = createReducer(
  initialGradeLevelsState,
  on(
    GradeLevelsActions.setGradeLevels,
    (state, action): GradeLevelsState => ({
      ...state,
      gradeLevels: arrayToDictionary(action.gradeLevels),
    }),
  ),
  on(
    GradeLevelsActions.setGradeLevelGroups,
    (state, action): GradeLevelsState => ({
      ...state,
      gradeLevelGroups: arrayToDictionary(action.gradeLevelGroups),
    }),
  ),
  on(
    GradeLevelsActions.setEducationGroups,
    (state, action): GradeLevelsState => ({
      ...state,
      educationGroups: arrayToDictionary(action.educationGroups),
    }),
  ),
);
