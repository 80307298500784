import { Injectable } from '@angular/core';
import { User } from '@app/user/interfaces/user.interface';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternalBackendApiService {
  constructor(private apiService: ApiService) {}

  getUserModel(): Observable<User | null> {
    return this.apiService.getInternalBackend('Home/GetUserModel');
  }

  renewRefreshToken(expiresAt?: number): Observable<User | null> {
    return this.apiService.getInternalBackend(`auth/refreshtokencycling?t=${expiresAt}`);
  }
}
