import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PageTypeLinkPipe } from '@app/page/pipes/page-type-link.pipe';
import { ButtonComponent, ButtonVariant } from '@shared-components/ui/button/button.component';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';

@Component({
  standalone: true,
  imports: [NgIf, ButtonComponent, PageTypeLinkPipe, TranslateSharedModule],
  selector: 'app-cart-empty-state',
  templateUrl: './cart-empty-state.component.html',
  styleUrls: ['./cart-empty-state.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartEmptyStateComponent {
  @Input() allowDismiss: boolean = false;
  @Input() allowNavigateToFindContent: boolean = false;
  @Output() dismiss = new EventEmitter<void>();

  ButtonVariant = ButtonVariant;

  onDismissClick(): void {
    this.dismiss.emit();
  }
}
