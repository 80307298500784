import { Injectable } from '@angular/core';
import { Tooltip } from '@shared-modules/tooltip/interfaces/tooltip.interface';
import { getObservableValueSync } from '@utils/observables';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private readonly tooltipsSubject: BehaviorSubject<Tooltip[]> = new BehaviorSubject([] as Tooltip[]);
  public readonly tooltips$ = this.tooltipsSubject.asObservable();

  constructor() {}

  public addTooltip(tooltip: Tooltip): void {
    let tooltips = [...getObservableValueSync<Tooltip[]>(this.tooltipsSubject)];
    const foundIndex = tooltips.findIndex((t) => t.elementId === tooltip.elementId);

    if (foundIndex === -1) {
      tooltips.push(tooltip);
    } else {
      tooltips[foundIndex] = tooltip;
    }

    this.tooltipsSubject.next(tooltips);
  }

  public removeTooltip(elementId: string): void {
    const tooltips = [...getObservableValueSync<Tooltip[]>(this.tooltipsSubject)];

    const index = tooltips.findIndex((tooltip) => tooltip.elementId === elementId);

    if (index !== -1) {
      tooltips.splice(index, 1);
      this.tooltipsSubject.next(tooltips);
    }
  }

  public updateTooltip(elementId: string, updates: Partial<Tooltip>): void {
    const tooltips = [...getObservableValueSync<Tooltip[]>(this.tooltipsSubject)];
    const index = tooltips.findIndex((tooltip) => tooltip.elementId === elementId);

    if (index !== -1) {
      tooltips[index] = { ...tooltips[index], ...updates };
      this.tooltipsSubject.next(tooltips);
    }
  }
}
