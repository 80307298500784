import { Injectable } from '@angular/core';

import { ImageExtended } from '../interfaces/image-extended.interface';
import { FocalPoint } from '../interfaces/umbraco/umb-focal-point.interface';

@Injectable({
  providedIn: 'root',
})
export class ImageExtendedMapper {
  mapToImageExtended(url: string, cropUrl: string, originCss: string, focalpoint: FocalPoint): ImageExtended {
    return {
      cropUrl: cropUrl,
      focalPoint: focalpoint,
      originCss: originCss,
      url: url,
    };
  }
}
