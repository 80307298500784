/// TODO: MF2-5149 re-think date utils/pipes
export function formatDateWithLocale(
  timestamp: Date,
  config: {
    format?: string;
  } = {},
): string {
  let { format = 'YYYY-MM-DD' } = config;

  if (!timestamp) {
    return 'No Date specified';
  }

  const date = new Date(timestamp);
  const _date = date.toLocaleString('default', { day: '2-digit' });
  const _month = date.toLocaleString('default', { month: '2-digit' });
  const _fullYear = date.toLocaleString('default', { year: 'numeric' });

  format = format.replace('DD', _date);
  format = format.replace('MM', _month);
  format = format.replace('YYYY', _fullYear);

  return format;
}

export function addHours(date: Date, h: number): Date {
  date.setTime(date.getTime() + h * 60 * 60 * 1000);
  return date;
}

export function cloneDate(date: Date): Date {
  return new Date(date.toISOString());
}

/**
 * Sets all time parts of a date object to 0.
 * Optionally skip any of the values by configuring the resetTimeOptions object.
 */
export function resetTime(
  date: Date,
  resetTimeOptions: {
    skipHours?: boolean;
    skipMinutes?: boolean;
    skipSeconds?: boolean;
    skipMilliseconds?: boolean;
  } = {},
): void {
  if (!resetTimeOptions.skipHours) {
    date.setHours(0);
  }

  if (!resetTimeOptions.skipMinutes) {
    date.setMinutes(0);
  }

  if (!resetTimeOptions.skipSeconds) {
    date.setSeconds(0);
  }

  if (!resetTimeOptions.skipMilliseconds) {
    date.setMilliseconds(0);
  }
}

export function convertSecondsToMinutes(seconds: any): number {
  return (seconds / 60) as number;
}

export function dateIsInThePast(dateToCheck: number | Date): boolean {
  const date_input: number = typeof dateToCheck === 'number' ? dateToCheck : Math.floor(dateToCheck.getTime() / 1000);
  try {
    const date_now = Math.floor(new Date().getTime() / 1000);
    return date_input < date_now;
  } catch (error) {
    return true;
  }
}
