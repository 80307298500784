import { Injectable } from '@angular/core';
import { GradeLevelsApiService } from '@app/grade-levels/services/grade-levels-api.service';
import * as LevelsActions from '@app/grade-levels/store/grade-levels.actions';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GradeLevelsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private gradeLevelsApi: GradeLevelsApiService,
  ) {}

  loadGradeLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LevelsActions.loadGradeLevels),
      exhaustMap(() => {
        this.store.dispatch(
          LevelsActions.setLoadGradeLevelsServerInteractionState({
            state: ServerInteractionStateType.LOADING,
          }),
        );

        return this.gradeLevelsApi.loadLevels().pipe(
          switchMap(({ gradeLevels, gradeLevelGroups, educationGroups }) => [
            LevelsActions.setLoadGradeLevelsServerInteractionState({
              state: ServerInteractionStateType.SUCCESS,
            }),
            LevelsActions.setGradeLevels({ gradeLevels }),
            LevelsActions.setGradeLevelGroups({ gradeLevelGroups }),
            LevelsActions.setEducationGroups({ educationGroups }),
          ]),
          catchError((error) => [
            LevelsActions.setLoadGradeLevelsServerInteractionState({
              state: ServerInteractionStateType.ERROR,
              error,
            }),
          ]),
        );
      }),
    ),
  );
}
