import { NgModule } from '@angular/core';
import { GradeLevelsEffects } from '@app/grade-levels/store/grade-levels.effects';
import { gradeLevelsFeatureKey, gradeLevelsReducer } from '@app/grade-levels/store/grade-levels.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    StoreModule.forFeature(gradeLevelsFeatureKey, gradeLevelsReducer),
    EffectsModule.forFeature([GradeLevelsEffects]),
  ],
})
export class GradeLevelsModule {}
