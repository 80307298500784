import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ManagedAssignmentItem } from '@app/assignments-managed/interfaces/managed-assignment-item.interface';
import { CartItem } from '@app/cart/interfaces/cart-item.interface';
import { ContentType } from '@app/content/enums/content-type.enum';
import { ContentTypeIconSize } from '@app/content/enums/content-type-icon-size.enum';
import { GradeLevelsModule } from '@app/grade-levels/grade-levels.module';
import { FormatGradeLevelSpanPipe } from '@app/grade-levels/pipes/format-grade-level-span.pipe';
import { GradeLevelsService } from '@app/grade-levels/services/grade-levels.service';
import { CardComponent } from '@shared-components/ui/card/card.component';
import { IconComponent } from '@shared-components/ui/icon/icon.component';
import { ToggleSwitchComponent } from '@shared-components/ui/toggle-switch/toggle-switch.component';
import { Icons } from '@shared-data/icons';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';
import { TooltipDirective } from '@shared-modules/tooltip/directives/tooltip.directive';
import { ReadableArrayPipe } from '@shared-pipes/readable-array.pipe';
import { camelToKebab } from '@utils/string';
import { createUniqueId } from '@utils/uuid';

import { ContentTypeIconComponent } from '../../../content/components/content-type-icon/content-type-icon.component';
import { PascalToKebabPipe } from '../../../shared-pipes/pascal-to-kebab.pipe';

@Component({
  standalone: true,
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,
    CardComponent,
    ReadableArrayPipe,
    TooltipDirective,
    FormatGradeLevelSpanPipe,
    TranslateSharedModule,
    GradeLevelsModule,
    ContentTypeIconComponent,
    PascalToKebabPipe,
    IconComponent,
    ToggleSwitchComponent,
  ],
})
export class CartItemComponent {
  @Input() data!: CartItem | ManagedAssignmentItem;

  // Toggle switch
  @Input() displayToggleSwitch: boolean = false;
  @Input() toggleSwitchDisabled: boolean = false;
  @Input() toggleSwitchValue: boolean = false;
  @Input() toggleSwitchLabel?: string;
  @Input() toggleSwitchTooltip?: string;

  @Output() public toggleSwitchChange: EventEmitter<boolean> = new EventEmitter();

  ContentTypeIconSize = ContentTypeIconSize;
  ContentType = ContentType;

  public toggleSwitchLabelId = createUniqueId('card-toggle-switch-label');
  public Icons = Icons;

  constructor(public gradeLevelsService: GradeLevelsService) {}

  @HostBinding('class')
  public get contentTypeClassName(): string {
    return `content-type-${camelToKebab(this.data.contentType)}`;
  }
  public get hasMetaCount(): boolean {
    return [
      ContentType.EXERCISE_COLLECTION,
      ContentType.TRAINING_COURSE,
      ContentType.WORKSHEET,
      ContentType.ACTIVITY,
    ].includes(this.data.contentType);
  }
  public get metaCount(): number {
    switch (this.data.contentType) {
      case ContentType.EXERCISE_COLLECTION:
        return this.data.exerciseCount;
      case ContentType.WORKSHEET:
        return this.data.sheetCount;
      case ContentType.ACTIVITY:
        return this.data.estimatedDuration;
    }

    return 0;
  }
}
