import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Measures the width of the scrollbar on the body and applies it to the global variable `--scrollbar-width`.
 * The applied technique relies on the fact that block elements takes up the entire width of the viewport minus the scroll bar width.
 * The measured value is updated everytime the size of the ruler changes.
 */
@Component({
  selector: 'app-scrollbar-ruler',
  standalone: true,
  imports: [CommonModule],
  template: '',
  styleUrls: ['./scrollbar-ruler.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollbarRulerComponent implements AfterViewInit, OnDestroy {
  private resizeObserver?: ResizeObserver;

  constructor(private elementRef: ElementRef) {}
  ngAfterViewInit() {
    const resizeObserver = new ResizeObserver((resizeObserverEntries) => {
      const currentValue = document.documentElement.style.getPropertyValue('--scrollbar-width');

      resizeObserverEntries.forEach(({ target }) => {
        const newValue = window.innerWidth - target.clientWidth + 'px';

        if (newValue !== currentValue) {
          document.documentElement.style.setProperty('--scrollbar-width', newValue);
        }
      });
    });

    resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver?.disconnect();
  }
}
