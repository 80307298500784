import { Injectable } from '@angular/core';
import { Team, TeamsState } from '@app/teams/interfaces/teams.interfaces';
import { TeamsApiService } from '@app/teams/services/teams-api.service';
import { getLocalStorageKeyForGlobalSubTeamId, getLocalStorageKeyForGlobalTeamId } from '@app/teams/store/teams.utils';
import * as UserSelectors from '@app/user/store/user.selectors';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import * as CoreSelectors from '@core/store/core.selectors';
import { shouldLoadData } from '@core/utils/server-interaction-state.utils';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';
import { of } from 'rxjs';
import { catchError, exhaustMap, switchMap, tap } from 'rxjs/operators';

import * as TeamsActions from './teams.actions';
import * as TeamsSelectors from './teams.selectors';

@Injectable()
export class TeamsEffects {
  constructor(
    private actions$: Actions,
    private teamsApiService: TeamsApiService,
    private teamsStore: Store<TeamsState>,
  ) {}

  loadTeams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TeamsActions.loadTeams),
      exhaustMap((action) => {
        this.teamsStore.dispatch(
          TeamsActions.setLoadTeamsServerInteractionState({ state: ServerInteractionStateType.LOADING }),
        );

        return this.teamsApiService.getTeamsAvailableToUser().pipe(
          switchMap((teamsResponse) => {
            const teams: Team[] = teamsResponse.map((team) => {
              return { ...team, gradeLevelIds: team.levelIds };
            }) as unknown as Team[];

            return [
              TeamsActions.setLoadTeamsServerInteractionState({ state: ServerInteractionStateType.SUCCESS }),
              TeamsActions.setTeams({
                teams,
              }),
              TeamsActions.applyGlobalTeamAndSubTeamFromLocalStorage(),
            ];
          }),
          catchError((error) => {
            return of(
              TeamsActions.setLoadTeamsServerInteractionState({ state: ServerInteractionStateType.ERROR, error }),
            );
          }),
        );
      }),
    );
  });

  loadTeamsIfNecessary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TeamsActions.loadTeamsIfNecessary),
      concatLatestFrom(() => [this.teamsStore.select(TeamsSelectors.selectLoadTeamsServerInteractionState)]),
      exhaustMap(([action, loadTeamsServerInteractionState]) => {
        if (shouldLoadData(loadTeamsServerInteractionState.state)) {
          return [TeamsActions.loadTeams()];
        }

        return [];
      }),
    );
  });

  setAllTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.setAllTeams),
      switchMap(({ teams, autoSelectNewTeam }) => {
        teams = [...teams].sort((a, b) => {
          return b.updated.getTime() - a.updated.getTime();
        });

        return [TeamsActions.autoUpdateGlobalTeam({ teams, autoSelectNewTeam }), TeamsActions.setTeams({ teams })];
      }),
    ),
  );

  autoUpdateGlobalTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.autoUpdateGlobalTeam),
      concatLatestFrom(() => [this.teamsStore.select(TeamsSelectors.selectGlobalTeamId)]),
      switchMap(([{ teams, autoSelectNewTeam }, globalTeamId]) => {
        if (teams.length === 0) {
          return [TeamsActions.unsetGlobalTeam()];
        }

        const hasOldGlobalTeam = globalTeamId && teams.some((team) => team.id === globalTeamId);

        // Only do anything if:
        // - the old global team doesn't exist anymore
        // - or automatic selection of a new team is requested
        if (!hasOldGlobalTeam || autoSelectNewTeam) {
          // Sort by last update
          let newTeams = [...teams].sort((a, b) => {
            return b.updated.getTime() - a.updated.getTime();
          });

          // Auto select the newest team
          return [TeamsActions.setGlobalTeam({ teamId: newTeams[0].id, globalTeamUserSelected: false })];
        }

        return [];
      }),
    ),
  );

  loadSubTeamsForTeam$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TeamsActions.loadSubTeamsForTeam),
      exhaustMap(({ teamId }) => {
        this.teamsStore.dispatch(
          TeamsActions.setSubTeamsForTeamServerInteractionState({ teamId, state: ServerInteractionStateType.LOADING }),
        );

        return this.teamsApiService.getSubTeamsForTeam(teamId).pipe(
          switchMap((subTeams) => {
            return [
              TeamsActions.setSubTeamsForTeamServerInteractionState({
                teamId,
                state: ServerInteractionStateType.SUCCESS,
              }),
              TeamsActions.setSubTeamsForTeam({ teamId, subTeams }),
            ];
          }),
          catchError((error) => {
            return of(
              TeamsActions.setSubTeamsForTeamServerInteractionState({
                teamId,
                state: ServerInteractionStateType.ERROR,
                error,
              }),
            );
          }),
        );
      }),
    );
  });

  loadSubTeamsForTeamIfNecessary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TeamsActions.loadSubTeamsForTeamIfNecessary),
      concatLatestFrom(({ teamId }) => [this.teamsStore.select(TeamsSelectors.selectSubTeamsForTeam(teamId))]),
      exhaustMap(([{ teamId }, subTeams]) => {
        if (
          teamId &&
          [ServerInteractionStateType.INITIAL, ServerInteractionStateType.ERROR].includes(
            subTeams.serverInteractionState.state,
          )
        ) {
          return [TeamsActions.loadSubTeamsForTeam({ teamId })];
        }

        return [];
      }),
    );
  });

  loadStudentsForTeam$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TeamsActions.loadStudentsForTeam),
      exhaustMap(({ teamId }) => {
        this.teamsStore.dispatch(
          TeamsActions.setStudentsForTeamServerInteractionState({ teamId, state: ServerInteractionStateType.LOADING }),
        );

        return this.teamsApiService.getStudentsForTeam(teamId).pipe(
          switchMap((students) => [
            TeamsActions.setStudentsForTeamServerInteractionState({
              teamId,
              state: ServerInteractionStateType.SUCCESS,
            }),
            TeamsActions.setStudentsForTeam({ teamId, students }),
          ]),
          catchError((error) =>
            of(
              TeamsActions.setStudentsForTeamServerInteractionState({
                teamId,
                state: ServerInteractionStateType.ERROR,
                error,
              }),
            ),
          ),
        );
      }),
    );
  });

  loadStudentsForTeamIfNecessary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TeamsActions.loadStudentsForTeamIfNecessary),
      concatLatestFrom(({ teamId }) => [
        this.teamsStore.select(TeamsSelectors.selectStudentsForTeamServerInteractionState(teamId)),
      ]),
      exhaustMap(([{ teamId }, serverInteractionState]) => {
        if (
          [ServerInteractionStateType.INITIAL, ServerInteractionStateType.ERROR].includes(serverInteractionState.state)
        ) {
          return [TeamsActions.loadStudentsForTeam({ teamId })];
        }

        return [];
      }),
    );
  });

  setGlobalTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.setGlobalTeam),
      concatLatestFrom(() => [this.teamsStore.select(UserSelectors.selectCurrentUserId)]),
      switchMap(([{ teamId }, userId]) => {
        if (teamId) {
          localStorage.setItem(getLocalStorageKeyForGlobalTeamId(userId!), teamId);
        }

        return [TeamsActions.loadSubTeamsForTeamIfNecessary({ teamId }), TeamsActions.unsetGlobalSubTeam()];
      }),
    ),
  );

  unsetGlobalTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.unsetGlobalTeam),
      concatLatestFrom(() => [this.teamsStore.select(UserSelectors.selectCurrentUserId)]),
      switchMap(([action, userId]) => {
        localStorage.removeItem(getLocalStorageKeyForGlobalTeamId(userId!));

        return [TeamsActions.unsetGlobalSubTeam()];
      }),
    ),
  );

  setGlobalSubTeam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamsActions.setGlobalSubTeam),
        concatLatestFrom(() => [this.teamsStore.select(UserSelectors.selectCurrentUserId)]),
        tap(([action, userId]) => {
          const localStorageKey = getLocalStorageKeyForGlobalSubTeamId(userId!);

          if (action.subTeamId) {
            localStorage.setItem(localStorageKey, action.subTeamId);
          } else {
            localStorage.removeItem(localStorageKey);
          }
        }),
      ),
    { dispatch: false },
  );

  unsetGlobalSubTeam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TeamsActions.unsetGlobalSubTeam),
        concatLatestFrom(() => [this.teamsStore.select(UserSelectors.selectCurrentUserId)]),
        tap(([action, userId]) => {
          localStorage.removeItem(getLocalStorageKeyForGlobalSubTeamId(userId!));
        }),
      ),
    { dispatch: false },
  );

  applyGlobalTeamAndSubTeamFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.applyGlobalTeamAndSubTeamFromLocalStorage),
      concatLatestFrom(() => [
        this.teamsStore.select(UserSelectors.selectCurrentUserId),
        this.teamsStore.select(TeamsSelectors.selectTeams),
      ]),
      exhaustMap(([action, userId, teams]) => {
        const teamId: GUID | null = localStorage.getItem(getLocalStorageKeyForGlobalTeamId(userId!)) as GUID | null;
        const subTeamId: GUID | null = localStorage.getItem(
          getLocalStorageKeyForGlobalSubTeamId(userId!),
        ) as GUID | null;

        return [
          // Apply the team ID if present
          teamId
            ? TeamsActions.setGlobalTeam({ teamId, globalTeamUserSelected: false })
            : TeamsActions.unsetGlobalTeam(),
          // Apply the sub-team ID if both a team ID and sub-team ID is present
          teamId && subTeamId ? TeamsActions.setGlobalSubTeam({ subTeamId }) : TeamsActions.unsetGlobalSubTeam(),
          // Auto update the global team ID based on the current teams
          TeamsActions.autoUpdateGlobalTeam({ teams }),
        ];
      }),
    ),
  );
}
