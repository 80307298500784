import { createReducer, on } from '@ngrx/store';

import * as ZIndexActions from './z-index.actions';
import { initialZIndexState } from './z-index.state';

export const zIndexFeatureKey = 'zIndexState';

export const zIndexReducer = createReducer(
  initialZIndexState,
  on(ZIndexActions.setItem, (state, action) => {
    return {
      ...state,
      items: [
        ...state.items.filter((x) => x.id !== action.id),
        {
          id: action.id,
          zIndex: state.currentZIndex + 2,
        },
      ],
    };
  }),

  on(ZIndexActions.unsetItem, (state, action) => {
    return {
      ...state,
      items: [...state.items.filter((x) => x.id !== action.id)],
    };
  }),

  on(ZIndexActions.setCurrentZIndex, (state, action) => {
    return {
      ...state,
      currentZIndex: action.zIndex,
    };
  }),
);
