<app-tooltip
  *ngFor="let tooltip of tooltipService.tooltips$ | async; trackBy: trackByElementId"
  [tooltip]="tooltip"
  [@tooltipAnimation]="{
    value: true,
    params: {
      translateYEnter: tooltip.direction === 'down' ? '0px' : '-100%',
      translateYLeave:
        tooltip.direction === 'down' ? 'calc(-1 * var(--spacing-24px))' : 'calc(var(--spacing-24px) - 100%)'
    }
  }"
></app-tooltip>
