import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { UserService } from '@app/user/services/user.service';
import { ModalButtonsComponent } from '@shared-components/modals/modal-buttons/modal-buttons.component';
import { ModalContentLayoutComponent } from '@shared-components/modals/modal-content-layout/modal-content-layout.component';
import { ButtonComponent } from '@shared-components/ui/button/button.component';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';

@Component({
  selector: 'app-not-authorized-modal-content',
  standalone: true,
  imports: [CommonModule, ModalContentLayoutComponent, ModalButtonsComponent, ButtonComponent, TranslateSharedModule],
  templateUrl: './not-authorized-modal-content.component.html',
  styleUrls: ['./not-authorized-modal-content.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotAuthorizedModalContentComponent {
  @Output() okClick = new EventEmitter<void>();

  constructor(protected userService: UserService) {}
}
