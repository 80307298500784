import { NgIf } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Icons } from '@shared-data/icons';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';

import { ButtonComponent } from '../button/button.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';

export enum MessageVariants {
  DEFAULT = 'default',
  ERROR = 'error',
  INVERTED = 'inverted',
}

@Component({
  standalone: true,
  imports: [NgIf, TranslateSharedModule, ButtonComponent, IconButtonComponent],
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  @HostBinding('class')
  @Input()
  variant?: MessageVariants;

  @Input() allowDismiss?: boolean;

  @Output() dismiss = new EventEmitter<void>();

  Icons = Icons;

  constructor() {
    this.variant = MessageVariants.DEFAULT;
    this.allowDismiss = false;
  }

  onDismissClick(): void {
    this.dismiss.emit();
  }
}
