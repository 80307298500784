import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { AssignedAssignmentItemProgress } from '@app/assignments-assigned/interfaces/assigned-assignment-item-progress.interface';
import { AssignedAssignmentProgressState } from '@app/assignments-shared/assignments-shared.enums';
import { ContentTypeIconComponent } from '@app/content/components/content-type-icon/content-type-icon.component';
import { DefaultBadge, OneOffBadge } from '@shared-interfaces/badge.interface';

@Component({
  standalone: true,
  imports: [NgIf, ContentTypeIconComponent],
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressCircleComponent {
  @Input() progress!: AssignedAssignmentItemProgress;
  @Input() badge!: OneOffBadge | DefaultBadge;

  public svgSize = 100;
  public progressStrokeWidth = 6;

  public get svgViewBox(): string {
    return `0 0 ${this.svgSize} ${this.svgSize}`;
  }

  public get displayProgress(): boolean {
    return !!this.progress && this.progress.state !== AssignedAssignmentProgressState.NOT_STARTED;
  }

  /**
   * The `stroke-dasharray` for filling out the progress circle.
   */
  public get strokeDashArray(): string {
    if (!this.progress || !this.displayProgress) {
      return '';
    }

    const circumference = Math.PI * (this.svgSize - this.progressStrokeWidth);

    const fill = (this.progress.stepsCompleted / this.progress.steps) * circumference;

    return `${fill.toString()} ${(circumference - fill).toString()}`;
  }
}
