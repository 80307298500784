<ng-container *ngIf="{ gradeLevels: gradeLevelsService.levels$ | async } as sub">
  <div class="card" [class.toggle-switch]="displayToggleSwitch">
    <div class="contentType-icon">
      <app-content-type-icon
        [contentType]="data.contentType"
        [contentTypeIconSize]="ContentTypeIconSize.LARGE"
      ></app-content-type-icon>
    </div>
    <div class="card-content">
      <h4 class="heading-4 clamp cart-item-title" data-line-clamp="2">{{ data.title }}</h4>
      <ng-template #topicsTooltipContent>
        <ul>
          <li *ngFor="let topic of data.topics">{{ topic.name }}</li>
        </ul>
      </ng-template>
      <p
        class="subtitle clamp"
        data-line-clamp="1"
        [appTooltip]="topicsTooltipContent"
        [appTooltipIfLineClamped]="true"
      >
        <ng-container *ngIf="data.gradeLevelIds.length">
          {{ data.gradeLevelIds ? (data.gradeLevelIds | formatGradeLevelSpan) : '' }}
        </ng-container>
        <ng-container *ngIf="hasMetaCount">
          <span class="content-type-meta-value">| {{ metaCount }}</span
          >&nbsp;<span class="content-type-meta-label">{{
            'content.content-type-count.' + (data.contentType | pascalToKebab) | translate: { count: metaCount }
          }}</span></ng-container
        >
        <ng-container *ngIf="data.topics.length">
          | {{ data.topics | readableArray: { objectKey: 'name' } }}
        </ng-container>
      </p>
      <div *ngIf="displayToggleSwitch" class="card-toggle-switch-area" data-testid="card-toggle-switch-area">
        <app-toggle-switch
          [labelledBy]="toggleSwitchLabelId"
          [state]="toggleSwitchValue"
          [disabled]="toggleSwitchDisabled"
          (stateChange)="toggleSwitchChange.emit($event)"
        ></app-toggle-switch>
        <span class="card-toggle-switch-label" [id]="toggleSwitchLabelId">
          <span [attr.aria-label]="toggleSwitchTooltip" [appTooltip]="toggleSwitchTooltip"
            >{{ toggleSwitchLabel }}
          </span>
        </span>
      </div>
    </div>
  </div>
</ng-container>
