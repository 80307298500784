import { NgModule, Optional, SkipSelf } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TrackingService } from '@app/tracking/services/tracking.service';
import { UserEffects } from '@app/user/store/user.effects';
import { userFeatureKey, userReducer } from '@app/user/store/user.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AppInitService } from './services/app-init.service';
import { CoreEffects } from './store/core.effects';
import { coreFeatureKey, coreReducer } from './store/core.reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(coreFeatureKey, coreReducer),
    EffectsModule.forFeature([CoreEffects]),
    StoreModule.forFeature(userFeatureKey, userReducer),
    EffectsModule.forFeature([UserEffects]),
  ],
  providers: [{ provide: 'Window', useValue: window }],
  exports: [],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private appInitService: AppInitService,
    private router: Router,
    private trackingService: TrackingService,
  ) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in `main.ts` only.');
    }

    this.appInitService.initializeApplication();

    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.trackingService.trackPageView(item.urlAfterRedirects);
      }
    });
  }
}
