import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouterLinkCommands } from '@shared-types/router-link-command.type';
import { getLastArrayItem } from '@utils/array';
import { createAbsolutePathFromRelativePath } from '@utils/path.utils';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Provides custom back button functionality with a built-in fallback path in case the user started on the current page.
 *
 * Inspired by: https://nils-mehlhorn.de/posts/angular-navigate-back-previous-page
 */
@Injectable({ providedIn: 'root' })
export class NavigationService {
  public readonly history: string[] = [];

  constructor(
    private router: Router,
    private location: Location,
  ) {}

  public initialize(): void {
    (<Observable<NavigationEnd>>this.router.events.pipe(filter((event) => event instanceof NavigationEnd))).subscribe(
      (event: NavigationEnd) => {
        if (getLastArrayItem(this.history) !== event.urlAfterRedirects) {
          this.history.push(event.urlAfterRedirects);
        }
      },
    );
  }

  /**
   * Returns to the previous page if a previous page exists. Falls back to the given fallback path or `/` if no fallback path is given.
   */
  back(fallbackPath: RouterLinkCommands = '/'): void {
    const canGoBack = this.history.length > 1;

    this.history.pop();

    if (canGoBack) {
      this.router.navigateByUrl(getLastArrayItem(this.history)!);
    } else {
      this.router.navigate(createAbsolutePathFromRelativePath(fallbackPath, this.location.path()));
    }
  }

  /**
   * Creates an absolute path array based on the current path and the given relative path
   */
  public createAbsolutePath(relativePath: RouterLinkCommands): string[] {
    return createAbsolutePathFromRelativePath(relativePath, this.location.path());
  }
}
