import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getObservableValueSync } from '@utils/observables';
import { createUniqueId } from '@utils/uuid';

import { ToastMessage } from '../interfaces/toast-message.interface';
import { ToastMessageInstance } from '../interfaces/toast-message-instance.interface';
import * as ToastActions from '../store/toast.actions';
import * as ToastSelectors from '../store/toast.selectors';
import { selectAllMessages } from '../store/toast.selectors';
import { ToastState } from '../store/toast.state';
import { ToastEventType } from '../toast-types';

/** A service for handling toast messages */
@Injectable({ providedIn: 'root' })
export class ToastService {
  /** Observable of all toast messages */
  public messages$ = this.store.select(selectAllMessages);
  public defaultExpirationTime$ = this.store.select(ToastSelectors.selectDefaultExpirationTime);

  constructor(private store: Store<ToastState>) {}

  /** Displays the given toast message and returns the ID created to represent the message.
   * @param message either a string or configured {@link ToastMessage} object
   * @returns ID that represent the created message */
  public displayMessage(message: string | ToastMessage): string {
    if (typeof message === 'string') {
      message = { message: message, type: ToastEventType.INFO };
    }

    const preparedMessage = this.prepareMessageForStore(message);

    this.store.dispatch(ToastActions.addMessage({ message: preparedMessage }));

    return preparedMessage.id;
  }

  /** Dispatches an action to remove the specific message
   * @param id the id of the specific message to remove */
  public removeMessage(id: string): void {
    this.store.dispatch(ToastActions.removeMessage({ id }));
  }

  /** Dispatches an action to pause all message expirations */
  public pause(): void {
    this.store.dispatch(ToastActions.pauseMessageExpiration());
  }

  /** Dispatches an action to continue all message expirations */
  public continue(): void {
    this.store.dispatch(ToastActions.continueMessageExpiration());
  }

  /** Prepares the message for the store by adding specific icons and colors dependent on the specific type of message
   * @param message The message to prepare for the store
   * @returns A {@link ToastMessageInstance} object created from the param. */
  private prepareMessageForStore(message: ToastMessage): ToastMessageInstance {
    const id = createUniqueId('toast-message');

    let messageInstance: ToastMessageInstance = { ...message, id };

    if (messageInstance.duration === undefined) {
      messageInstance.duration = getObservableValueSync(this.defaultExpirationTime$)!;
    }

    return messageInstance;
  }
}
