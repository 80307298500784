import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { getContentTypeDescription, getContentTypeName } from '@app/content/content.utils';
import { ContentType } from '@app/content/enums/content-type.enum';
import { ContentTypeIconSize } from '@app/content/enums/content-type-icon-size.enum';
import { camelToKebab, pascalToKebab } from '@app/utils/string';
import { InlineSVGSharedModule } from '@shared-modules/shared-inline-svg.module';
import { TranslateSharedModule } from '@shared-modules/shared-translate.module';
import { TooltipDirective } from '@shared-modules/tooltip/directives/tooltip.directive';
import { PascalToKebabPipe } from '@shared-pipes/pascal-to-kebab.pipe';

@Component({
  standalone: true,
  imports: [NgIf, InlineSVGSharedModule, TranslateSharedModule, TooltipDirective, PascalToKebabPipe],
  selector: 'app-content-type-icon',
  templateUrl: './content-type-icon.component.html',
  styleUrls: ['./content-type-icon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentTypeIconComponent {
  @Input() contentType?: ContentType;
  @Input() contentTypeIconSize: ContentTypeIconSize = ContentTypeIconSize.SMALL;

  @HostBinding('class')
  public get contentTypeClassName(): string {
    return this.contentType ? `content-type-${camelToKebab(this.contentType)}` : '';
  }

  @HostBinding('class.large-icon')
  public get contentIconSize(): boolean {
    return this.contentTypeIconSize === ContentTypeIconSize.LARGE;
  }

  @HostBinding('class.is-blank')
  public get isBlank(): boolean {
    return !this.contentType;
  }

  public get iconPath(): string | null {
    if (!this.contentType) {
      return null;
    }

    if (
      this.contentTypeIconSize === ContentTypeIconSize.LARGE &&
      [ContentType.EXERCISE_COLLECTION, ContentType.LESSON, ContentType.TRAINING_COURSE].includes(this.contentType)
    ) {
      return `content-type-icons/24/${pascalToKebab(this.contentType)}.svg`;
    }

    return `content-type-icons/16/${pascalToKebab(this.contentType)}.svg`;
  }

  public get contentTypeName(): string {
    return getContentTypeName(this.contentType);
  }

  public get contentTypeDescription(): string {
    return getContentTypeDescription(this.contentType);
  }
}
