import { Injectable } from '@angular/core';
import { getCustomUrl, getUmbracoAlign, getUmbracoWidth } from '@utils/umbraco';

import { Hero } from '../interfaces/hero.interface';
import { HeroMenu } from '../interfaces/hero-menu.interface';
import { HeroMenuItem } from '../interfaces/hero-menu-item.interface';
import { UmbPage } from '../interfaces/umbraco/umb-page.interface';

@Injectable({
  providedIn: 'root',
})
export class HeroMapper {
  mapHeroFromApi(page: UmbPage): Hero | undefined {
    let result: Hero | undefined;

    if (page.hero) {
      let title: string | undefined;
      let description: string | undefined;
      let menu: HeroMenu | undefined;

      if (page.hero.usePageText) {
        title = page.title;
        description = page.teaser;
      } else {
        title = page.hero.headline;
        description = page.hero.description;
      }

      if (page.hero.menu) {
        menu = {
          title: 'Vælg trin',
          items: [],
          alias: page.hero.menu.alias,
          type: page.hero.menu.type,
        };

        for (const row of page.hero.menu.rows) {
          for (const column of row.columns) {
            for (const item of column.value?.items) {
              const elem: HeroMenuItem = {
                id: item.id,
                title: item.name,
                url: item.customUrl ? getCustomUrl(item.customUrl) : item.url,
                selected: item.id === page.id,
              };

              menu.items.push(elem);
              if (item.id === page.id) {
                menu.title = elem.title;
              }
            }
          }
        }
      }

      result = {
        textAlign: getUmbracoAlign(page.hero.textAlign),
        align: getUmbracoAlign(page.hero.sectionAlign),
        title: title,
        description: description,
        coverImage: page.hero.backgroundImageUrl,
        width: getUmbracoWidth(page.hero.width),
        menu: menu,
      };
    }

    return result;
  }
}
