import { Injectable } from '@angular/core';
import { AzureAppConfiguration } from '@core/interfaces/azure-app-configuration/azure-app-configuration.interface';
import { map, Observable } from 'rxjs';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AzureAppConfigurationApiService {
  constructor(private apiService: ApiService) {}

  getAzureAppConfiguration(): Observable<AzureAppConfiguration> {
    return this.apiService.getBFFGatewayForAzureConfig<{ result: AzureAppConfiguration }>().pipe(
      map((data) => {
        return data.result;
      }),
    );
  }
}
