<app-background-graphics
  [isTeacher]="!!(userService.isTeacher$ | async)"
  [isWinter]="isWinter"
  [hasLoadedUserData]="!!(userService.hasLoadedUserData$ | async)"
  [backgroundColor]="(backgroundColor$ | async) ?? undefined"
  [backgroundImageUrl]="(backgroundImageUrl$ | async) ?? undefined"
  [showGraphics]="!!(userService.hasLoadedUserData$ | async) && !!(showGraphics$ | async)"
  [showPattern]="!!(userService.hasLoadedUserData$ | async) && !!(showPattern$ | async)"
  [inContainer]="inContainer"
></app-background-graphics>
