import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { ExerciseCollectionPreview } from '@app/find-content/interfaces/exercise-collection-preview.interface';
import { SearchResultItem } from '@app/find-content/interfaces/search-result-item.interface';
import { TrainingCoursePreview } from '@app/find-content/interfaces/training-course-preview.interface';
import { SuggestionResponse } from '@shared-components/search-field/suggestion-response.interface';
import { GUID } from '@shared-types/guid.type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppliedFindContentFilters } from '../interfaces/applied-find-content-filters.interface';
import { FindContentSearchResponse } from '../interfaces/find-content-search-response.interface';
import {
  FindContentAppliedFilter,
  MF24986FindContentSearchResponse,
  SearchSuggestionPayload,
} from '../interfaces/MF2-4986-find-content/MF2-4986-find-content.interface';

@Injectable({
  providedIn: 'root',
})
export class FindContentApiService {
  constructor(private api: ApiService) {}

  public search(body: AppliedFindContentFilters): Observable<FindContentSearchResponse> {
    return this.api.postSearchContent('SearchContent', body);
  }

  // TODO replace old api call with this when MF2-4986 is done
  public MF24986search(body: FindContentAppliedFilter): Observable<MF24986FindContentSearchResponse> {
    return this.api
      .postBFFGateway<{ result: MF24986FindContentSearchResponse }>(`v1/search`, body)
      .pipe(map((response) => response.result));
  }

  public getExerciseCollectionPreview(exerciseCollectionId: GUID): Observable<ExerciseCollectionPreview> {
    return this.api
      .getBFFGateway<{ result: ExerciseCollectionPreview }>(`v1/exercise-collections/${exerciseCollectionId}/preview`)
      .pipe(map((response) => response.result));
  }

  public getTrainingCoursePreview(trainingCourseId: GUID): Observable<TrainingCoursePreview> {
    return this.api
      .getBFFGateway<{ result: TrainingCoursePreview }>(`v1/training-courses/${trainingCourseId}/preview`)
      .pipe(map((response) => response.result));
  }

  requestSuggestions(textToComplete: string): Observable<SuggestionResponse> {
    return this.api.postSearchContent('AutoCompleteTopic', {
      query: textToComplete,
    });
  }

  MF24986requestSuggestions(body: SearchSuggestionPayload): Observable<string[]> {
    return this.api
      .postBFFGateway<{ result: string[] }>(`v1/search/auto-complete`, body)
      .pipe(map((response) => response.result));
  }

  searchByIds(ids: GUID[]): Observable<SearchResultItem[]> {
    const urlSearchParams = new URLSearchParams();

    ids.forEach((id) => {
      urlSearchParams.append('id', id);
    });

    return this.api
      .getBFFGateway<{ result: SearchResultItem[] }>(`v1/search/by-ids?${urlSearchParams.toString()}`)
      .pipe(map((response) => response.result));
  }
}
