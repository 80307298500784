import { Injectable, OnDestroy } from '@angular/core';
import { Student } from '@app/teams/interfaces/student.interfaces';
import { SubTeamsForTeam } from '@app/teams/interfaces/sub-team.interfaces';
import { Team, TeamsState } from '@app/teams/interfaces/teams.interfaces';
import * as TeamsActions from '@app/teams/store/teams.actions';
import * as TeamsSelectors from '@app/teams/store/teams.selectors';
import { UserService } from '@app/user/services/user.service';
import { ServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { shouldLoadData } from '@core/utils/server-interaction-state.utils';
import { Store } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TeamsService implements OnDestroy {
  public noTeams$: Observable<boolean> = this.teamsStore.select(TeamsSelectors.selectNoTeams);
  public teams$: Observable<Team[]> = this.teamsStore.select(TeamsSelectors.selectTeams);

  public globalTeamId$: Observable<GUID | undefined> = this.teamsStore.select(TeamsSelectors.selectGlobalTeamId);
  public globalTeamName$: Observable<string | undefined> = this.teamsStore.select(TeamsSelectors.selectGlobalTeamName);
  public globalTeam$: Observable<Team | undefined> = this.teamsStore.select(TeamsSelectors.selectGlobalTeam);
  public globalTeamUserSelected$: Observable<boolean> = this.teamsStore.select(TeamsSelectors.globalTeamUserSelected);
  public alertTeamChange$: Observable<boolean> = this.teamsStore.select(TeamsSelectors.selectAlertTeamChange);

  public globalTeamLevelIds$: Observable<GUID[] | undefined> = this.teamsStore.select(
    TeamsSelectors.selectGlobalTeamLevelIds,
  );

  public globalSubTeamId$: Observable<GUID | undefined | null> = this.teamsStore.select(
    TeamsSelectors.selectGlobalSubTeamId,
  );
  public loadTeamsServerInteractionState$: Observable<ServerInteractionState> = this.teamsStore.select(
    TeamsSelectors.selectLoadTeamsServerInteractionState,
  );

  private loadTeamsSubscriber: Subscription;

  constructor(
    private teamsStore: Store<TeamsState>,
    public userService: UserService,
  ) {
    this.loadTeamsSubscriber = combineLatest([userService.user$, this.loadTeamsServerInteractionState$]).subscribe(
      ([user, loadTeamsServerInteractionState]) => {
        if (user?.isTeacher && shouldLoadData(loadTeamsServerInteractionState.state)) {
          this.loadTeams();
        }
      },
    );
  }

  public ngOnDestroy() {
    this.loadTeamsSubscriber.unsubscribe();
  }

  public loadTeams(): void {
    this.teamsStore.dispatch(TeamsActions.loadTeams());
  }

  public alertTeamChange(change: boolean) {
    this.teamsStore.dispatch(TeamsActions.setAlertTeamChange({ change }));
  }

  public setTeams(teams: Team[], autoSelectNewTeam?: boolean): void {
    this.teamsStore.dispatch(TeamsActions.setAllTeams({ teams, autoSelectNewTeam }));
  }

  public loadTeamsIfNecessary(): void {
    this.teamsStore.dispatch(TeamsActions.loadTeamsIfNecessary());
  }

  public loadSubTeamsForTeamIfNecessary(teamId: GUID): void {
    this.teamsStore.dispatch(TeamsActions.loadSubTeamsForTeamIfNecessary({ teamId }));
  }

  public loadStudentsForTeamIfNecessary(teamId: GUID): void {
    this.teamsStore.dispatch(TeamsActions.loadStudentsForTeamIfNecessary({ teamId }));
  }

  public loadStudentsForTeam(teamId: GUID): void {
    this.teamsStore.dispatch(TeamsActions.loadStudentsForTeam({ teamId }));
  }

  public getTeam(teamId: GUID): Observable<Team | undefined> {
    return this.teamsStore.select(TeamsSelectors.selectTeam(teamId));
  }

  public getSubTeamsForTeam(teamId: GUID, doNotLoadIfMissing?: boolean): Observable<SubTeamsForTeam> {
    if (!doNotLoadIfMissing) {
      this.teamsStore.dispatch(TeamsActions.loadSubTeamsForTeamIfNecessary({ teamId }));
    }

    return this.teamsStore.select(TeamsSelectors.selectSubTeamsForTeam(teamId));
  }

  public getStudentsForTeam(
    teamId: GUID,
    doNotLoadIfMissing?: boolean,
  ): Observable<{ students: Student[]; serverInteractionState: ServerInteractionState }> {
    if (!doNotLoadIfMissing) {
      this.loadStudentsForTeamIfNecessary(teamId);
    }

    return combineLatest([
      this.teamsStore.select(TeamsSelectors.selectStudentsForTeam(teamId)),
      this.teamsStore.select(TeamsSelectors.selectStudentsForTeamServerInteractionState(teamId)),
    ]).pipe(map(([students, serverInteractionState]) => ({ students, serverInteractionState })));
  }

  public setGlobalTeam(teamId: GUID, globalTeamUserSelected: boolean): void {
    this.teamsStore.dispatch(TeamsActions.setGlobalTeam({ teamId, globalTeamUserSelected }));
  }

  public unsetGlobalTeam(): void {
    this.teamsStore.dispatch(TeamsActions.unsetGlobalTeam());
  }

  public setGlobalSubTeam(subTeamId: GUID | undefined): void {
    if (subTeamId) {
      this.teamsStore.dispatch(TeamsActions.setGlobalSubTeam({ subTeamId }));
    } else {
      this.teamsStore.dispatch(TeamsActions.unsetGlobalSubTeam());
    }
  }
}
