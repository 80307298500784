import { UserState } from '@app/user/interfaces/user-state.interface';
import { initialServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { createReducer, on } from '@ngrx/store';

import * as UserActions from './user.actions';

export const userFeatureKey = 'userState';

export const initialUserState: UserState = {
  user: null,
  userServerInteractionState: initialServerInteractionState,
  renewRefreshTokenServerInteractionState: initialServerInteractionState,
};

export const userReducer = createReducer(
  initialUserState,
  on(UserActions.loadUserSuccess, (state, action) => ({
    ...state,
    user: action.user,
  })),
  on(UserActions.removeUser, (state) => ({ ...state, user: null })),
  on(UserActions.setUserServerInteractionState, (state, action) => ({
    ...state,
    userServerInteractionState: { state: action.state, error: action.error || null },
  })),
  on(UserActions.setRenewRefreshTokenServerInteractionState, (state, action) => ({
    ...state,
    renewRefreshTokenServerInteractionState: { state: action.state, error: action.error || null },
  })),
);
