import { hasOwnProperty } from '@utils/object';

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function lowercaseFirstLetter(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function truncate(string: string, limit = 25, ellipsis = '...'): string {
  // split on characters
  return string.length > limit ? string.substr(0, limit - ellipsis.length) + ellipsis : string;
  // split on words
  // return string.split(' ').length  > limit ? string.split(' ').splice(0, limit).join(' ') + ellipsis : string;
}

/**
 * Removes any HTML or MathML from the given string.
 */
export function removeTags(string: string): string {
  const doc = new DOMParser().parseFromString(string, 'text/html');

  return doc.body.textContent || '';
}

/**
 * Converts any camel case string to a kebab case string.
 */
export function camelToKebab(string: string, cleanStart = true): string {
  const output = string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();

  if (cleanStart) {
    return output.replace(/^-*/, '');
  }

  return output;
}

/**
 * Converts any camel case string to object reference string.
 */
export function camelToObject(string: string, cleanStart = true): string {
  const output = string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1_$2').toUpperCase();

  if (cleanStart) {
    return output.replace(/^_*/, '');
  }

  return output;
}

/**
 * Converts any kebab case string to a camel case string.
 */
export function kebabToCamel(string: string): string {
  return string.replace(/-./g, (x) => x[1].toUpperCase());
}

/**
 * Converts any kebab case string to a pascal case string.
 */
export function kebabToPascal(string: string): string {
  return capitalizeFirstLetter(kebabToCamel(string));
}

/**
 * Converts any pascal case string to a kebab case string.
 */
export function pascalToKebab(string: string): string {
  return camelToKebab(lowercaseFirstLetter(string));
}

/**
 * Converts any camel case string to a pascal case string.
 */
export function camelToPascal(string: string): string {
  return capitalizeFirstLetter(string);
}

/**
 * Converts any pascal case string to a camel case string.
 */
export function pascalToCamel(string: string): string {
  return lowercaseFirstLetter(string);
}

/**
 * Removes any special characters, converts to lowercase and replaces spaces with `-`
 */
export function urlFriendlyString(string: string): string {
  return string.replace(urlEncodingCharactersRegex, '').replace(/\s/g, '-').toLowerCase();
}

/**
 * Source: https://secure.n-able.com/webhelp/nc_9-1-0_so_en/content/sa_docs/api_level_integration/api_integration_urlencoding.html
 */
const urlEncodingCharacters: string[] = [
  '$',
  '&',
  '+',
  ',',
  '/',
  ':',
  ';',
  '=',
  '?',
  '@',
  '"',
  "'",
  '<',
  '>',
  '#',
  '%',
  '{',
  '}',
  '|',
  '\\',
  '^',
  '~',
  '[',
  ']',
  '`',
];

/**
 * A regex that matches all characters that aren't URL safe
 */
const urlEncodingCharactersRegex = new RegExp('/' + urlEncodingCharacters.map((char) => '\\' + char).join('|') + '/g');

/**
 * Converts anything to a string
 * @param x
 */
export function stringify(x: any): string {
  switch (typeof x) {
    case 'string':
      return x;
    case 'function':
      return 'function';
    case 'undefined':
      return 'undefined';
  }

  if (x === null) {
    return 'null';
  }

  if (hasOwnProperty(x, 'toString')) {
    return x.toString();
  }

  return JSON.stringify(x);
}
