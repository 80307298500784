import { SearchResultItem } from '@app/find-content/interfaces/search-result-item.interface';
import { ServerInteractionStateType } from '@core/enums/server-interaction-state-type.enum';
import { ParsedServerError } from '@core/interfaces/project/parsed-server-error.interface';
import { createAction, props } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';

export const searchByIds = createAction('[Search by Ids/API] Search by IDs', props<{ ids: GUID[] }>());
export const searchByIdsIfNecessary = createAction(
  '[Search by Ids/API] Search by IDs if necessary',
  props<{ ids: GUID[] }>(),
);

export const setSearchByIdsServerInteractionState = createAction(
  '[Search by Ids/API] set search by IDs server interaction state',
  props<{ ids: GUID[]; state: ServerInteractionStateType; error?: ParsedServerError }>(),
);

export const setSearchByIdsResult = createAction(
  '[Search by Ids/API] set search by IDs result',
  props<{ ids: GUID[]; searchResultItems: SearchResultItem[] }>(),
);
