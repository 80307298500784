import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from '@app/user/services/user.service';
import { BackgroundGraphicsComponent } from '@shared-components/background-graphics/background-graphics.component';
import { Observable, startWith } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-background-graphics-manager',
  standalone: true,
  imports: [AsyncPipe, BackgroundGraphicsComponent],
  templateUrl: './background-graphics-manager.component.html',
  styleUrls: ['./background-graphics-manager.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundGraphicsManagerComponent {
  @Input() inContainer: boolean = false;

  public readonly showPattern$: Observable<boolean>;
  public readonly showGraphics$: Observable<boolean>;
  public readonly backgroundColor$: Observable<string | undefined>;
  public readonly backgroundImageUrl$: Observable<string | undefined>;

  public get isWinter(): boolean {
    return [0, 1, 11].includes(new Date().getMonth());
  }

  constructor(
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    const routerDataBackground$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let child = this.route.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['background']) {
            return child.snapshot.data['background'] as {
              color: string;
              showGraphics: boolean;
              showPattern: boolean;
              imageUrl: string;
            };
          } else {
            return null;
          }
        }
        return null;
      }),
    );

    this.showGraphics$ = routerDataBackground$.pipe(
      // Make sure graphics are shown even though no routing has taken place by firing an initial null
      startWith(null),
      map((routeDataBackground) => (routeDataBackground ? routeDataBackground.showGraphics : true)),
    );

    this.showPattern$ = routerDataBackground$.pipe(
      // Make sure patterns are shown even though no routing has taken place by firing an initial null
      startWith(null),
      map((routeDataBackground) => (routeDataBackground ? routeDataBackground.showPattern : true)),
    );

    this.backgroundColor$ = routerDataBackground$.pipe(map((routeDataBackground) => routeDataBackground?.color));
    this.backgroundImageUrl$ = routerDataBackground$.pipe(map((routeDataBackground) => routeDataBackground?.imageUrl));
  }
}
