import { contentFeatureKey } from '@app/content/store/content.reducers';
import { initialServerInteractionState } from '@core/interfaces/server-interaction-state.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GUID } from '@shared-types/guid.type';

import { ContentState } from '../interfaces/content.interface';

export const selectContentState = createFeatureSelector<ContentState>(contentFeatureKey);
export const selectLessonsState = createSelector(selectContentState, (contentState) => contentState.lessonState);
export const selectPrintableState = createSelector(selectContentState, (contentState) => contentState.printableState);

export const selectLesson = (lessonId: GUID) =>
  createSelector(selectLessonsState, (lessonState) => lessonState.lessons[lessonId]);

export const selectLessonCourseItemById = (lessonId: GUID, courseItemId: GUID) =>
  createSelector(selectLesson(lessonId), (lesson) => lesson.courseItems.find((item) => item.id === courseItemId));

export const selectLessonCourseItemByOrder = (lessonId: GUID, order: number) =>
  createSelector(selectLesson(lessonId), (lesson) => lesson?.courseItems.find((item) => item.order === order));

export const selectLessonCourseItemIdByOrder = (lessonId: GUID, order: number) =>
  createSelector(selectLessonCourseItemByOrder(lessonId, order), (courseItem) => courseItem?.id);

export const selectPrintable = (printableId: GUID) =>
  createSelector(selectPrintableState, (printableState) => printableState.printable.entities[printableId]);

export const selectLoadLessonServerInteractionState = (lessonId: GUID) =>
  createSelector(
    selectLessonsState,
    (lessonState) => lessonState.loadLessonServerInteractionStates[lessonId] ?? initialServerInteractionState,
  );
export const selectLoadPrintableServerInteractionState = (printableId: GUID) =>
  createSelector(
    selectPrintableState,
    (printableState) => printableState.loadPrintableServerInteractionStates[printableId],
  );
