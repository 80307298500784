<ng-container *ngIf="breadcrumbs.length > 0">
  <nav [attr.aria-label]="'app.components.breadcrumb.aria-label' | translate" class="breadcrumb">
    <ol>
      <li>
        <a routerLink="" [appTooltip]="'app.navigation.home' | translate"
          ><app-icon [icon]="Icons.HOME" [size]="IconSize.DEFAULT"></app-icon
        ></a>
      </li>
      <li *ngFor="let bc of breadcrumbs; let isLast = last" [class.short]="bc.label.length < 11">
        <a
          [appTooltip]="bc.label"
          [appTooltipIfEllipsis]="true"
          [routerLink]="bc.url"
          [attr.aria-current]="isLast ? 'page' : null"
          >{{ bc.label }}</a
        >
      </li>
    </ol>
  </nav>
</ng-container>
