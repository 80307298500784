<h1 class="heading-2">{{ 'page.components.login-modal-content.heading' | translate }}</h1>

<div class="login-options">
  <div *ngIf="requestTrialLink" class="login-option">
    <p>{{ 'page.components.login-modal-content.request-trial-message' | translate }}</p>
    <app-button [href]="requestTrialLink" [variant]="ButtonVariant.GHOST">{{
      'page.components.login-modal-content.request-trial-button' | translate
    }}</app-button>
  </div>

  <div class="login-option">
    <p>{{ 'page.components.login-modal-content.login-message' | translate }}</p>
    <app-button [icon]="Icons.NAVIGATION_ARROW_RIGHT" [rightAlignIcon]="true" (click)="userService.userLogin()">{{
      'page.components.login-modal-content.login-button' | translate
    }}</app-button>
  </div>
</div>
